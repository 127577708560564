.footer {
  background: rgba($grey, 0.75);
  color: #fff;

  @include media-breakpoint-down(sm) {
    .logo {
      max-width: 85%;
    }
  }

  // address {
  //   line-height: (26px / $font-size-base) * 1rem;
  // }
  
  .logo {
    width: 260px;
  }

  .footer-email {
    color: #fff;
    font-weight: 600;

    &:hover {
      color: $primary;
    }
  }

  .emergency-callout {
    color: #fff;

    a {
      color: #fff !important;
    }
  }

  .nav-item {
    color: #fff;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;

    .nav-link {
        @include font-size(16px);
        color: inherit;
        display: block;
        font-family: fieldwork, $font-family-sans-serif;
        font-weight: 300;
        //line-height: (20px / $font-size-base) * 1rem;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-decoration: none;

        &:hover {
          color: $primary;
        }
    }
  }
}

.footer-social-link {
  color: $grey-alt;
  margin-bottom: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: auto;

  &:hover {
    .fa-lg {
      color: #fff;
    }
  }
}
