.left-text-right-image-block {
  img {
    display: block;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(fhd) {
      transform: translateX(20%);
    }
  }
}
