.core-value {
    i.fas,
    i.fal,
    i.far {
        @include static-font-size(100px);
        color: $primary;
        margin: 0 auto auto auto;
        opacity: 0.15;
    }

    @include media-breakpoint-up(xl) {
        h3 {
            margin-left: auto;
            margin-right: auto;
            width: 80%;
        }
    }

    p {
        @include static-font-size(18px);
        font-weight: 400;
    }
}

@include media-breakpoint-up(lg) {
.page-template-about .core-values-block .row > div:nth-child(2) > .core-value {
    transform: translateY(80px);
}
}
