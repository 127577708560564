.feature-row {
  color: $primary;
  position: relative;
  // z-index: 2;

  &:before {
    background: $off-white;
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 0;
  }

  .feature-row-image {
    position: relative;
    z-index: 5;

    @include media-breakpoint-up(md){
      position: absolute;
    }
  }

  .feature-row-content {
    // h2,

    h2 {
      @include font-size(36px);

      @include media-breakpoint-only(lg){
        @include font-size(42px);
      }

      @include media-breakpoint-only(xl){
        @include font-size(48px);
      }
    }

    p {
      @include font-size(16px);
      font-weight: 300;

      @include media-breakpoint-up(lg){
        @include font-size(18px);
      }
    }

    .btn {
      font-weight: 700;
      margin-left: 0;
      margin-right: auto ;
      padding-left: 0 ;
      padding-right: 0;
      text-align: left ;
    }
  }
}
