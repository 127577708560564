@mixin static-font-size($size) {
    font-size: ($size / $font-size-base) * 1rem;
}

// convert letter-spacing from Adobe XD to CSS
@mixin xd-letter-spacing($letter-spacing) {
    letter-spacing: ($letter-spacing / 1000) * 1em;
}

@mixin fontawesome() {
    display: inline-block;
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

// https://engageinteractive.co.uk/blog/top-10-scss-mixins
@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

//// psuedo example:
// div::after {
//     @include pseudo;
//     top: -1rem; left: -1rem;
//     width: 1rem; height: 1rem;
// }

@mixin responsive-ratio($x,$y, $pseudo: false) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    @if $pseudo {
        &:after {
            @include pseudo($pos: relative);
            width: 100%;
            //padding-top: $padding;
            padding-bottom: $padding;
        }
    } @else {
        //padding-top: $padding;
        padding-bottom: $padding;
    }
}

//// responsive-ratio example
// div {
//     @include responsive-ratio(16,9);
// }
