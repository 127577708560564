// helpers/flex/flex
%flex, .flex {
    display: flex !important;
}

%flex-center {
    justify-content: center;
    align-items: center;
}

%flex-align-start {
    align-items: flex-start;
}

%flex-align-center {
    align-items: center;
}

%flex-align-end {
    align-items: flex-end;
}

%flex-justify-start, .flex-justify-start {
    justify-content: flex-start;
}

%flex-justify-center, .flex-justify-center {
    justify-content: center;
}

%flex-justify-end, .flex-justify-end {
    justify-content: flex-end;
}

%flex-justify-space-between {
    justify-content: space-between;
}

%flex-wrap {
    flex-wrap: wrap;
}

%flex-column {
    flex-direction: column;
}

%flex-grow, .flex--grow {
    flex: 1;
}

%flex-shrink, .flex--shrink {
    flex: 0 1;
}
