.caption-right-block {
    margin-top: 200px;

    @include media-breakpoint-only(md){
      margin-top: 150px;
    }


  @include media-breakpoint-only(md) {
    .drop-shadow-bottom-left {
      max-width: 100%;
    }
    .drop-shadow {
      display: none !important;
    }
  }

  .caption-right-row {
    position: relative;
  }

  .caption-right-col {
    position: static;
  }

  .caption-right-picture {
    display: block;
    position: relative;
    //max-width: 85%;

    // .caption-right-image {
    //   //box-shadow: -5rem 5rem $grey;
    //   display: inline-block;
    //   margin: auto;
    //   //max-width: 85%;
    //   position: relative;
    //   z-index: 1;

    //   // @include media-breakpoint-up(md) {
    //   //   max-width: 100%;
    //   // }
    // }

    @include media-breakpoint-up(md) {
      &:before {
        background-image: linear-gradient(to right, rgba(#fff, 1), rgba(#fff, 0.6));
        bottom: 0;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.75;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 3;
      }
    }
    @include media-breakpoint-up(lg) {
      &:before {
        background-image: linear-gradient(to right, rgba(#fff, 0), rgba(#fff, 1));
      }
    }
  }

  .caption-right-content {
    position: relative;
    z-index: 4;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-top: 2.5rem;
      position: absolute;
      right: 0;
      top: -1rem;
      width: 100%;

      // fix IE bug
      a {
        align-self: start;
        flex-shrink: 1;
      }

      &.justify-content-start {
        padding-top: 2rem;
      }

      &.justify-content-end {
        padding-bottom: 2rem;
      }
    }

    @include media-breakpoint-only(md){
      padding: 0 5rem;

      .title-accent-left {
        text-align: center !important;

        &::before {
          right: 0;
          margin: 0 auto;
        }
      }

      .btn {
        margin: 0 auto;
      }
    }


    @include media-breakpoint-up(lg) {
      bottom: 0;
      //margin-top: 5rem;
      right: 5%;
      top: 0;
      width: 36.6%;

      &.justify-content-start {
        padding-top: 5rem;
      }

      &.justify-content-end {
        padding-bottom: 5rem;
      }

      .h1 {
        font-size: 2.6rem;
      }
      //left: 40%;
    }

    @include media-breakpoint-up(xl) {
      width: 36.7%;

      .h1 {
        font-size: 2.8rem;
      }
    }

    //@include media-breakpoint-up(lg) {
      //left: 50%;
    //}
  }
}
