.form-col-bg {
  position: relative;
  z-index: 0;

  &:before {
    background-color: $off-white;
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: -30px;
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 0;

    // @include media-breakpoint-up(md) {
    //   left: 0;
    //   right: 0;
    //   //width: 66.66667vw;
    //   width: 100vw;
    // }

    @include media-breakpoint-up(lg) {
      left: 0;
      right: unset;
      width: 66.66667vw;
    }
  }
}

.custom-control-label {
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  color: $grey;
  // font-size: 14px;
  // font-weight: 400;
  padding-left: 0.5rem;
}

.custom-select {
  cursor: pointer;
}

.is-error {
  .custom-control-label {
    background: #fff;
    padding: 0.5rem;
  }

  input, textarea, select {
      border: 2px solid #ff424e !important;
      color: #ff424e !important;
      outline: 1px solid #ff424e !important;
  }

  input[type="checkbox"], input[type="radio"] {
      + label {
          &:before {
              border-color: #ff424e;
          }
      }
  }

  //&.input { // only on inputs, not form
  .input-message {
      @include font-size(14px);
      color: #ff424e;
      font-style: italic;
      font-weight: 300;
      margin-bottom: 0.25rem;
      margin-top: 0.25rem;
      position: relative;
      visibility: visible;
      width: 100%;
      z-index: 1;
  }
  //}
}

.alert--danger {
  @extend .alert-danger;
  background: #fff;
  border: 3px solid #ff424e;
  color: $grey;
}

.alert--success {
  @extend .alert-success;
  background: #fff;
  border: 3px solid #9cde9f;
  color: $grey;;
}

.submit-btn.waiting {
  display: none;
}

.gp_form.waiting {
  .submit-btn {
      display: none;
  }

  .spinner,
  .spinner-border {
      display: inline-block;
  }
}

label small {
  display: inline-block;
  margin-top: 0.5rem;
}

.city-town-row {
  display: none;
}
