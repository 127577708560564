.feature-block {
  color: $primary;
  // position: relative;
  // z-index: 2;

  .feature-block-image {
    position: relative;
    z-index: 2;
  }

  .feature-block-content {
    h2 {
      position: relative;
      z-index: 2;
    }

    p {
      @include font-size(16px);
      position: relative;
      z-index: 2;

      @include media-breakpoint-up(lg) {
        @include font-size(18px);
      }
    }

    &:before {
      background: $off-white;
      //background: gold;
      bottom: 0;
      content: "";
      display: block;
      height: 105%;
      left: -20vw;
      position: absolute;
      //right: 0;
      top: 0;
      width: 150vw;
      z-index: 1;

      @include media-breakpoint-only(md) {
        left: auto;
        right: 0;
        width: 120%;
      }

      @include media-breakpoint-only(lg) {
        right: auto;
        left: -25%;
        width: 58vw;
      }

      @include media-breakpoint-only(xl) {
        left: -30%;
        width: 57vw;
      }

      @include media-breakpoint-up(fhd) {
        left: -33%;
        width: 55vw;
      }
    }
  }
}
