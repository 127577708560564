.hero-block {
    height: 560px;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(lg) {
        @include responsive-ratio(160, 79);
        //height: 0;
    }

  .hero-block-content {
    // background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 0;

    @include media-breakpoint-up(lg) {
        @include responsive-ratio(160, 79);
        height: 0;
    }

    &:before {
        background: $off-white;
        bottom: 0;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.8;
        position: absolute;
        right: 0;
        top: 0;
        width: 100vw;
        z-index: 1;
    }
  }

  .hero-block-inner {
    @include static-font-size(18px);
    @extend %flex;
    @extend %flex-center;
    @extend %flex-column;
    bottom: 0;
    color: $primary;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;

    p {
        font-size: inherit;
    }
  }
}

// .hero-block {
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     overflow: hidden;
//     position: relative;
//     z-index: 0;

//     // @include media-breakpoint-up(xl) {
//     //     @include responsive-ratio(160, 79);
//     //     // height: 0;
//     // }

//     &:before {
//         background: $off-white;
//         bottom: 0;
//         content: "";
//         display: block;
//         height: 100%;
//         left: 0;
//         opacity: 0.8;
//         position: absolute;
//         right: 0;
//         top: 0;
//         width: 100vw;
//         z-index: 1;
//     }

//     .hero-block-inner {
//         @include static-font-size(18px);
//         color: $primary;
//         position: relative;
//         z-index: 2;

//         p {
//             font-size: inherit;
//         }
//     }
// }
