.accreditation-slides {
    position: relative;
    text-align: center;
    // width: 100%;

    .accreditation-slide {
        display: flex;
        margin: auto;
    }

    .accreditationsPrev,
    .accreditationsNext {
      @include font-size(25px);
      color: $primary;
      font-weight: 400;
      position: absolute;
      top: 50%;
      transform: translateY(-50%)
    }

    .accreditationsPrev {
      left: 0;
    }

    .accreditationsNext {
      right: 0;
    }

    .flickity-page-dots {
      left: 0;
      right: 0;

      .dot {
        background-color: $primary;
      }
    }

    .flickity-slider {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .stretched-link {
      display: block;
      filter: grayscale(100%);
      transition: all 300ms;
      opacity: 0.5;

      &:hover {
        filter: grayscale(0%);
        opacity: 1;
      }
    }
}

// .accreditation-slide {
//     margin: auto;
//     width: 20%;
// }

// .flickity-prev-next-button {
//     &.previous,
//     &.next {
//         svg {
//             display: none;
//         }
//     }

//     &.previous {
//         &:before {
//             @include fontawesome;
//             @include static-font-size(25px);
//             color: $primary;
//             display: block;
//             content: "\f177";
//             font-weight: 700;
//             position: absolute;
//         }
//     }

//     &.next {
//         &:before {
//             @include fontawesome;
//             @include static-font-size(25px);
//             color: $primary;
//             display: block;
//             content: "\f178";
//             font-weight: 700;
//             position: absolute;
//         }
//     }
// }
