.contact-content {
  h1,h2 {
    font-weight: 700;
  }
}

@include media-breakpoint-only(md) {
  .contact-content {
    width: 50%;
  }

  .contact-ctas-wrapper {
    width: 50%;
  }
}

.contact-enquiries-cta {
    background: $lightest-grey;
    color: $grey;
    //line-height: (28px / $font-size-base) * 1rem;

    p {
        @include font-size(18px);
        font-weight: 400;
    }

    h4 {
        color: $grey;
        margin-bottom: 0.5rem !important;
    }

    a {
        @include font-size(24px);
        color: $primary;
        font-weight: 600;

        &:hover {
          color: $ternary;
        }
    }
}

.contact-emergency-cta {
    background: $primary;
    color: #fff;
    //line-height: (28px / $font-size-base) * 1rem;
    position: relative;
    z-index: 1;

    p {
        @include static-font-size(18px);
        font-weight: 400;
    }

    h4 {
        color: #fff;
        margin-bottom: 0.5rem !important;
    }

    a {
        @include font-size(24px);
        color: #fff;
        font-weight: 600;

        &:hover {
          color: $ternary;
        }
    }

    &:before {
        background-image: url('../img/emergency-callout-icon-lime.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        display: block;
        height: 8.25rem;
        opacity: 0.05;
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
        width: 8.25rem;
        z-index: 2;
    }
}
