.map-block {
  color: $primary;
  position: relative;
  overflow: hidden;

  &:before {
    background: $off-white;
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 0;
  }

  .map-block-content {
    p {
      @include font-size(16px);
      font-weight: 300;

      @include media-breakpoint-up(lg) {
        @include font-size(18px);
      }
    }

    .btn {
      font-weight: 700;
      margin-left: 0;
      margin-right: auto ;
      padding-left: 0 ;
      padding-right: 0;
      text-align: left ;
    }
  }
}


#location-map {
  margin-top: 0;
  margin-bottom: 80px;
  width: 100%;
  height: auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  @include media-breakpoint-up(md) {
    margin-top: -130px;
  }
}
