// some button attributes are set by variables in _custom.scss

.btn {
  @include static-font-size(18px);
  @include xd-letter-spacing(25);
  border-color: transparent;
  max-width: 100%;
  text-transform: uppercase;
  white-space: nowrap;

  i {
    margin-left: 1rem;
  }
}

.btn-light {
  color: $primary;
}

.btn-wide {
  padding-left: 4rem;
  padding-right: 4rem;
}

.btn-transparent {
  background-color: transparent;
  border-color: transparent;
  color: $primary;
  font-weight: 600;
}

.btn-primary {
  &:hover {
    background: #fff;
    border-color: $primary;
    color: $primary;
  }

  &:active {
    background: #16471f;
    border-color: #16471f;
    color: #16471f;
  }
}

.btn-link {
  @include static-font-size(24px);
  font-weight: 600;
  text-transform: none;
}

a.dropdown-toggle {
  -webkit-appearance: unset !important;
}
