@import 'mixins';
@import 'a';

h1,h2,h3,h4,h5,h6,blockquote,button,li,p,a,.btn,
input[type='submit'],
input[type='text'],
input[type='email'],
input[type='tel'],
label,select,option,textarea,
.font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.caption {
  @include font-size(74px);
}

h1,
.h1 {
  @include font-size($h1-font-size);
}

// $h1-font-size:                $font-size-base * 3.375; // 54px
// $h2-font-size:                $font-size-base * 2.875; // 46px
// $h3-font-size:                $font-size-base * 2.375; // 38px
// $h4-font-size:                $font-size-base * 1.5;   // 24px
// $h5-font-size:                $font-size-base * 1.125; // 18px
// $h6-font-size:                $font-size-base * 1;     // 16px

h2,
.h2 {
  @include font-size($h2-font-size);

  // @include media-breakpoint-down(md) {
  //   @include static-font-size(3.6rem);
  // }
}

// h3,
// .h3 {
//   margin-bottom: 1.5rem;
//   text-transform: none;
// }

// h4,
// .h4 {
//   margin-bottom: 1.5rem;
//   text-transform: none;
// }

// h5,
// .h5 {
//   margin-bottom: 1.5rem;
//   text-transform: none;
// }

// h6,
// .h6 {
//   margin-bottom: 1rem;
//   text-transform: none;
// }

small {
  @include font-size(14px);
  text-transform: none;

  a {
    text-decoration: none;
  }
}

p {
  font-weight: 300;
}

.title,
.title-accent-left,
.title-accent-center {
  padding-top: 1.5rem;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-bottom: 2rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 2.5rem;
  }
}

.title-accent-left,
.title-accent-center  {
  &:before {
    background-color: $primary;
    content: "";
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 4.25rem;
  }
}

.title-accent-center {
  text-align: center;

  &:before {
    left: 50%;
    transform: translateX(-50%);
  }
}

.page-title {
    @include font-size(60px);
    color: $primary;

    // @include media-breakpoint-up(xl) {
    //   margin-top: 65px;
    // }

    // @include media-breakpoint-up(fhd) {
    //   margin-top: 45px;
    // }
}


%font-medium {
    font-weight: 500;
}

%font-semi-bold,
.font-weight-semi-bold {
    font-weight: 600;
}

%font-bold {
    font-weight: 700;
}

%text-center {
    text-align: center;
}

%text-left {
    text-align: left;
}

%text-right {
    text-align: right;
}

// fix a bug in Edge
.list-unstyled > li {
  white-space: nowrap;
}
