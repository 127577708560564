.testimonials-block {
    position: relative;

    > .container {
        @include media-breakpoint-up(lg){
          position: relative;
        }

        &:after {
            background: $primary;
            bottom: 0;
            content: "";
            display: block;
            height: 100%;
            left: 0;
            opacity: 1;
            position: absolute;
            top: 0;
            transform: translate3d(100%,0,0);
            transition: transform 500ms $material-decelerate;
            width: 100vw;
            will-change: transform;
            z-index: 0;

            @include media-breakpoint-up(lg){
              height: 90%;
            }

            @include media-breakpoint-up(xl){
              height: 80%;
            }
        }
    }

    .col-lg-6.d-lg-inline-block {
        align-self: flex-end;
    }

    &.is-visible {
      > .container:after,
      .testimonial-image {
        opacity: 1;
        transform: translate3d(0,0,0);
        will-change: unset;
      }
    }
}

    // .testimonial-image {
    //     display: block;
    //     //object-fit: cover;
    //     position: absolute;
    //     // top: 0;
    //     // left: 0;
    //     // right: 0;
    //     left: 4rem;
    //     top: 6rem;
    //     height: auto;
    //     max-width: 100%;
    //     z-index: 2;
    // }

.testimonial-image-wrapper {
    // @include responsive-ratio(53, 60, true);
    //left: 4rem;
    //top: 6rem;
    display: block;
    // outline: 1px solid #f00;
    // height: 0;
    // overflow: hidden;
    // position: absolute;
    //z-index: 2;
    //height: 100%;
    //width: 100%;

    .testimonial-image {
        //@include responsive-ratio(53, 60, true);
        display: none;
        // position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        //bottom: 0;
        //@at-rootheight: auto;
        //height: 100%;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // top: 0;
        //width: 100%;

        @include media-breakpoint-up(lg){
          display: block;
          height: auto;
          object-fit: cover;
          opacity: 1;
          position: relative;
          margin-left: -4rem;
          margin-top: 6rem;
          max-width: 100%;
          transform: translate3d(-100%,0,0);
          transition: transform 500ms $material-decelerate;
          width: 100%;
          z-index: 1;
        }

        @include media-breakpoint-up(xl){
          margin-left: -12rem;
        }
    }
}

.testimonial-block-content {
    color: #fff;
    // position: relative;
    // z-index: 1;

    // &:after {
    //     background: $primary;
    //     content: "";
    //     display: block;
    //     opacity: 1;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     // right: 0;
    //     bottom: 0;
    //     height: 100%;
    //     //transform: translateX(100%);
    //     //transition: transform 400ms $material-decelerate;
    //     width: 100vw;
    //     z-index: 0;
    // }

    h2 {
        @include font-size(46px);
        color: #fff;
        display: block;
        //line-height: (56px / $font-size-base) * 1rem;
        position: relative;
        z-index: 1;

        .quote-left,
        .quote-right {
            background-image: url('../img/speechmark.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            display: block;
            height: 32px;
            position: absolute;
            width: 32px;
            z-index: 1;

            @include media-breakpoint-up(xl){
              height: 48px;
              width: 48px;
            }
        }

        .quote-left {
          left: -2.25rem;
          top: 0;

          @include media-breakpoint-up(xl){
            left: -4rem;
            top: -0.25rem;
          }
        }

        .quote-right {
            display: inline-block;
            position: absolute;
            left: none;
            margin-left: 0.5rem;
            margin-top: 0.5rem;
            //bottom: 0;
            top: none;

            @include media-breakpoint-up(xl){
              //bottom: 0.25rem;
              margin-left: 1rem;
            }
        }
    }

    p {
        @include font-size(18px);
        font-weight: 600;
        //line-height: (64px / $font-size-base) * 1rem;
        position: relative;
        z-index: 1;

        @include media-breakpoint-up(md) {
            padding-bottom: 90px;
        }
    }
}
