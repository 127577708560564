.emergency-callout {
    color: #fff;
    margin-bottom: 0.5rem;
    margin-top: 1rem;

    img {
        height: 48px;
        margin-right: 10px;
        width: 48px;
    }

    span {
        letter-spacing: 0.2px;
    }

    a {
        font-size: 26px;
        color: #fff;
        letter-spacing: 2.1px;
        line-height: normal;
        transform: translateY(2px);
    }
}
