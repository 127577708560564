.contact-cta {
  background: transparent;
  border: 4px solid $grey;
  position: relative;

  h3 {
    // line-height: (48px / $font-size-base) * 1rem;
    position: relative;
    z-index: 5;
  }

  p {
    @include font-size(24px);
    font-weight: 600;
    position: relative;
    z-index: 5;
    //line-height: (34px / $font-size-base) * 1rem;
  }
}


@include media-breakpoint-up(lg) {
  .page-template-about .contact-cta {
    position: relative;
    top: -3rem;
    z-index: 5;
  }
}

@include media-breakpoint-up(xl) {
  .page-template-about .contact-cta {
    position: relative;
    top: -6rem;
    z-index: 5;
  }
}
