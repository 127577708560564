$primary: #1d612a;
$secondary: #808080;
$ternary: #9CDE9F;
$quaternary: #b6d82f;
// $quintile: #528a9c;
// $senary: #ef4640;

// $pagination: #623581;

$social-icons: #f4c00a;

$green: $primary;

// $orange: $primary;
// $yellow: $ternary;
// $purple: $quaternary;
// $blue: $quintile;
// $red: $senary;

// $success: $quintile;
// $danger: $primary;
// $text-success: $quintile;
// $text-danger:  $primary;

$grey: $secondary;
$grey-alt: #585858;
$dark-grey: #3e3938;
// $light-grey: #acacac;
// $lighter-grey: #d5d5d5;
$lightest-grey: #f2f2f2;

$off-white: #f3f7f4;

$light: #fff;
$dark: $dark-grey;

// $hover: #5db77a;
// $active: #006C4C;

$instagram: #e1306c; // #f00075
$facebook: #3b5998;
$twitter: #1da1f2;
$pinterest: #bd081c;
$youtube: #f00;
$vimeo: #1ab7ea;
$linkedin: #007bb5;

.light-green {
  background-color: $off-white;
}

.light-grey {
  background-color: $lightest-grey;
}

// $joypots-blue: #6ba9d8;
// $joypots-driftwood: #b37e4a;
// $joypots-green: #92b361;
// $joypots-orange: #c45c2a;
// $joypots-purple: #6b4576;
// $joypots-rajah: #f7af63;
// $joypots-red: #e38b90;
// $joypots-sienna: #d58554;
// $joypots-yellow: #f6af31;

// .banner__item.blue,
// .drop-shadow-blue:before,
// .product-tile.blue:before,
// .product-header.blue:before {
//   background-color: $joypots-blue !important;
// }
