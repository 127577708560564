.header {
  background: $primary;
  position: relative;
  transform: translate3d(0, -100%, 0);
  transition: transform 300ms $material-decelerate;
  width: 100%;

  &.float {
    position: fixed;
    z-index: $zindex-fixed;
  }

  &.show {
    transform: translate3d(0, 0, 0);
  }

  .logo {
    width: 260px;
  }

  .nav-item {
    color: #fff;
    position: relative;
    transition: background-color 300ms $material-decelerate, color 30ms $material-decelerate;

    &:hover {
      background: #fff;
      color: $primary;

      .dropdown-menu {
        display: block;
      }
    }

    .nav-link {
        @include static-font-size(16px);
        color: inherit;
        display: inline-block;
        font-family: fieldwork, $font-family-sans-serif;
        font-weight: 600;
        line-height: (48px / $font-size-base) * 1rem;
        text-decoration: none;
    }

    .dropdown-menu {
      left: -1px !important;
      margin-top: -1px;
      top: unset !important;
      transform: unset !important;
    }

    .dropdown > .nav-link {
      padding-right: 0;
    }

    .dropdown-toggle {
      &:after {
        @include fontawesome;
        border: 0;
        content: "\f107";
        font-size: 20px;
        font-weight: 400;
        vertical-align: middle;
      }
    }
  }
}
