/*
https://tympanus.net/codrops/2013/07/30/google-nexus-website-menu/
*/

// .gn-menu-main is the encompassing ul containing logo and trigger
.gn-menu-main {
  display: block;
  flex-basis: 0%;
  flex-grow: 0;
  flex-shrink: 0;
  height: $mobile-menu-height;
  margin-bottom: auto;
  margin-top: 0;
  position: fixed;
  width: 100%;
  z-index: $zindex-fixed;

  @include media-breakpoint-up(xl) {
    display: none !important;
  }
}

.gn-menu-main .logo {
  height: 50px;
  margin-bottom: auto;
  margin-top: auto;
  width: auto;

  @include media-breakpoint-up(sm) {
    height: $mobile-menu-height;
  }
}

.gn-menu-main,
.gn-menu-main ul {
    margin: 0;
    padding: 0;
    background: $primary;
    list-style: none;
    text-transform: none;
    line-height: $mobile-menu-height;
}

.gn-menu-main ul {
  background: $primary;
  //padding-top: 16px;
}

.gn-menu-main a {
  cursor: pointer;
  display: flex;
  height: 100%;
  text-decoration: none;
  user-select: none;
  width: 100%;
  z-index: $zindex-fixed;
}

/*
.gn-menu-main a:hover,
.gn-menu li.gn-search-item:hover,
.gn-menu li.gn-search-item:hover a {
    background: #5f6f81;
    color: white;
}
*/

// .gn-trigger .gn-icon a:hover {
//   color: white !important;
// }

// .gn-menu-main > li {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     line-height: normal;
// }

.gn-menu-main > li:first-child {
    margin-left: 0;
    margin-right: auto;
    text-align: left;
}

.gn-menu-main > li {
    border-right: none;
    height: 100%;
    text-align: right;
}

.gn-menu-main li.gn-search {
    font-weight: 400;
    margin-left: auto;
}

.gn-menu-main li.gn-search,
.gn-menu-main li.gn-trigger {
    overflow: hidden;
    // padding: 8px;
    position: relative;
    width: 60px;
    user-select: none;
}

// .gn-menu-main li.gn-trigger {
//     font-weight: 900;
// }

/*
.gn-menu-main > li > a {
    padding: 0 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}
*/

.gn-menu-main:after {
  display: table;
  clear: both;
  content: '';
}

// The nav element that will transition between opened/closed
.gn-menu-wrapper {
  background: $primary;
  bottom: 0;
  height: 100vh;
  left: 0;
  overflow: hidden;
  padding-top: $mobile-menu-height;
  position: fixed;
  // top: $mobile-menu-height;
  top: 0;
  transform: translateX(-60px); /* will be transitioned to 0px */
  transition: transform 250ms $material-decelerate, width 250ms $material-decelerate;
  width: 50px; /* will be transitioned to 340px */
  z-index: $zindex-sticky;

  @include media-breakpoint-up(sm) {
    padding-top: $mobile-menu-height + 10px;
  }

  hr {
    background-color: rgba(#fff, 0.3);
  }

  div.d-flex {
    position: relative;
  }

  .gmenu-cta {
    font-weight: 400;
    line-height: 1.4;

    img {
      width: 48px;
    }

    a {
      @include font-size(24px);
      height: auto;
    }
  }
}

.gn-menu-wrapper.gn-open-all,
.gn-menu-wrapper.gn-open-part {
    transform: translateX(0px);
}

.gn-menu-wrapper.gn-open-all {
    width: 370px;
}

// .gn-menu-wrapper.gn-open-all .gn-submenu li {
//     height: 50px;
// }

@include media-breakpoint-down(sm) {
/*@media screen and (max-width: 422px) { */
    .gn-menu-wrapper.gn-open-all {
        transform: translateX(0px);
        //width: 100%;
    }

    // .gn-menu-wrapper.gn-open-all .gn-scroller {
    //     width: 130%;
    // }
}



// Scrollable div containing the nav links
.gn-scroller {
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 370px;
  height: 100vh;
  max-width: 100vw;
}

// .gn-menu is the ul containing the navigation links
.gn-menu {
  // border-bottom: 1px solid rgba(#fff, 0.2);
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;

  .submenu-btn {
    position: absolute;
    right: 0;
    background: none;
    border: 0;
    color: #FFFFFF;
    height: 100%;
    margin: 0;
    text-align: center;
    width: 48px;
    line-height: normal;
    z-index: 1031;

    &[aria-expanded=true] {
      color: $primary;
    }

    i {
      user-select: none;
      pointer-events: none;
    }
  }

  .submenu-list {
    background-color: #FFFFFF;
  }

  .submenu-link {
    @include font-size(20px);
    // color: #fff;
    display: block;
    line-height: 40px;
    padding: 0.4rem 2rem 0.4rem 2rem;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    &:hover {
      background: #e6e6e6;
      color: $primary;
    }
  }
}

// .gn-menu li:not(:first-child),
// .gn-menu li li {
//   box-shadow: inset 0 1px lighten($primary, 25%);
// }

// .gn-menu li:first-child a {
//   padding-top: 0.5rem;
// }

// navigation link
.gn-menu-main .gn-menu .gn-menu-link {
    @include font-size(24px);
    color: #fff;
    display: block;
    font-family: $headings-font-family;
    font-weight: 500;
    line-height: 48px;
    padding: 0 2rem;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include media-breakpoint-up(md) {
      text-align: left;
    }

    &:hover,
    &.active {
        background: #fff;
        color: $primary;
    }
}


.gn-icon {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    display: block;
    height: 100%;
    line-height: normal;
    margin: 0;
    //overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;

    i {
        line-height: inherit;
        margin: auto;
    }
}


.burger {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: $zindex-fixed;

  span {
    background: #fff;
    display: block;
    height: 4px;
    left: 15px;
    margin-bottom: 5px;
    margin-top: 5px;
    position: relative;
    top: 15px;
    transition: all 150ms $material-decelerate;
    width: 30px;
    z-index: $zindex-fixed;

    &:nth-child(1) {
      transition-delay: 350ms;
    }

    &:nth-child(2) {
      transition-delay: 400ms;
    }

    &:nth-child(3) {
      transition-delay: 450ms;
    }
  }
}

.cross {
    height: 48px;
    right: 6px;
    position: absolute;
    top: 6px;
    width: 48px;
    transform: rotate(45deg);
    z-index: $zindex-fixed;

    &:before,
    &:after {
      background-color: #fff;
      content:'';
      position: absolute;
      transition: all 150ms $material-decelerate;
      z-index: $zindex-fixed;
    }

    &:before {
      height: 0;
      left: 22px;
      top: 8px; //5px;
      transition-delay: 0;
      width: 4px;
    }

    &:after {
      height: 4px;
      left: 8px;
      top: 22px;
      transition-delay: 150ms;
      width: 0;
    }
}


.gn-selected {
  .burger {
    span {
      width: 0;

      &:nth-child(1) {
        transition-delay: 0;
      }

      &:nth-child(2) {
        transition-delay: 100ms;
      }

      &:nth-child(3) {
        transition-delay: 300ms;
      }
    }
  }

  .cross {
    &:before {
      height: 32px;
      transition-delay: 600ms;
    }

    &:after {
      width: 32px;
      transition-delay: 750ms;
    }
  }
}
