a {
  transition: all 100ms $material-decelerate;
}

.email-icon-link {
  @include static-font-size(18px);
  color: $primary;
  display: inline-block;
  font-weight: 600;
  line-height: (28px / $font-size-base) * 1rem;
  margin-left: 0;
  margin-right: auto;
  padding-left: 2rem;
  position: relative;
  width: auto;

  &:before {
    @include fontawesome;
    color: inherit;
    content: "\f0e0";
    font-size: 20px;
    font-weight: 400;
    line-height: (28px / $font-size-base) * 1rem;
    position: absolute;
    left: 0;
    top: 0;
    vertical-align: middle;
  }

  &:hover {
    color: $ternary;

    &:before {
      content: "\f2b6";
      top: -2px;
    }
  }
}

.back-link {
    @include font-size(20px);
    @include xd-letter-spacing(20);
    // color: $quaternary;
    // display: inline-block;
    font-family: "neusa-next-std-compact", $font-family-sans-serif;
    font-weight: 500;
    line-height: (29px / $font-size-base) * 1rem;
    text-decoration: none !important;
    text-transform: uppercase;

    &::before {
        @include fontawesome;
        color: inherit;
        content: "\f060";
        display: inline-block;
        font-weight: 900;
        margin-right: 0.5rem;
    }

    &:hover::before {
        animation: left-bounce 750ms $material-standard infinite;
        animation-play-state: running;
    }
}

@keyframes left-bounce {
    0%,
    100% {
      transform: translate3d(0,0,0);
    }

    50% {
      transform: translate3d(-70%,0,0);
    }
}

.forward-link {
    @include font-size(20px);
    @include xd-letter-spacing(20);
    color: inherit;
    // color: $quaternary;
    // display: inline-block;
    font-family: "neusa-next-std-compact", $font-family-sans-serif;
    font-weight: 500;
    line-height: (29px / $font-size-base) * 1rem;
    text-decoration: none !important;;
    text-transform: uppercase;

    &:after {
        @include fontawesome;
        color: inherit;
        content: "\f061";
        display: inline-block;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        margin-left: 0.5rem;
    }

    &:hover::after {
        animation: right-bounce 750ms $material-standard infinite;
        animation-play-state: running;
    }
}

@keyframes right-bounce {
    0%,
    100% {
      transform: translate3d(0,0,0);
    }

    50% {
      transform: translate3d(70%,0,0);
    }
}
