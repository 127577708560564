@charset "UTF-8";
.light-green {
  background-color: #f3f7f4; }

.light-grey {
  background-color: #f2f2f2; }

/* custom bootstrap variables */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #1d612a;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #1d612a;
  --secondary: #808080;
  --success: #1d612a;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #fff;
  --dark: #3e3938;
  --ternary: #9CDE9F;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-fhd: 1900px;
  --breakpoint-qhd: 2540px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: fieldwork, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #808080;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #1d612a;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1d612a;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: fieldwork, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  line-height: 1.2;
  color: #1d612a; }

h1, .h1 {
  font-size: 3.375rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.4625rem + 2.55vw) ; } }

h2, .h2 {
  font-size: 2.875rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.4125rem + 1.95vw) ; } }

h3, .h3 {
  font-size: 2.375rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.3625rem + 1.35vw) ; } }

h4, .h4 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.275rem + 0.3vw) ; } }

h5, .h5 {
  font-size: 1.125rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

/*!
 * Bootstrap Grid v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 726px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 1900px) {
    .container {
      max-width: 1330px; } }
  @media (min-width: 2540px) {
    .container {
      max-width: 1600px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-fhd-1, .col-fhd-2, .col-fhd-3, .col-fhd-4, .col-fhd-5, .col-fhd-6, .col-fhd-7, .col-fhd-8, .col-fhd-9, .col-fhd-10, .col-fhd-11, .col-fhd-12, .col-fhd,
.col-fhd-auto, .col-qhd-1, .col-qhd-2, .col-qhd-3, .col-qhd-4, .col-qhd-5, .col-qhd-6, .col-qhd-7, .col-qhd-8, .col-qhd-9, .col-qhd-10, .col-qhd-11, .col-qhd-12, .col-qhd,
.col-qhd-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1900px) {
  .col-fhd {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-fhd-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-fhd-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-fhd-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-fhd-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-fhd-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-fhd-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-fhd-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-fhd-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-fhd-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-fhd-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-fhd-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-fhd-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-fhd-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-fhd-first {
    order: -1; }
  .order-fhd-last {
    order: 13; }
  .order-fhd-0 {
    order: 0; }
  .order-fhd-1 {
    order: 1; }
  .order-fhd-2 {
    order: 2; }
  .order-fhd-3 {
    order: 3; }
  .order-fhd-4 {
    order: 4; }
  .order-fhd-5 {
    order: 5; }
  .order-fhd-6 {
    order: 6; }
  .order-fhd-7 {
    order: 7; }
  .order-fhd-8 {
    order: 8; }
  .order-fhd-9 {
    order: 9; }
  .order-fhd-10 {
    order: 10; }
  .order-fhd-11 {
    order: 11; }
  .order-fhd-12 {
    order: 12; }
  .offset-fhd-0 {
    margin-left: 0; }
  .offset-fhd-1 {
    margin-left: 8.33333%; }
  .offset-fhd-2 {
    margin-left: 16.66667%; }
  .offset-fhd-3 {
    margin-left: 25%; }
  .offset-fhd-4 {
    margin-left: 33.33333%; }
  .offset-fhd-5 {
    margin-left: 41.66667%; }
  .offset-fhd-6 {
    margin-left: 50%; }
  .offset-fhd-7 {
    margin-left: 58.33333%; }
  .offset-fhd-8 {
    margin-left: 66.66667%; }
  .offset-fhd-9 {
    margin-left: 75%; }
  .offset-fhd-10 {
    margin-left: 83.33333%; }
  .offset-fhd-11 {
    margin-left: 91.66667%; } }

@media (min-width: 2540px) {
  .col-qhd {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-qhd-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-qhd-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-qhd-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-qhd-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-qhd-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-qhd-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-qhd-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-qhd-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-qhd-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-qhd-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-qhd-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-qhd-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-qhd-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-qhd-first {
    order: -1; }
  .order-qhd-last {
    order: 13; }
  .order-qhd-0 {
    order: 0; }
  .order-qhd-1 {
    order: 1; }
  .order-qhd-2 {
    order: 2; }
  .order-qhd-3 {
    order: 3; }
  .order-qhd-4 {
    order: 4; }
  .order-qhd-5 {
    order: 5; }
  .order-qhd-6 {
    order: 6; }
  .order-qhd-7 {
    order: 7; }
  .order-qhd-8 {
    order: 8; }
  .order-qhd-9 {
    order: 9; }
  .order-qhd-10 {
    order: 10; }
  .order-qhd-11 {
    order: 11; }
  .order-qhd-12 {
    order: 12; }
  .offset-qhd-0 {
    margin-left: 0; }
  .offset-qhd-1 {
    margin-left: 8.33333%; }
  .offset-qhd-2 {
    margin-left: 16.66667%; }
  .offset-qhd-3 {
    margin-left: 25%; }
  .offset-qhd-4 {
    margin-left: 33.33333%; }
  .offset-qhd-5 {
    margin-left: 41.66667%; }
  .offset-qhd-6 {
    margin-left: 50%; }
  .offset-qhd-7 {
    margin-left: 58.33333%; }
  .offset-qhd-8 {
    margin-left: 66.66667%; }
  .offset-qhd-9 {
    margin-left: 75%; }
  .offset-qhd-10 {
    margin-left: 83.33333%; }
  .offset-qhd-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1900px) {
  .d-fhd-none {
    display: none !important; }
  .d-fhd-inline {
    display: inline !important; }
  .d-fhd-inline-block {
    display: inline-block !important; }
  .d-fhd-block {
    display: block !important; }
  .d-fhd-table {
    display: table !important; }
  .d-fhd-table-row {
    display: table-row !important; }
  .d-fhd-table-cell {
    display: table-cell !important; }
  .d-fhd-flex {
    display: flex !important; }
  .d-fhd-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 2540px) {
  .d-qhd-none {
    display: none !important; }
  .d-qhd-inline {
    display: inline !important; }
  .d-qhd-inline-block {
    display: inline-block !important; }
  .d-qhd-block {
    display: block !important; }
  .d-qhd-table {
    display: table !important; }
  .d-qhd-table-row {
    display: table-row !important; }
  .d-qhd-table-cell {
    display: table-cell !important; }
  .d-qhd-flex {
    display: flex !important; }
  .d-qhd-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1900px) {
  .flex-fhd-row {
    flex-direction: row !important; }
  .flex-fhd-column {
    flex-direction: column !important; }
  .flex-fhd-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-fhd-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-fhd-wrap {
    flex-wrap: wrap !important; }
  .flex-fhd-nowrap {
    flex-wrap: nowrap !important; }
  .flex-fhd-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-fhd-fill {
    flex: 1 1 auto !important; }
  .flex-fhd-grow-0 {
    flex-grow: 0 !important; }
  .flex-fhd-grow-1 {
    flex-grow: 1 !important; }
  .flex-fhd-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-fhd-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-fhd-start {
    justify-content: flex-start !important; }
  .justify-content-fhd-end {
    justify-content: flex-end !important; }
  .justify-content-fhd-center {
    justify-content: center !important; }
  .justify-content-fhd-between {
    justify-content: space-between !important; }
  .justify-content-fhd-around {
    justify-content: space-around !important; }
  .align-items-fhd-start {
    align-items: flex-start !important; }
  .align-items-fhd-end {
    align-items: flex-end !important; }
  .align-items-fhd-center {
    align-items: center !important; }
  .align-items-fhd-baseline {
    align-items: baseline !important; }
  .align-items-fhd-stretch {
    align-items: stretch !important; }
  .align-content-fhd-start {
    align-content: flex-start !important; }
  .align-content-fhd-end {
    align-content: flex-end !important; }
  .align-content-fhd-center {
    align-content: center !important; }
  .align-content-fhd-between {
    align-content: space-between !important; }
  .align-content-fhd-around {
    align-content: space-around !important; }
  .align-content-fhd-stretch {
    align-content: stretch !important; }
  .align-self-fhd-auto {
    align-self: auto !important; }
  .align-self-fhd-start {
    align-self: flex-start !important; }
  .align-self-fhd-end {
    align-self: flex-end !important; }
  .align-self-fhd-center {
    align-self: center !important; }
  .align-self-fhd-baseline {
    align-self: baseline !important; }
  .align-self-fhd-stretch {
    align-self: stretch !important; } }

@media (min-width: 2540px) {
  .flex-qhd-row {
    flex-direction: row !important; }
  .flex-qhd-column {
    flex-direction: column !important; }
  .flex-qhd-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-qhd-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-qhd-wrap {
    flex-wrap: wrap !important; }
  .flex-qhd-nowrap {
    flex-wrap: nowrap !important; }
  .flex-qhd-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-qhd-fill {
    flex: 1 1 auto !important; }
  .flex-qhd-grow-0 {
    flex-grow: 0 !important; }
  .flex-qhd-grow-1 {
    flex-grow: 1 !important; }
  .flex-qhd-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-qhd-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-qhd-start {
    justify-content: flex-start !important; }
  .justify-content-qhd-end {
    justify-content: flex-end !important; }
  .justify-content-qhd-center {
    justify-content: center !important; }
  .justify-content-qhd-between {
    justify-content: space-between !important; }
  .justify-content-qhd-around {
    justify-content: space-around !important; }
  .align-items-qhd-start {
    align-items: flex-start !important; }
  .align-items-qhd-end {
    align-items: flex-end !important; }
  .align-items-qhd-center {
    align-items: center !important; }
  .align-items-qhd-baseline {
    align-items: baseline !important; }
  .align-items-qhd-stretch {
    align-items: stretch !important; }
  .align-content-qhd-start {
    align-content: flex-start !important; }
  .align-content-qhd-end {
    align-content: flex-end !important; }
  .align-content-qhd-center {
    align-content: center !important; }
  .align-content-qhd-between {
    align-content: space-between !important; }
  .align-content-qhd-around {
    align-content: space-around !important; }
  .align-content-qhd-stretch {
    align-content: stretch !important; }
  .align-self-qhd-auto {
    align-self: auto !important; }
  .align-self-qhd-start {
    align-self: flex-start !important; }
  .align-self-qhd-end {
    align-self: flex-end !important; }
  .align-self-qhd-center {
    align-self: center !important; }
  .align-self-qhd-baseline {
    align-self: baseline !important; }
  .align-self-qhd-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 2rem !important; }

.mt-5,
.my-5 {
  margin-top: 2rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 2rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2rem !important; }

.m-6 {
  margin: 3rem !important; }

.mt-6,
.my-6 {
  margin-top: 3rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3rem !important; }

.m-7 {
  margin: 4rem !important; }

.mt-7,
.my-7 {
  margin-top: 4rem !important; }

.mr-7,
.mx-7 {
  margin-right: 4rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 4rem !important; }

.ml-7,
.mx-7 {
  margin-left: 4rem !important; }

.m-8 {
  margin: 5rem !important; }

.mt-8,
.my-8 {
  margin-top: 5rem !important; }

.mr-8,
.mx-8 {
  margin-right: 5rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 5rem !important; }

.ml-8,
.mx-8 {
  margin-left: 5rem !important; }

.m-9 {
  margin: 6rem !important; }

.mt-9,
.my-9 {
  margin-top: 6rem !important; }

.mr-9,
.mx-9 {
  margin-right: 6rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 6rem !important; }

.ml-9,
.mx-9 {
  margin-left: 6rem !important; }

.m-10 {
  margin: 7rem !important; }

.mt-10,
.my-10 {
  margin-top: 7rem !important; }

.mr-10,
.mx-10 {
  margin-right: 7rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 7rem !important; }

.ml-10,
.mx-10 {
  margin-left: 7rem !important; }

.m-11 {
  margin: 8rem !important; }

.mt-11,
.my-11 {
  margin-top: 8rem !important; }

.mr-11,
.mx-11 {
  margin-right: 8rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 8rem !important; }

.ml-11,
.mx-11 {
  margin-left: 8rem !important; }

.m-12 {
  margin: 9rem !important; }

.mt-12,
.my-12 {
  margin-top: 9rem !important; }

.mr-12,
.mx-12 {
  margin-right: 9rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 9rem !important; }

.ml-12,
.mx-12 {
  margin-left: 9rem !important; }

.m-13 {
  margin: 10rem !important; }

.mt-13,
.my-13 {
  margin-top: 10rem !important; }

.mr-13,
.mx-13 {
  margin-right: 10rem !important; }

.mb-13,
.my-13 {
  margin-bottom: 10rem !important; }

.ml-13,
.mx-13 {
  margin-left: 10rem !important; }

.m-14 {
  margin: 11rem !important; }

.mt-14,
.my-14 {
  margin-top: 11rem !important; }

.mr-14,
.mx-14 {
  margin-right: 11rem !important; }

.mb-14,
.my-14 {
  margin-bottom: 11rem !important; }

.ml-14,
.mx-14 {
  margin-left: 11rem !important; }

.m-15 {
  margin: 12rem !important; }

.mt-15,
.my-15 {
  margin-top: 12rem !important; }

.mr-15,
.mx-15 {
  margin-right: 12rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 12rem !important; }

.ml-15,
.mx-15 {
  margin-left: 12rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 2rem !important; }

.pt-5,
.py-5 {
  padding-top: 2rem !important; }

.pr-5,
.px-5 {
  padding-right: 2rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 2rem !important; }

.pl-5,
.px-5 {
  padding-left: 2rem !important; }

.p-6 {
  padding: 3rem !important; }

.pt-6,
.py-6 {
  padding-top: 3rem !important; }

.pr-6,
.px-6 {
  padding-right: 3rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3rem !important; }

.pl-6,
.px-6 {
  padding-left: 3rem !important; }

.p-7 {
  padding: 4rem !important; }

.pt-7,
.py-7 {
  padding-top: 4rem !important; }

.pr-7,
.px-7 {
  padding-right: 4rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 4rem !important; }

.pl-7,
.px-7 {
  padding-left: 4rem !important; }

.p-8 {
  padding: 5rem !important; }

.pt-8,
.py-8 {
  padding-top: 5rem !important; }

.pr-8,
.px-8 {
  padding-right: 5rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 5rem !important; }

.pl-8,
.px-8 {
  padding-left: 5rem !important; }

.p-9 {
  padding: 6rem !important; }

.pt-9,
.py-9 {
  padding-top: 6rem !important; }

.pr-9,
.px-9 {
  padding-right: 6rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 6rem !important; }

.pl-9,
.px-9 {
  padding-left: 6rem !important; }

.p-10 {
  padding: 7rem !important; }

.pt-10,
.py-10 {
  padding-top: 7rem !important; }

.pr-10,
.px-10 {
  padding-right: 7rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 7rem !important; }

.pl-10,
.px-10 {
  padding-left: 7rem !important; }

.p-11 {
  padding: 8rem !important; }

.pt-11,
.py-11 {
  padding-top: 8rem !important; }

.pr-11,
.px-11 {
  padding-right: 8rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 8rem !important; }

.pl-11,
.px-11 {
  padding-left: 8rem !important; }

.p-12 {
  padding: 9rem !important; }

.pt-12,
.py-12 {
  padding-top: 9rem !important; }

.pr-12,
.px-12 {
  padding-right: 9rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 9rem !important; }

.pl-12,
.px-12 {
  padding-left: 9rem !important; }

.p-13 {
  padding: 10rem !important; }

.pt-13,
.py-13 {
  padding-top: 10rem !important; }

.pr-13,
.px-13 {
  padding-right: 10rem !important; }

.pb-13,
.py-13 {
  padding-bottom: 10rem !important; }

.pl-13,
.px-13 {
  padding-left: 10rem !important; }

.p-14 {
  padding: 11rem !important; }

.pt-14,
.py-14 {
  padding-top: 11rem !important; }

.pr-14,
.px-14 {
  padding-right: 11rem !important; }

.pb-14,
.py-14 {
  padding-bottom: 11rem !important; }

.pl-14,
.px-14 {
  padding-left: 11rem !important; }

.p-15 {
  padding: 12rem !important; }

.pt-15,
.py-15 {
  padding-top: 12rem !important; }

.pr-15,
.px-15 {
  padding-right: 12rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 12rem !important; }

.pl-15,
.px-15 {
  padding-left: 12rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -2rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -2rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important; }

.m-n6 {
  margin: -3rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -3rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -3rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -3rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -3rem !important; }

.m-n7 {
  margin: -4rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -4rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -4rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -4rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -4rem !important; }

.m-n8 {
  margin: -5rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -5rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -5rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -5rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -5rem !important; }

.m-n9 {
  margin: -6rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -6rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -6rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -6rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -6rem !important; }

.m-n10 {
  margin: -7rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -7rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -7rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -7rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -7rem !important; }

.m-n11 {
  margin: -8rem !important; }

.mt-n11,
.my-n11 {
  margin-top: -8rem !important; }

.mr-n11,
.mx-n11 {
  margin-right: -8rem !important; }

.mb-n11,
.my-n11 {
  margin-bottom: -8rem !important; }

.ml-n11,
.mx-n11 {
  margin-left: -8rem !important; }

.m-n12 {
  margin: -9rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -9rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -9rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -9rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -9rem !important; }

.m-n13 {
  margin: -10rem !important; }

.mt-n13,
.my-n13 {
  margin-top: -10rem !important; }

.mr-n13,
.mx-n13 {
  margin-right: -10rem !important; }

.mb-n13,
.my-n13 {
  margin-bottom: -10rem !important; }

.ml-n13,
.mx-n13 {
  margin-left: -10rem !important; }

.m-n14 {
  margin: -11rem !important; }

.mt-n14,
.my-n14 {
  margin-top: -11rem !important; }

.mr-n14,
.mx-n14 {
  margin-right: -11rem !important; }

.mb-n14,
.my-n14 {
  margin-bottom: -11rem !important; }

.ml-n14,
.mx-n14 {
  margin-left: -11rem !important; }

.m-n15 {
  margin: -12rem !important; }

.mt-n15,
.my-n15 {
  margin-top: -12rem !important; }

.mr-n15,
.mx-n15 {
  margin-right: -12rem !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -12rem !important; }

.ml-n15,
.mx-n15 {
  margin-left: -12rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 2rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important; }
  .m-sm-6 {
    margin: 3rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3rem !important; }
  .m-sm-7 {
    margin: 4rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 4rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 4rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 4rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 4rem !important; }
  .m-sm-8 {
    margin: 5rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 5rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 5rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 5rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 5rem !important; }
  .m-sm-9 {
    margin: 6rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 6rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 6rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 6rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 6rem !important; }
  .m-sm-10 {
    margin: 7rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 7rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 7rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 7rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 7rem !important; }
  .m-sm-11 {
    margin: 8rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 8rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 8rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 8rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 8rem !important; }
  .m-sm-12 {
    margin: 9rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 9rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 9rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 9rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 9rem !important; }
  .m-sm-13 {
    margin: 10rem !important; }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 10rem !important; }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 10rem !important; }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 10rem !important; }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 10rem !important; }
  .m-sm-14 {
    margin: 11rem !important; }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 11rem !important; }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 11rem !important; }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 11rem !important; }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 11rem !important; }
  .m-sm-15 {
    margin: 12rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 12rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 12rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 12rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 12rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 2rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important; }
  .p-sm-6 {
    padding: 3rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3rem !important; }
  .p-sm-7 {
    padding: 4rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 4rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 4rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 4rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 4rem !important; }
  .p-sm-8 {
    padding: 5rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 5rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 5rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 5rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 5rem !important; }
  .p-sm-9 {
    padding: 6rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 6rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 6rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 6rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 6rem !important; }
  .p-sm-10 {
    padding: 7rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 7rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 7rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 7rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 7rem !important; }
  .p-sm-11 {
    padding: 8rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 8rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 8rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 8rem !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 8rem !important; }
  .p-sm-12 {
    padding: 9rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 9rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 9rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 9rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 9rem !important; }
  .p-sm-13 {
    padding: 10rem !important; }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 10rem !important; }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 10rem !important; }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 10rem !important; }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 10rem !important; }
  .p-sm-14 {
    padding: 11rem !important; }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 11rem !important; }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 11rem !important; }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 11rem !important; }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 11rem !important; }
  .p-sm-15 {
    padding: 12rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 12rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 12rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 12rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 12rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -2rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2rem !important; }
  .m-sm-n6 {
    margin: -3rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -3rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -3rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -3rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -3rem !important; }
  .m-sm-n7 {
    margin: -4rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -4rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -4rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -4rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -4rem !important; }
  .m-sm-n8 {
    margin: -5rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -5rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -5rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -5rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -5rem !important; }
  .m-sm-n9 {
    margin: -6rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -6rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -6rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -6rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -6rem !important; }
  .m-sm-n10 {
    margin: -7rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -7rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -7rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -7rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -7rem !important; }
  .m-sm-n11 {
    margin: -8rem !important; }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -8rem !important; }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -8rem !important; }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -8rem !important; }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -8rem !important; }
  .m-sm-n12 {
    margin: -9rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -9rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -9rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -9rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -9rem !important; }
  .m-sm-n13 {
    margin: -10rem !important; }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -10rem !important; }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -10rem !important; }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -10rem !important; }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -10rem !important; }
  .m-sm-n14 {
    margin: -11rem !important; }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -11rem !important; }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -11rem !important; }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -11rem !important; }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -11rem !important; }
  .m-sm-n15 {
    margin: -12rem !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -12rem !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -12rem !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -12rem !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -12rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 2rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important; }
  .m-md-6 {
    margin: 3rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3rem !important; }
  .m-md-7 {
    margin: 4rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 4rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 4rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 4rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 4rem !important; }
  .m-md-8 {
    margin: 5rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 5rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 5rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 5rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 5rem !important; }
  .m-md-9 {
    margin: 6rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 6rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 6rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 6rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 6rem !important; }
  .m-md-10 {
    margin: 7rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 7rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 7rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 7rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 7rem !important; }
  .m-md-11 {
    margin: 8rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 8rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 8rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 8rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 8rem !important; }
  .m-md-12 {
    margin: 9rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 9rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 9rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 9rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 9rem !important; }
  .m-md-13 {
    margin: 10rem !important; }
  .mt-md-13,
  .my-md-13 {
    margin-top: 10rem !important; }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 10rem !important; }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 10rem !important; }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 10rem !important; }
  .m-md-14 {
    margin: 11rem !important; }
  .mt-md-14,
  .my-md-14 {
    margin-top: 11rem !important; }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 11rem !important; }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 11rem !important; }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 11rem !important; }
  .m-md-15 {
    margin: 12rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 12rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 12rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 12rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 12rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 2rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important; }
  .p-md-6 {
    padding: 3rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3rem !important; }
  .p-md-7 {
    padding: 4rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 4rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 4rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 4rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 4rem !important; }
  .p-md-8 {
    padding: 5rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 5rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 5rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 5rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 5rem !important; }
  .p-md-9 {
    padding: 6rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 6rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 6rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 6rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 6rem !important; }
  .p-md-10 {
    padding: 7rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 7rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 7rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 7rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 7rem !important; }
  .p-md-11 {
    padding: 8rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 8rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 8rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 8rem !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 8rem !important; }
  .p-md-12 {
    padding: 9rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 9rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 9rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 9rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 9rem !important; }
  .p-md-13 {
    padding: 10rem !important; }
  .pt-md-13,
  .py-md-13 {
    padding-top: 10rem !important; }
  .pr-md-13,
  .px-md-13 {
    padding-right: 10rem !important; }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 10rem !important; }
  .pl-md-13,
  .px-md-13 {
    padding-left: 10rem !important; }
  .p-md-14 {
    padding: 11rem !important; }
  .pt-md-14,
  .py-md-14 {
    padding-top: 11rem !important; }
  .pr-md-14,
  .px-md-14 {
    padding-right: 11rem !important; }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 11rem !important; }
  .pl-md-14,
  .px-md-14 {
    padding-left: 11rem !important; }
  .p-md-15 {
    padding: 12rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 12rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 12rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 12rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 12rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -2rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2rem !important; }
  .m-md-n6 {
    margin: -3rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -3rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -3rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -3rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -3rem !important; }
  .m-md-n7 {
    margin: -4rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -4rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -4rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -4rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -4rem !important; }
  .m-md-n8 {
    margin: -5rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -5rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -5rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -5rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -5rem !important; }
  .m-md-n9 {
    margin: -6rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -6rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -6rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -6rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -6rem !important; }
  .m-md-n10 {
    margin: -7rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -7rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -7rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -7rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -7rem !important; }
  .m-md-n11 {
    margin: -8rem !important; }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -8rem !important; }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -8rem !important; }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -8rem !important; }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -8rem !important; }
  .m-md-n12 {
    margin: -9rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -9rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -9rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -9rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -9rem !important; }
  .m-md-n13 {
    margin: -10rem !important; }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -10rem !important; }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -10rem !important; }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -10rem !important; }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -10rem !important; }
  .m-md-n14 {
    margin: -11rem !important; }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -11rem !important; }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -11rem !important; }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -11rem !important; }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -11rem !important; }
  .m-md-n15 {
    margin: -12rem !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -12rem !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -12rem !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -12rem !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -12rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 2rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important; }
  .m-lg-6 {
    margin: 3rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3rem !important; }
  .m-lg-7 {
    margin: 4rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 4rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 4rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 4rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 4rem !important; }
  .m-lg-8 {
    margin: 5rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 5rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 5rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 5rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 5rem !important; }
  .m-lg-9 {
    margin: 6rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 6rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 6rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 6rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 6rem !important; }
  .m-lg-10 {
    margin: 7rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 7rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 7rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 7rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 7rem !important; }
  .m-lg-11 {
    margin: 8rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 8rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 8rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 8rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 8rem !important; }
  .m-lg-12 {
    margin: 9rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 9rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 9rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 9rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 9rem !important; }
  .m-lg-13 {
    margin: 10rem !important; }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 10rem !important; }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 10rem !important; }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 10rem !important; }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 10rem !important; }
  .m-lg-14 {
    margin: 11rem !important; }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 11rem !important; }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 11rem !important; }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 11rem !important; }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 11rem !important; }
  .m-lg-15 {
    margin: 12rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 12rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 12rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 12rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 12rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 2rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important; }
  .p-lg-6 {
    padding: 3rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3rem !important; }
  .p-lg-7 {
    padding: 4rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 4rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 4rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 4rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 4rem !important; }
  .p-lg-8 {
    padding: 5rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 5rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 5rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 5rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 5rem !important; }
  .p-lg-9 {
    padding: 6rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 6rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 6rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 6rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 6rem !important; }
  .p-lg-10 {
    padding: 7rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 7rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 7rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 7rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 7rem !important; }
  .p-lg-11 {
    padding: 8rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 8rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 8rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 8rem !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 8rem !important; }
  .p-lg-12 {
    padding: 9rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 9rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 9rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 9rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 9rem !important; }
  .p-lg-13 {
    padding: 10rem !important; }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 10rem !important; }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 10rem !important; }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 10rem !important; }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 10rem !important; }
  .p-lg-14 {
    padding: 11rem !important; }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 11rem !important; }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 11rem !important; }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 11rem !important; }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 11rem !important; }
  .p-lg-15 {
    padding: 12rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 12rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 12rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 12rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 12rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -2rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2rem !important; }
  .m-lg-n6 {
    margin: -3rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -3rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -3rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -3rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -3rem !important; }
  .m-lg-n7 {
    margin: -4rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -4rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -4rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -4rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -4rem !important; }
  .m-lg-n8 {
    margin: -5rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -5rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -5rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -5rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -5rem !important; }
  .m-lg-n9 {
    margin: -6rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -6rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -6rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -6rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -6rem !important; }
  .m-lg-n10 {
    margin: -7rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -7rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -7rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -7rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -7rem !important; }
  .m-lg-n11 {
    margin: -8rem !important; }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -8rem !important; }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -8rem !important; }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -8rem !important; }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -8rem !important; }
  .m-lg-n12 {
    margin: -9rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -9rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -9rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -9rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -9rem !important; }
  .m-lg-n13 {
    margin: -10rem !important; }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -10rem !important; }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -10rem !important; }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -10rem !important; }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -10rem !important; }
  .m-lg-n14 {
    margin: -11rem !important; }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -11rem !important; }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -11rem !important; }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -11rem !important; }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -11rem !important; }
  .m-lg-n15 {
    margin: -12rem !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -12rem !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -12rem !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -12rem !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -12rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 2rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important; }
  .m-xl-6 {
    margin: 3rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3rem !important; }
  .m-xl-7 {
    margin: 4rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 4rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 4rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 4rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 4rem !important; }
  .m-xl-8 {
    margin: 5rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 5rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 5rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 5rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 5rem !important; }
  .m-xl-9 {
    margin: 6rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 6rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 6rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 6rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 6rem !important; }
  .m-xl-10 {
    margin: 7rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 7rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 7rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 7rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 7rem !important; }
  .m-xl-11 {
    margin: 8rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 8rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 8rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 8rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 8rem !important; }
  .m-xl-12 {
    margin: 9rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 9rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 9rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 9rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 9rem !important; }
  .m-xl-13 {
    margin: 10rem !important; }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 10rem !important; }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 10rem !important; }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 10rem !important; }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 10rem !important; }
  .m-xl-14 {
    margin: 11rem !important; }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 11rem !important; }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 11rem !important; }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 11rem !important; }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 11rem !important; }
  .m-xl-15 {
    margin: 12rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 12rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 12rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 12rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 12rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 2rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important; }
  .p-xl-6 {
    padding: 3rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3rem !important; }
  .p-xl-7 {
    padding: 4rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 4rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 4rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 4rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 4rem !important; }
  .p-xl-8 {
    padding: 5rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 5rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 5rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 5rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 5rem !important; }
  .p-xl-9 {
    padding: 6rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 6rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 6rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 6rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 6rem !important; }
  .p-xl-10 {
    padding: 7rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 7rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 7rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 7rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 7rem !important; }
  .p-xl-11 {
    padding: 8rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 8rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 8rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 8rem !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 8rem !important; }
  .p-xl-12 {
    padding: 9rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 9rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 9rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 9rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 9rem !important; }
  .p-xl-13 {
    padding: 10rem !important; }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 10rem !important; }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 10rem !important; }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 10rem !important; }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 10rem !important; }
  .p-xl-14 {
    padding: 11rem !important; }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 11rem !important; }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 11rem !important; }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 11rem !important; }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 11rem !important; }
  .p-xl-15 {
    padding: 12rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 12rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 12rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 12rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 12rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -2rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2rem !important; }
  .m-xl-n6 {
    margin: -3rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -3rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -3rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -3rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -3rem !important; }
  .m-xl-n7 {
    margin: -4rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -4rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -4rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -4rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -4rem !important; }
  .m-xl-n8 {
    margin: -5rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -5rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -5rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -5rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -5rem !important; }
  .m-xl-n9 {
    margin: -6rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -6rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -6rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -6rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -6rem !important; }
  .m-xl-n10 {
    margin: -7rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -7rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -7rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -7rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -7rem !important; }
  .m-xl-n11 {
    margin: -8rem !important; }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -8rem !important; }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -8rem !important; }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -8rem !important; }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -8rem !important; }
  .m-xl-n12 {
    margin: -9rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -9rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -9rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -9rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -9rem !important; }
  .m-xl-n13 {
    margin: -10rem !important; }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -10rem !important; }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -10rem !important; }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -10rem !important; }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -10rem !important; }
  .m-xl-n14 {
    margin: -11rem !important; }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -11rem !important; }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -11rem !important; }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -11rem !important; }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -11rem !important; }
  .m-xl-n15 {
    margin: -12rem !important; }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -12rem !important; }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -12rem !important; }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -12rem !important; }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -12rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1900px) {
  .m-fhd-0 {
    margin: 0 !important; }
  .mt-fhd-0,
  .my-fhd-0 {
    margin-top: 0 !important; }
  .mr-fhd-0,
  .mx-fhd-0 {
    margin-right: 0 !important; }
  .mb-fhd-0,
  .my-fhd-0 {
    margin-bottom: 0 !important; }
  .ml-fhd-0,
  .mx-fhd-0 {
    margin-left: 0 !important; }
  .m-fhd-1 {
    margin: 0.25rem !important; }
  .mt-fhd-1,
  .my-fhd-1 {
    margin-top: 0.25rem !important; }
  .mr-fhd-1,
  .mx-fhd-1 {
    margin-right: 0.25rem !important; }
  .mb-fhd-1,
  .my-fhd-1 {
    margin-bottom: 0.25rem !important; }
  .ml-fhd-1,
  .mx-fhd-1 {
    margin-left: 0.25rem !important; }
  .m-fhd-2 {
    margin: 0.5rem !important; }
  .mt-fhd-2,
  .my-fhd-2 {
    margin-top: 0.5rem !important; }
  .mr-fhd-2,
  .mx-fhd-2 {
    margin-right: 0.5rem !important; }
  .mb-fhd-2,
  .my-fhd-2 {
    margin-bottom: 0.5rem !important; }
  .ml-fhd-2,
  .mx-fhd-2 {
    margin-left: 0.5rem !important; }
  .m-fhd-3 {
    margin: 1rem !important; }
  .mt-fhd-3,
  .my-fhd-3 {
    margin-top: 1rem !important; }
  .mr-fhd-3,
  .mx-fhd-3 {
    margin-right: 1rem !important; }
  .mb-fhd-3,
  .my-fhd-3 {
    margin-bottom: 1rem !important; }
  .ml-fhd-3,
  .mx-fhd-3 {
    margin-left: 1rem !important; }
  .m-fhd-4 {
    margin: 1.5rem !important; }
  .mt-fhd-4,
  .my-fhd-4 {
    margin-top: 1.5rem !important; }
  .mr-fhd-4,
  .mx-fhd-4 {
    margin-right: 1.5rem !important; }
  .mb-fhd-4,
  .my-fhd-4 {
    margin-bottom: 1.5rem !important; }
  .ml-fhd-4,
  .mx-fhd-4 {
    margin-left: 1.5rem !important; }
  .m-fhd-5 {
    margin: 2rem !important; }
  .mt-fhd-5,
  .my-fhd-5 {
    margin-top: 2rem !important; }
  .mr-fhd-5,
  .mx-fhd-5 {
    margin-right: 2rem !important; }
  .mb-fhd-5,
  .my-fhd-5 {
    margin-bottom: 2rem !important; }
  .ml-fhd-5,
  .mx-fhd-5 {
    margin-left: 2rem !important; }
  .m-fhd-6 {
    margin: 3rem !important; }
  .mt-fhd-6,
  .my-fhd-6 {
    margin-top: 3rem !important; }
  .mr-fhd-6,
  .mx-fhd-6 {
    margin-right: 3rem !important; }
  .mb-fhd-6,
  .my-fhd-6 {
    margin-bottom: 3rem !important; }
  .ml-fhd-6,
  .mx-fhd-6 {
    margin-left: 3rem !important; }
  .m-fhd-7 {
    margin: 4rem !important; }
  .mt-fhd-7,
  .my-fhd-7 {
    margin-top: 4rem !important; }
  .mr-fhd-7,
  .mx-fhd-7 {
    margin-right: 4rem !important; }
  .mb-fhd-7,
  .my-fhd-7 {
    margin-bottom: 4rem !important; }
  .ml-fhd-7,
  .mx-fhd-7 {
    margin-left: 4rem !important; }
  .m-fhd-8 {
    margin: 5rem !important; }
  .mt-fhd-8,
  .my-fhd-8 {
    margin-top: 5rem !important; }
  .mr-fhd-8,
  .mx-fhd-8 {
    margin-right: 5rem !important; }
  .mb-fhd-8,
  .my-fhd-8 {
    margin-bottom: 5rem !important; }
  .ml-fhd-8,
  .mx-fhd-8 {
    margin-left: 5rem !important; }
  .m-fhd-9 {
    margin: 6rem !important; }
  .mt-fhd-9,
  .my-fhd-9 {
    margin-top: 6rem !important; }
  .mr-fhd-9,
  .mx-fhd-9 {
    margin-right: 6rem !important; }
  .mb-fhd-9,
  .my-fhd-9 {
    margin-bottom: 6rem !important; }
  .ml-fhd-9,
  .mx-fhd-9 {
    margin-left: 6rem !important; }
  .m-fhd-10 {
    margin: 7rem !important; }
  .mt-fhd-10,
  .my-fhd-10 {
    margin-top: 7rem !important; }
  .mr-fhd-10,
  .mx-fhd-10 {
    margin-right: 7rem !important; }
  .mb-fhd-10,
  .my-fhd-10 {
    margin-bottom: 7rem !important; }
  .ml-fhd-10,
  .mx-fhd-10 {
    margin-left: 7rem !important; }
  .m-fhd-11 {
    margin: 8rem !important; }
  .mt-fhd-11,
  .my-fhd-11 {
    margin-top: 8rem !important; }
  .mr-fhd-11,
  .mx-fhd-11 {
    margin-right: 8rem !important; }
  .mb-fhd-11,
  .my-fhd-11 {
    margin-bottom: 8rem !important; }
  .ml-fhd-11,
  .mx-fhd-11 {
    margin-left: 8rem !important; }
  .m-fhd-12 {
    margin: 9rem !important; }
  .mt-fhd-12,
  .my-fhd-12 {
    margin-top: 9rem !important; }
  .mr-fhd-12,
  .mx-fhd-12 {
    margin-right: 9rem !important; }
  .mb-fhd-12,
  .my-fhd-12 {
    margin-bottom: 9rem !important; }
  .ml-fhd-12,
  .mx-fhd-12 {
    margin-left: 9rem !important; }
  .m-fhd-13 {
    margin: 10rem !important; }
  .mt-fhd-13,
  .my-fhd-13 {
    margin-top: 10rem !important; }
  .mr-fhd-13,
  .mx-fhd-13 {
    margin-right: 10rem !important; }
  .mb-fhd-13,
  .my-fhd-13 {
    margin-bottom: 10rem !important; }
  .ml-fhd-13,
  .mx-fhd-13 {
    margin-left: 10rem !important; }
  .m-fhd-14 {
    margin: 11rem !important; }
  .mt-fhd-14,
  .my-fhd-14 {
    margin-top: 11rem !important; }
  .mr-fhd-14,
  .mx-fhd-14 {
    margin-right: 11rem !important; }
  .mb-fhd-14,
  .my-fhd-14 {
    margin-bottom: 11rem !important; }
  .ml-fhd-14,
  .mx-fhd-14 {
    margin-left: 11rem !important; }
  .m-fhd-15 {
    margin: 12rem !important; }
  .mt-fhd-15,
  .my-fhd-15 {
    margin-top: 12rem !important; }
  .mr-fhd-15,
  .mx-fhd-15 {
    margin-right: 12rem !important; }
  .mb-fhd-15,
  .my-fhd-15 {
    margin-bottom: 12rem !important; }
  .ml-fhd-15,
  .mx-fhd-15 {
    margin-left: 12rem !important; }
  .p-fhd-0 {
    padding: 0 !important; }
  .pt-fhd-0,
  .py-fhd-0 {
    padding-top: 0 !important; }
  .pr-fhd-0,
  .px-fhd-0 {
    padding-right: 0 !important; }
  .pb-fhd-0,
  .py-fhd-0 {
    padding-bottom: 0 !important; }
  .pl-fhd-0,
  .px-fhd-0 {
    padding-left: 0 !important; }
  .p-fhd-1 {
    padding: 0.25rem !important; }
  .pt-fhd-1,
  .py-fhd-1 {
    padding-top: 0.25rem !important; }
  .pr-fhd-1,
  .px-fhd-1 {
    padding-right: 0.25rem !important; }
  .pb-fhd-1,
  .py-fhd-1 {
    padding-bottom: 0.25rem !important; }
  .pl-fhd-1,
  .px-fhd-1 {
    padding-left: 0.25rem !important; }
  .p-fhd-2 {
    padding: 0.5rem !important; }
  .pt-fhd-2,
  .py-fhd-2 {
    padding-top: 0.5rem !important; }
  .pr-fhd-2,
  .px-fhd-2 {
    padding-right: 0.5rem !important; }
  .pb-fhd-2,
  .py-fhd-2 {
    padding-bottom: 0.5rem !important; }
  .pl-fhd-2,
  .px-fhd-2 {
    padding-left: 0.5rem !important; }
  .p-fhd-3 {
    padding: 1rem !important; }
  .pt-fhd-3,
  .py-fhd-3 {
    padding-top: 1rem !important; }
  .pr-fhd-3,
  .px-fhd-3 {
    padding-right: 1rem !important; }
  .pb-fhd-3,
  .py-fhd-3 {
    padding-bottom: 1rem !important; }
  .pl-fhd-3,
  .px-fhd-3 {
    padding-left: 1rem !important; }
  .p-fhd-4 {
    padding: 1.5rem !important; }
  .pt-fhd-4,
  .py-fhd-4 {
    padding-top: 1.5rem !important; }
  .pr-fhd-4,
  .px-fhd-4 {
    padding-right: 1.5rem !important; }
  .pb-fhd-4,
  .py-fhd-4 {
    padding-bottom: 1.5rem !important; }
  .pl-fhd-4,
  .px-fhd-4 {
    padding-left: 1.5rem !important; }
  .p-fhd-5 {
    padding: 2rem !important; }
  .pt-fhd-5,
  .py-fhd-5 {
    padding-top: 2rem !important; }
  .pr-fhd-5,
  .px-fhd-5 {
    padding-right: 2rem !important; }
  .pb-fhd-5,
  .py-fhd-5 {
    padding-bottom: 2rem !important; }
  .pl-fhd-5,
  .px-fhd-5 {
    padding-left: 2rem !important; }
  .p-fhd-6 {
    padding: 3rem !important; }
  .pt-fhd-6,
  .py-fhd-6 {
    padding-top: 3rem !important; }
  .pr-fhd-6,
  .px-fhd-6 {
    padding-right: 3rem !important; }
  .pb-fhd-6,
  .py-fhd-6 {
    padding-bottom: 3rem !important; }
  .pl-fhd-6,
  .px-fhd-6 {
    padding-left: 3rem !important; }
  .p-fhd-7 {
    padding: 4rem !important; }
  .pt-fhd-7,
  .py-fhd-7 {
    padding-top: 4rem !important; }
  .pr-fhd-7,
  .px-fhd-7 {
    padding-right: 4rem !important; }
  .pb-fhd-7,
  .py-fhd-7 {
    padding-bottom: 4rem !important; }
  .pl-fhd-7,
  .px-fhd-7 {
    padding-left: 4rem !important; }
  .p-fhd-8 {
    padding: 5rem !important; }
  .pt-fhd-8,
  .py-fhd-8 {
    padding-top: 5rem !important; }
  .pr-fhd-8,
  .px-fhd-8 {
    padding-right: 5rem !important; }
  .pb-fhd-8,
  .py-fhd-8 {
    padding-bottom: 5rem !important; }
  .pl-fhd-8,
  .px-fhd-8 {
    padding-left: 5rem !important; }
  .p-fhd-9 {
    padding: 6rem !important; }
  .pt-fhd-9,
  .py-fhd-9 {
    padding-top: 6rem !important; }
  .pr-fhd-9,
  .px-fhd-9 {
    padding-right: 6rem !important; }
  .pb-fhd-9,
  .py-fhd-9 {
    padding-bottom: 6rem !important; }
  .pl-fhd-9,
  .px-fhd-9 {
    padding-left: 6rem !important; }
  .p-fhd-10 {
    padding: 7rem !important; }
  .pt-fhd-10,
  .py-fhd-10 {
    padding-top: 7rem !important; }
  .pr-fhd-10,
  .px-fhd-10 {
    padding-right: 7rem !important; }
  .pb-fhd-10,
  .py-fhd-10 {
    padding-bottom: 7rem !important; }
  .pl-fhd-10,
  .px-fhd-10 {
    padding-left: 7rem !important; }
  .p-fhd-11 {
    padding: 8rem !important; }
  .pt-fhd-11,
  .py-fhd-11 {
    padding-top: 8rem !important; }
  .pr-fhd-11,
  .px-fhd-11 {
    padding-right: 8rem !important; }
  .pb-fhd-11,
  .py-fhd-11 {
    padding-bottom: 8rem !important; }
  .pl-fhd-11,
  .px-fhd-11 {
    padding-left: 8rem !important; }
  .p-fhd-12 {
    padding: 9rem !important; }
  .pt-fhd-12,
  .py-fhd-12 {
    padding-top: 9rem !important; }
  .pr-fhd-12,
  .px-fhd-12 {
    padding-right: 9rem !important; }
  .pb-fhd-12,
  .py-fhd-12 {
    padding-bottom: 9rem !important; }
  .pl-fhd-12,
  .px-fhd-12 {
    padding-left: 9rem !important; }
  .p-fhd-13 {
    padding: 10rem !important; }
  .pt-fhd-13,
  .py-fhd-13 {
    padding-top: 10rem !important; }
  .pr-fhd-13,
  .px-fhd-13 {
    padding-right: 10rem !important; }
  .pb-fhd-13,
  .py-fhd-13 {
    padding-bottom: 10rem !important; }
  .pl-fhd-13,
  .px-fhd-13 {
    padding-left: 10rem !important; }
  .p-fhd-14 {
    padding: 11rem !important; }
  .pt-fhd-14,
  .py-fhd-14 {
    padding-top: 11rem !important; }
  .pr-fhd-14,
  .px-fhd-14 {
    padding-right: 11rem !important; }
  .pb-fhd-14,
  .py-fhd-14 {
    padding-bottom: 11rem !important; }
  .pl-fhd-14,
  .px-fhd-14 {
    padding-left: 11rem !important; }
  .p-fhd-15 {
    padding: 12rem !important; }
  .pt-fhd-15,
  .py-fhd-15 {
    padding-top: 12rem !important; }
  .pr-fhd-15,
  .px-fhd-15 {
    padding-right: 12rem !important; }
  .pb-fhd-15,
  .py-fhd-15 {
    padding-bottom: 12rem !important; }
  .pl-fhd-15,
  .px-fhd-15 {
    padding-left: 12rem !important; }
  .m-fhd-n1 {
    margin: -0.25rem !important; }
  .mt-fhd-n1,
  .my-fhd-n1 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n1,
  .mx-fhd-n1 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n1,
  .my-fhd-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n1,
  .mx-fhd-n1 {
    margin-left: -0.25rem !important; }
  .m-fhd-n2 {
    margin: -0.5rem !important; }
  .mt-fhd-n2,
  .my-fhd-n2 {
    margin-top: -0.5rem !important; }
  .mr-fhd-n2,
  .mx-fhd-n2 {
    margin-right: -0.5rem !important; }
  .mb-fhd-n2,
  .my-fhd-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-fhd-n2,
  .mx-fhd-n2 {
    margin-left: -0.5rem !important; }
  .m-fhd-n3 {
    margin: -1rem !important; }
  .mt-fhd-n3,
  .my-fhd-n3 {
    margin-top: -1rem !important; }
  .mr-fhd-n3,
  .mx-fhd-n3 {
    margin-right: -1rem !important; }
  .mb-fhd-n3,
  .my-fhd-n3 {
    margin-bottom: -1rem !important; }
  .ml-fhd-n3,
  .mx-fhd-n3 {
    margin-left: -1rem !important; }
  .m-fhd-n4 {
    margin: -1.5rem !important; }
  .mt-fhd-n4,
  .my-fhd-n4 {
    margin-top: -1.5rem !important; }
  .mr-fhd-n4,
  .mx-fhd-n4 {
    margin-right: -1.5rem !important; }
  .mb-fhd-n4,
  .my-fhd-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-fhd-n4,
  .mx-fhd-n4 {
    margin-left: -1.5rem !important; }
  .m-fhd-n5 {
    margin: -2rem !important; }
  .mt-fhd-n5,
  .my-fhd-n5 {
    margin-top: -2rem !important; }
  .mr-fhd-n5,
  .mx-fhd-n5 {
    margin-right: -2rem !important; }
  .mb-fhd-n5,
  .my-fhd-n5 {
    margin-bottom: -2rem !important; }
  .ml-fhd-n5,
  .mx-fhd-n5 {
    margin-left: -2rem !important; }
  .m-fhd-n6 {
    margin: -3rem !important; }
  .mt-fhd-n6,
  .my-fhd-n6 {
    margin-top: -3rem !important; }
  .mr-fhd-n6,
  .mx-fhd-n6 {
    margin-right: -3rem !important; }
  .mb-fhd-n6,
  .my-fhd-n6 {
    margin-bottom: -3rem !important; }
  .ml-fhd-n6,
  .mx-fhd-n6 {
    margin-left: -3rem !important; }
  .m-fhd-n7 {
    margin: -4rem !important; }
  .mt-fhd-n7,
  .my-fhd-n7 {
    margin-top: -4rem !important; }
  .mr-fhd-n7,
  .mx-fhd-n7 {
    margin-right: -4rem !important; }
  .mb-fhd-n7,
  .my-fhd-n7 {
    margin-bottom: -4rem !important; }
  .ml-fhd-n7,
  .mx-fhd-n7 {
    margin-left: -4rem !important; }
  .m-fhd-n8 {
    margin: -5rem !important; }
  .mt-fhd-n8,
  .my-fhd-n8 {
    margin-top: -5rem !important; }
  .mr-fhd-n8,
  .mx-fhd-n8 {
    margin-right: -5rem !important; }
  .mb-fhd-n8,
  .my-fhd-n8 {
    margin-bottom: -5rem !important; }
  .ml-fhd-n8,
  .mx-fhd-n8 {
    margin-left: -5rem !important; }
  .m-fhd-n9 {
    margin: -6rem !important; }
  .mt-fhd-n9,
  .my-fhd-n9 {
    margin-top: -6rem !important; }
  .mr-fhd-n9,
  .mx-fhd-n9 {
    margin-right: -6rem !important; }
  .mb-fhd-n9,
  .my-fhd-n9 {
    margin-bottom: -6rem !important; }
  .ml-fhd-n9,
  .mx-fhd-n9 {
    margin-left: -6rem !important; }
  .m-fhd-n10 {
    margin: -7rem !important; }
  .mt-fhd-n10,
  .my-fhd-n10 {
    margin-top: -7rem !important; }
  .mr-fhd-n10,
  .mx-fhd-n10 {
    margin-right: -7rem !important; }
  .mb-fhd-n10,
  .my-fhd-n10 {
    margin-bottom: -7rem !important; }
  .ml-fhd-n10,
  .mx-fhd-n10 {
    margin-left: -7rem !important; }
  .m-fhd-n11 {
    margin: -8rem !important; }
  .mt-fhd-n11,
  .my-fhd-n11 {
    margin-top: -8rem !important; }
  .mr-fhd-n11,
  .mx-fhd-n11 {
    margin-right: -8rem !important; }
  .mb-fhd-n11,
  .my-fhd-n11 {
    margin-bottom: -8rem !important; }
  .ml-fhd-n11,
  .mx-fhd-n11 {
    margin-left: -8rem !important; }
  .m-fhd-n12 {
    margin: -9rem !important; }
  .mt-fhd-n12,
  .my-fhd-n12 {
    margin-top: -9rem !important; }
  .mr-fhd-n12,
  .mx-fhd-n12 {
    margin-right: -9rem !important; }
  .mb-fhd-n12,
  .my-fhd-n12 {
    margin-bottom: -9rem !important; }
  .ml-fhd-n12,
  .mx-fhd-n12 {
    margin-left: -9rem !important; }
  .m-fhd-n13 {
    margin: -10rem !important; }
  .mt-fhd-n13,
  .my-fhd-n13 {
    margin-top: -10rem !important; }
  .mr-fhd-n13,
  .mx-fhd-n13 {
    margin-right: -10rem !important; }
  .mb-fhd-n13,
  .my-fhd-n13 {
    margin-bottom: -10rem !important; }
  .ml-fhd-n13,
  .mx-fhd-n13 {
    margin-left: -10rem !important; }
  .m-fhd-n14 {
    margin: -11rem !important; }
  .mt-fhd-n14,
  .my-fhd-n14 {
    margin-top: -11rem !important; }
  .mr-fhd-n14,
  .mx-fhd-n14 {
    margin-right: -11rem !important; }
  .mb-fhd-n14,
  .my-fhd-n14 {
    margin-bottom: -11rem !important; }
  .ml-fhd-n14,
  .mx-fhd-n14 {
    margin-left: -11rem !important; }
  .m-fhd-n15 {
    margin: -12rem !important; }
  .mt-fhd-n15,
  .my-fhd-n15 {
    margin-top: -12rem !important; }
  .mr-fhd-n15,
  .mx-fhd-n15 {
    margin-right: -12rem !important; }
  .mb-fhd-n15,
  .my-fhd-n15 {
    margin-bottom: -12rem !important; }
  .ml-fhd-n15,
  .mx-fhd-n15 {
    margin-left: -12rem !important; }
  .m-fhd-auto {
    margin: auto !important; }
  .mt-fhd-auto,
  .my-fhd-auto {
    margin-top: auto !important; }
  .mr-fhd-auto,
  .mx-fhd-auto {
    margin-right: auto !important; }
  .mb-fhd-auto,
  .my-fhd-auto {
    margin-bottom: auto !important; }
  .ml-fhd-auto,
  .mx-fhd-auto {
    margin-left: auto !important; } }

@media (min-width: 2540px) {
  .m-qhd-0 {
    margin: 0 !important; }
  .mt-qhd-0,
  .my-qhd-0 {
    margin-top: 0 !important; }
  .mr-qhd-0,
  .mx-qhd-0 {
    margin-right: 0 !important; }
  .mb-qhd-0,
  .my-qhd-0 {
    margin-bottom: 0 !important; }
  .ml-qhd-0,
  .mx-qhd-0 {
    margin-left: 0 !important; }
  .m-qhd-1 {
    margin: 0.25rem !important; }
  .mt-qhd-1,
  .my-qhd-1 {
    margin-top: 0.25rem !important; }
  .mr-qhd-1,
  .mx-qhd-1 {
    margin-right: 0.25rem !important; }
  .mb-qhd-1,
  .my-qhd-1 {
    margin-bottom: 0.25rem !important; }
  .ml-qhd-1,
  .mx-qhd-1 {
    margin-left: 0.25rem !important; }
  .m-qhd-2 {
    margin: 0.5rem !important; }
  .mt-qhd-2,
  .my-qhd-2 {
    margin-top: 0.5rem !important; }
  .mr-qhd-2,
  .mx-qhd-2 {
    margin-right: 0.5rem !important; }
  .mb-qhd-2,
  .my-qhd-2 {
    margin-bottom: 0.5rem !important; }
  .ml-qhd-2,
  .mx-qhd-2 {
    margin-left: 0.5rem !important; }
  .m-qhd-3 {
    margin: 1rem !important; }
  .mt-qhd-3,
  .my-qhd-3 {
    margin-top: 1rem !important; }
  .mr-qhd-3,
  .mx-qhd-3 {
    margin-right: 1rem !important; }
  .mb-qhd-3,
  .my-qhd-3 {
    margin-bottom: 1rem !important; }
  .ml-qhd-3,
  .mx-qhd-3 {
    margin-left: 1rem !important; }
  .m-qhd-4 {
    margin: 1.5rem !important; }
  .mt-qhd-4,
  .my-qhd-4 {
    margin-top: 1.5rem !important; }
  .mr-qhd-4,
  .mx-qhd-4 {
    margin-right: 1.5rem !important; }
  .mb-qhd-4,
  .my-qhd-4 {
    margin-bottom: 1.5rem !important; }
  .ml-qhd-4,
  .mx-qhd-4 {
    margin-left: 1.5rem !important; }
  .m-qhd-5 {
    margin: 2rem !important; }
  .mt-qhd-5,
  .my-qhd-5 {
    margin-top: 2rem !important; }
  .mr-qhd-5,
  .mx-qhd-5 {
    margin-right: 2rem !important; }
  .mb-qhd-5,
  .my-qhd-5 {
    margin-bottom: 2rem !important; }
  .ml-qhd-5,
  .mx-qhd-5 {
    margin-left: 2rem !important; }
  .m-qhd-6 {
    margin: 3rem !important; }
  .mt-qhd-6,
  .my-qhd-6 {
    margin-top: 3rem !important; }
  .mr-qhd-6,
  .mx-qhd-6 {
    margin-right: 3rem !important; }
  .mb-qhd-6,
  .my-qhd-6 {
    margin-bottom: 3rem !important; }
  .ml-qhd-6,
  .mx-qhd-6 {
    margin-left: 3rem !important; }
  .m-qhd-7 {
    margin: 4rem !important; }
  .mt-qhd-7,
  .my-qhd-7 {
    margin-top: 4rem !important; }
  .mr-qhd-7,
  .mx-qhd-7 {
    margin-right: 4rem !important; }
  .mb-qhd-7,
  .my-qhd-7 {
    margin-bottom: 4rem !important; }
  .ml-qhd-7,
  .mx-qhd-7 {
    margin-left: 4rem !important; }
  .m-qhd-8 {
    margin: 5rem !important; }
  .mt-qhd-8,
  .my-qhd-8 {
    margin-top: 5rem !important; }
  .mr-qhd-8,
  .mx-qhd-8 {
    margin-right: 5rem !important; }
  .mb-qhd-8,
  .my-qhd-8 {
    margin-bottom: 5rem !important; }
  .ml-qhd-8,
  .mx-qhd-8 {
    margin-left: 5rem !important; }
  .m-qhd-9 {
    margin: 6rem !important; }
  .mt-qhd-9,
  .my-qhd-9 {
    margin-top: 6rem !important; }
  .mr-qhd-9,
  .mx-qhd-9 {
    margin-right: 6rem !important; }
  .mb-qhd-9,
  .my-qhd-9 {
    margin-bottom: 6rem !important; }
  .ml-qhd-9,
  .mx-qhd-9 {
    margin-left: 6rem !important; }
  .m-qhd-10 {
    margin: 7rem !important; }
  .mt-qhd-10,
  .my-qhd-10 {
    margin-top: 7rem !important; }
  .mr-qhd-10,
  .mx-qhd-10 {
    margin-right: 7rem !important; }
  .mb-qhd-10,
  .my-qhd-10 {
    margin-bottom: 7rem !important; }
  .ml-qhd-10,
  .mx-qhd-10 {
    margin-left: 7rem !important; }
  .m-qhd-11 {
    margin: 8rem !important; }
  .mt-qhd-11,
  .my-qhd-11 {
    margin-top: 8rem !important; }
  .mr-qhd-11,
  .mx-qhd-11 {
    margin-right: 8rem !important; }
  .mb-qhd-11,
  .my-qhd-11 {
    margin-bottom: 8rem !important; }
  .ml-qhd-11,
  .mx-qhd-11 {
    margin-left: 8rem !important; }
  .m-qhd-12 {
    margin: 9rem !important; }
  .mt-qhd-12,
  .my-qhd-12 {
    margin-top: 9rem !important; }
  .mr-qhd-12,
  .mx-qhd-12 {
    margin-right: 9rem !important; }
  .mb-qhd-12,
  .my-qhd-12 {
    margin-bottom: 9rem !important; }
  .ml-qhd-12,
  .mx-qhd-12 {
    margin-left: 9rem !important; }
  .m-qhd-13 {
    margin: 10rem !important; }
  .mt-qhd-13,
  .my-qhd-13 {
    margin-top: 10rem !important; }
  .mr-qhd-13,
  .mx-qhd-13 {
    margin-right: 10rem !important; }
  .mb-qhd-13,
  .my-qhd-13 {
    margin-bottom: 10rem !important; }
  .ml-qhd-13,
  .mx-qhd-13 {
    margin-left: 10rem !important; }
  .m-qhd-14 {
    margin: 11rem !important; }
  .mt-qhd-14,
  .my-qhd-14 {
    margin-top: 11rem !important; }
  .mr-qhd-14,
  .mx-qhd-14 {
    margin-right: 11rem !important; }
  .mb-qhd-14,
  .my-qhd-14 {
    margin-bottom: 11rem !important; }
  .ml-qhd-14,
  .mx-qhd-14 {
    margin-left: 11rem !important; }
  .m-qhd-15 {
    margin: 12rem !important; }
  .mt-qhd-15,
  .my-qhd-15 {
    margin-top: 12rem !important; }
  .mr-qhd-15,
  .mx-qhd-15 {
    margin-right: 12rem !important; }
  .mb-qhd-15,
  .my-qhd-15 {
    margin-bottom: 12rem !important; }
  .ml-qhd-15,
  .mx-qhd-15 {
    margin-left: 12rem !important; }
  .p-qhd-0 {
    padding: 0 !important; }
  .pt-qhd-0,
  .py-qhd-0 {
    padding-top: 0 !important; }
  .pr-qhd-0,
  .px-qhd-0 {
    padding-right: 0 !important; }
  .pb-qhd-0,
  .py-qhd-0 {
    padding-bottom: 0 !important; }
  .pl-qhd-0,
  .px-qhd-0 {
    padding-left: 0 !important; }
  .p-qhd-1 {
    padding: 0.25rem !important; }
  .pt-qhd-1,
  .py-qhd-1 {
    padding-top: 0.25rem !important; }
  .pr-qhd-1,
  .px-qhd-1 {
    padding-right: 0.25rem !important; }
  .pb-qhd-1,
  .py-qhd-1 {
    padding-bottom: 0.25rem !important; }
  .pl-qhd-1,
  .px-qhd-1 {
    padding-left: 0.25rem !important; }
  .p-qhd-2 {
    padding: 0.5rem !important; }
  .pt-qhd-2,
  .py-qhd-2 {
    padding-top: 0.5rem !important; }
  .pr-qhd-2,
  .px-qhd-2 {
    padding-right: 0.5rem !important; }
  .pb-qhd-2,
  .py-qhd-2 {
    padding-bottom: 0.5rem !important; }
  .pl-qhd-2,
  .px-qhd-2 {
    padding-left: 0.5rem !important; }
  .p-qhd-3 {
    padding: 1rem !important; }
  .pt-qhd-3,
  .py-qhd-3 {
    padding-top: 1rem !important; }
  .pr-qhd-3,
  .px-qhd-3 {
    padding-right: 1rem !important; }
  .pb-qhd-3,
  .py-qhd-3 {
    padding-bottom: 1rem !important; }
  .pl-qhd-3,
  .px-qhd-3 {
    padding-left: 1rem !important; }
  .p-qhd-4 {
    padding: 1.5rem !important; }
  .pt-qhd-4,
  .py-qhd-4 {
    padding-top: 1.5rem !important; }
  .pr-qhd-4,
  .px-qhd-4 {
    padding-right: 1.5rem !important; }
  .pb-qhd-4,
  .py-qhd-4 {
    padding-bottom: 1.5rem !important; }
  .pl-qhd-4,
  .px-qhd-4 {
    padding-left: 1.5rem !important; }
  .p-qhd-5 {
    padding: 2rem !important; }
  .pt-qhd-5,
  .py-qhd-5 {
    padding-top: 2rem !important; }
  .pr-qhd-5,
  .px-qhd-5 {
    padding-right: 2rem !important; }
  .pb-qhd-5,
  .py-qhd-5 {
    padding-bottom: 2rem !important; }
  .pl-qhd-5,
  .px-qhd-5 {
    padding-left: 2rem !important; }
  .p-qhd-6 {
    padding: 3rem !important; }
  .pt-qhd-6,
  .py-qhd-6 {
    padding-top: 3rem !important; }
  .pr-qhd-6,
  .px-qhd-6 {
    padding-right: 3rem !important; }
  .pb-qhd-6,
  .py-qhd-6 {
    padding-bottom: 3rem !important; }
  .pl-qhd-6,
  .px-qhd-6 {
    padding-left: 3rem !important; }
  .p-qhd-7 {
    padding: 4rem !important; }
  .pt-qhd-7,
  .py-qhd-7 {
    padding-top: 4rem !important; }
  .pr-qhd-7,
  .px-qhd-7 {
    padding-right: 4rem !important; }
  .pb-qhd-7,
  .py-qhd-7 {
    padding-bottom: 4rem !important; }
  .pl-qhd-7,
  .px-qhd-7 {
    padding-left: 4rem !important; }
  .p-qhd-8 {
    padding: 5rem !important; }
  .pt-qhd-8,
  .py-qhd-8 {
    padding-top: 5rem !important; }
  .pr-qhd-8,
  .px-qhd-8 {
    padding-right: 5rem !important; }
  .pb-qhd-8,
  .py-qhd-8 {
    padding-bottom: 5rem !important; }
  .pl-qhd-8,
  .px-qhd-8 {
    padding-left: 5rem !important; }
  .p-qhd-9 {
    padding: 6rem !important; }
  .pt-qhd-9,
  .py-qhd-9 {
    padding-top: 6rem !important; }
  .pr-qhd-9,
  .px-qhd-9 {
    padding-right: 6rem !important; }
  .pb-qhd-9,
  .py-qhd-9 {
    padding-bottom: 6rem !important; }
  .pl-qhd-9,
  .px-qhd-9 {
    padding-left: 6rem !important; }
  .p-qhd-10 {
    padding: 7rem !important; }
  .pt-qhd-10,
  .py-qhd-10 {
    padding-top: 7rem !important; }
  .pr-qhd-10,
  .px-qhd-10 {
    padding-right: 7rem !important; }
  .pb-qhd-10,
  .py-qhd-10 {
    padding-bottom: 7rem !important; }
  .pl-qhd-10,
  .px-qhd-10 {
    padding-left: 7rem !important; }
  .p-qhd-11 {
    padding: 8rem !important; }
  .pt-qhd-11,
  .py-qhd-11 {
    padding-top: 8rem !important; }
  .pr-qhd-11,
  .px-qhd-11 {
    padding-right: 8rem !important; }
  .pb-qhd-11,
  .py-qhd-11 {
    padding-bottom: 8rem !important; }
  .pl-qhd-11,
  .px-qhd-11 {
    padding-left: 8rem !important; }
  .p-qhd-12 {
    padding: 9rem !important; }
  .pt-qhd-12,
  .py-qhd-12 {
    padding-top: 9rem !important; }
  .pr-qhd-12,
  .px-qhd-12 {
    padding-right: 9rem !important; }
  .pb-qhd-12,
  .py-qhd-12 {
    padding-bottom: 9rem !important; }
  .pl-qhd-12,
  .px-qhd-12 {
    padding-left: 9rem !important; }
  .p-qhd-13 {
    padding: 10rem !important; }
  .pt-qhd-13,
  .py-qhd-13 {
    padding-top: 10rem !important; }
  .pr-qhd-13,
  .px-qhd-13 {
    padding-right: 10rem !important; }
  .pb-qhd-13,
  .py-qhd-13 {
    padding-bottom: 10rem !important; }
  .pl-qhd-13,
  .px-qhd-13 {
    padding-left: 10rem !important; }
  .p-qhd-14 {
    padding: 11rem !important; }
  .pt-qhd-14,
  .py-qhd-14 {
    padding-top: 11rem !important; }
  .pr-qhd-14,
  .px-qhd-14 {
    padding-right: 11rem !important; }
  .pb-qhd-14,
  .py-qhd-14 {
    padding-bottom: 11rem !important; }
  .pl-qhd-14,
  .px-qhd-14 {
    padding-left: 11rem !important; }
  .p-qhd-15 {
    padding: 12rem !important; }
  .pt-qhd-15,
  .py-qhd-15 {
    padding-top: 12rem !important; }
  .pr-qhd-15,
  .px-qhd-15 {
    padding-right: 12rem !important; }
  .pb-qhd-15,
  .py-qhd-15 {
    padding-bottom: 12rem !important; }
  .pl-qhd-15,
  .px-qhd-15 {
    padding-left: 12rem !important; }
  .m-qhd-n1 {
    margin: -0.25rem !important; }
  .mt-qhd-n1,
  .my-qhd-n1 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n1,
  .mx-qhd-n1 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n1,
  .my-qhd-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n1,
  .mx-qhd-n1 {
    margin-left: -0.25rem !important; }
  .m-qhd-n2 {
    margin: -0.5rem !important; }
  .mt-qhd-n2,
  .my-qhd-n2 {
    margin-top: -0.5rem !important; }
  .mr-qhd-n2,
  .mx-qhd-n2 {
    margin-right: -0.5rem !important; }
  .mb-qhd-n2,
  .my-qhd-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-qhd-n2,
  .mx-qhd-n2 {
    margin-left: -0.5rem !important; }
  .m-qhd-n3 {
    margin: -1rem !important; }
  .mt-qhd-n3,
  .my-qhd-n3 {
    margin-top: -1rem !important; }
  .mr-qhd-n3,
  .mx-qhd-n3 {
    margin-right: -1rem !important; }
  .mb-qhd-n3,
  .my-qhd-n3 {
    margin-bottom: -1rem !important; }
  .ml-qhd-n3,
  .mx-qhd-n3 {
    margin-left: -1rem !important; }
  .m-qhd-n4 {
    margin: -1.5rem !important; }
  .mt-qhd-n4,
  .my-qhd-n4 {
    margin-top: -1.5rem !important; }
  .mr-qhd-n4,
  .mx-qhd-n4 {
    margin-right: -1.5rem !important; }
  .mb-qhd-n4,
  .my-qhd-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-qhd-n4,
  .mx-qhd-n4 {
    margin-left: -1.5rem !important; }
  .m-qhd-n5 {
    margin: -2rem !important; }
  .mt-qhd-n5,
  .my-qhd-n5 {
    margin-top: -2rem !important; }
  .mr-qhd-n5,
  .mx-qhd-n5 {
    margin-right: -2rem !important; }
  .mb-qhd-n5,
  .my-qhd-n5 {
    margin-bottom: -2rem !important; }
  .ml-qhd-n5,
  .mx-qhd-n5 {
    margin-left: -2rem !important; }
  .m-qhd-n6 {
    margin: -3rem !important; }
  .mt-qhd-n6,
  .my-qhd-n6 {
    margin-top: -3rem !important; }
  .mr-qhd-n6,
  .mx-qhd-n6 {
    margin-right: -3rem !important; }
  .mb-qhd-n6,
  .my-qhd-n6 {
    margin-bottom: -3rem !important; }
  .ml-qhd-n6,
  .mx-qhd-n6 {
    margin-left: -3rem !important; }
  .m-qhd-n7 {
    margin: -4rem !important; }
  .mt-qhd-n7,
  .my-qhd-n7 {
    margin-top: -4rem !important; }
  .mr-qhd-n7,
  .mx-qhd-n7 {
    margin-right: -4rem !important; }
  .mb-qhd-n7,
  .my-qhd-n7 {
    margin-bottom: -4rem !important; }
  .ml-qhd-n7,
  .mx-qhd-n7 {
    margin-left: -4rem !important; }
  .m-qhd-n8 {
    margin: -5rem !important; }
  .mt-qhd-n8,
  .my-qhd-n8 {
    margin-top: -5rem !important; }
  .mr-qhd-n8,
  .mx-qhd-n8 {
    margin-right: -5rem !important; }
  .mb-qhd-n8,
  .my-qhd-n8 {
    margin-bottom: -5rem !important; }
  .ml-qhd-n8,
  .mx-qhd-n8 {
    margin-left: -5rem !important; }
  .m-qhd-n9 {
    margin: -6rem !important; }
  .mt-qhd-n9,
  .my-qhd-n9 {
    margin-top: -6rem !important; }
  .mr-qhd-n9,
  .mx-qhd-n9 {
    margin-right: -6rem !important; }
  .mb-qhd-n9,
  .my-qhd-n9 {
    margin-bottom: -6rem !important; }
  .ml-qhd-n9,
  .mx-qhd-n9 {
    margin-left: -6rem !important; }
  .m-qhd-n10 {
    margin: -7rem !important; }
  .mt-qhd-n10,
  .my-qhd-n10 {
    margin-top: -7rem !important; }
  .mr-qhd-n10,
  .mx-qhd-n10 {
    margin-right: -7rem !important; }
  .mb-qhd-n10,
  .my-qhd-n10 {
    margin-bottom: -7rem !important; }
  .ml-qhd-n10,
  .mx-qhd-n10 {
    margin-left: -7rem !important; }
  .m-qhd-n11 {
    margin: -8rem !important; }
  .mt-qhd-n11,
  .my-qhd-n11 {
    margin-top: -8rem !important; }
  .mr-qhd-n11,
  .mx-qhd-n11 {
    margin-right: -8rem !important; }
  .mb-qhd-n11,
  .my-qhd-n11 {
    margin-bottom: -8rem !important; }
  .ml-qhd-n11,
  .mx-qhd-n11 {
    margin-left: -8rem !important; }
  .m-qhd-n12 {
    margin: -9rem !important; }
  .mt-qhd-n12,
  .my-qhd-n12 {
    margin-top: -9rem !important; }
  .mr-qhd-n12,
  .mx-qhd-n12 {
    margin-right: -9rem !important; }
  .mb-qhd-n12,
  .my-qhd-n12 {
    margin-bottom: -9rem !important; }
  .ml-qhd-n12,
  .mx-qhd-n12 {
    margin-left: -9rem !important; }
  .m-qhd-n13 {
    margin: -10rem !important; }
  .mt-qhd-n13,
  .my-qhd-n13 {
    margin-top: -10rem !important; }
  .mr-qhd-n13,
  .mx-qhd-n13 {
    margin-right: -10rem !important; }
  .mb-qhd-n13,
  .my-qhd-n13 {
    margin-bottom: -10rem !important; }
  .ml-qhd-n13,
  .mx-qhd-n13 {
    margin-left: -10rem !important; }
  .m-qhd-n14 {
    margin: -11rem !important; }
  .mt-qhd-n14,
  .my-qhd-n14 {
    margin-top: -11rem !important; }
  .mr-qhd-n14,
  .mx-qhd-n14 {
    margin-right: -11rem !important; }
  .mb-qhd-n14,
  .my-qhd-n14 {
    margin-bottom: -11rem !important; }
  .ml-qhd-n14,
  .mx-qhd-n14 {
    margin-left: -11rem !important; }
  .m-qhd-n15 {
    margin: -12rem !important; }
  .mt-qhd-n15,
  .my-qhd-n15 {
    margin-top: -12rem !important; }
  .mr-qhd-n15,
  .mx-qhd-n15 {
    margin-right: -12rem !important; }
  .mb-qhd-n15,
  .my-qhd-n15 {
    margin-bottom: -12rem !important; }
  .ml-qhd-n15,
  .mx-qhd-n15 {
    margin-left: -12rem !important; }
  .m-qhd-auto {
    margin: auto !important; }
  .mt-qhd-auto,
  .my-qhd-auto {
    margin-top: auto !important; }
  .mr-qhd-auto,
  .mx-qhd-auto {
    margin-right: auto !important; }
  .mb-qhd-auto,
  .my-qhd-auto {
    margin-bottom: auto !important; }
  .ml-qhd-auto,
  .mx-qhd-auto {
    margin-left: auto !important; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #808080; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid rgba(255, 255, 255, 0.15); }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(255, 255, 255, 0.15); }
  .table tbody + tbody {
    border-top: 2px solid rgba(255, 255, 255, 0.15); }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid rgba(255, 255, 255, 0.15); }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid rgba(255, 255, 255, 0.15); }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #808080;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c0d3c3; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #89ad90; }

.table-hover .table-primary:hover {
  background-color: #b1c9b5; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b1c9b5; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dbdbdb; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #bdbdbd; }

.table-hover .table-secondary:hover {
  background-color: #cecece; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cecece; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c0d3c3; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #89ad90; }

.table-hover .table-success:hover {
  background-color: #b1c9b5; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1c9b5; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: white; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: white; }

.table-hover .table-light:hover {
  background-color: #f2f2f2; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f2f2f2; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c9c8c7; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9b9898; }

.table-hover .table-dark:hover {
  background-color: #bcbbba; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bcbbba; }

.table-ternary,
.table-ternary > th,
.table-ternary > td {
  background-color: #e3f6e4; }

.table-ternary th,
.table-ternary td,
.table-ternary thead th,
.table-ternary tbody + tbody {
  border-color: #cceecd; }

.table-hover .table-ternary:hover {
  background-color: #d0f0d1; }
  .table-hover .table-ternary:hover > td,
  .table-hover .table-ternary:hover > th {
    background-color: #d0f0d1; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(255, 255, 255, 0.15); }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1899.98px) {
  .table-responsive-fhd {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-fhd > .table-bordered {
      border: 0; } }

@media (max-width: 2539.98px) {
  .table-responsive-qhd {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-qhd > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 2rem + 4px);
  padding: 1rem 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #1d612a;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #1d612a;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #1d612a;
    background-color: #fff;
    border-color: #34a84a;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 71, 31, 0.25); }
  .form-control::placeholder {
    color: rgba(29, 97, 42, 0.75);
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #1d612a;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(1rem + 2px);
  padding-bottom: calc(1rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #808080;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-lg {
  height: calc(1.5em + 1rem + 4px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #1d612a; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(29, 97, 42, 0.9); }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #1d612a;
  padding-right: calc(1.5em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231d612a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.5rem);
  background-size: calc(0.75em + 1rem) calc(0.75em + 1rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #1d612a;
    box-shadow: 0 0 0 0.2rem rgba(29, 97, 42, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 2rem);
  background-position: top calc(0.375em + 0.5rem) right calc(0.375em + 0.5rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #1d612a;
  padding-right: calc((1em + 2rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%231d612a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231d612a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.75em + 1rem) calc(0.75em + 1rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #1d612a;
    box-shadow: 0 0 0 0.2rem rgba(29, 97, 42, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #1d612a; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #1d612a; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #1d612a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #29883b;
  background-color: #29883b; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(29, 97, 42, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #1d612a; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #1d612a; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #1d612a;
  box-shadow: 0 0 0 0.2rem rgba(29, 97, 42, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9); }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.5rem);
  background-size: calc(0.75em + 1rem) calc(0.75em + 1rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 2rem);
  background-position: top calc(0.375em + 0.5rem) right calc(0.375em + 0.5rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 2rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%231d612a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 2rem/calc(0.75em + 1rem) calc(0.75em + 1rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 700;
  color: #808080;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 4px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #808080;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 71, 31, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #1d612a;
  border-color: #1d612a; }
  .btn-primary:hover {
    color: #fff;
    background-color: #14441d;
    border-color: #113a19; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 121, 74, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #1d612a;
    border-color: #1d612a; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #113a19;
    border-color: #0e3015; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(63, 121, 74, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #808080;
  border-color: #808080; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #6d6d6d;
    border-color: #676767; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(147, 147, 147, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #808080;
    border-color: #808080; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #676767;
    border-color: #606060; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(147, 147, 147, 0.5); }

.btn-success {
  color: #fff;
  background-color: #1d612a;
  border-color: #1d612a; }
  .btn-success:hover {
    color: #fff;
    background-color: #14441d;
    border-color: #113a19; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 121, 74, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #1d612a;
    border-color: #1d612a; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #113a19;
    border-color: #0e3015; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(63, 121, 74, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-light:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #3e3938;
  border-color: #3e3938; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2a2726;
    border-color: #232020; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(91, 87, 86, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #3e3938;
    border-color: #3e3938; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #232020;
    border-color: #1d1a1a; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(91, 87, 86, 0.5); }

.btn-ternary {
  color: #212529;
  background-color: #9CDE9F;
  border-color: #9CDE9F; }
  .btn-ternary:hover {
    color: #212529;
    background-color: #7fd483;
    border-color: #76d17a; }
  .btn-ternary:focus, .btn-ternary.focus {
    box-shadow: 0 0 0 0.2rem rgba(138, 194, 141, 0.5); }
  .btn-ternary.disabled, .btn-ternary:disabled {
    color: #212529;
    background-color: #9CDE9F;
    border-color: #9CDE9F; }
  .btn-ternary:not(:disabled):not(.disabled):active, .btn-ternary:not(:disabled):not(.disabled).active,
  .show > .btn-ternary.dropdown-toggle {
    color: #212529;
    background-color: #76d17a;
    border-color: #6cce71; }
    .btn-ternary:not(:disabled):not(.disabled):active:focus, .btn-ternary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ternary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(138, 194, 141, 0.5); }

.btn-outline-primary {
  color: #1d612a;
  border-color: #1d612a; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #1d612a;
    border-color: #1d612a; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(29, 97, 42, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #1d612a;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #1d612a;
    border-color: #1d612a; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(29, 97, 42, 0.5); }

.btn-outline-secondary {
  color: #808080;
  border-color: #808080; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #808080;
    border-color: #808080; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #808080;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #808080;
    border-color: #808080; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0.5); }

.btn-outline-success {
  color: #1d612a;
  border-color: #1d612a; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #1d612a;
    border-color: #1d612a; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(29, 97, 42, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #1d612a;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #1d612a;
    border-color: #1d612a; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(29, 97, 42, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #fff;
  border-color: #fff; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-dark {
  color: #3e3938;
  border-color: #3e3938; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #3e3938;
    border-color: #3e3938; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(62, 57, 56, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3e3938;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3e3938;
    border-color: #3e3938; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 57, 56, 0.5); }

.btn-outline-ternary {
  color: #9CDE9F;
  border-color: #9CDE9F; }
  .btn-outline-ternary:hover {
    color: #212529;
    background-color: #9CDE9F;
    border-color: #9CDE9F; }
  .btn-outline-ternary:focus, .btn-outline-ternary.focus {
    box-shadow: 0 0 0 0.2rem rgba(156, 222, 159, 0.5); }
  .btn-outline-ternary.disabled, .btn-outline-ternary:disabled {
    color: #9CDE9F;
    background-color: transparent; }
  .btn-outline-ternary:not(:disabled):not(.disabled):active, .btn-outline-ternary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ternary.dropdown-toggle {
    color: #212529;
    background-color: #9CDE9F;
    border-color: #9CDE9F; }
    .btn-outline-ternary:not(:disabled):not(.disabled):active:focus, .btn-outline-ternary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ternary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(156, 222, 159, 0.5); }

.btn-link {
  font-weight: 400;
  color: #1d612a;
  text-decoration: none; }
  .btn-link:hover {
    color: #1d612a;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 1rem 2rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #808080;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1900px) {
  .dropdown-menu-fhd-left {
    right: auto;
    left: 0; }
  .dropdown-menu-fhd-right {
    right: 0;
    left: auto; } }

@media (min-width: 2540px) {
  .dropdown-menu-qhd-left {
    right: auto;
    left: 0; }
  .dropdown-menu-qhd-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1d612a;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1d612a;
    text-decoration: none;
    background-color: #e6e6e6; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #16471f; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1.5rem;
  color: #1d612a; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #16471f;
    background-color: #16471f; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(22, 71, 31, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #34a84a; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #49c760;
    border-color: #49c760; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #1d612a solid 2px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #16471f;
  background-color: #16471f; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(29, 97, 42, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(29, 97, 42, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(29, 97, 42, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 4px);
    left: calc(-2.25rem + 4px);
    width: calc(1rem - 8px);
    height: calc(1rem - 8px);
    background-color: #1d612a;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(29, 97, 42, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 2rem + 4px);
  padding: 1rem 2rem 1rem 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5;
  color: #1d612a;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%231d612a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  background-color: #fff;
  border: 2px solid #1d612a;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #34a84a;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(22, 71, 31, 0.25); }
    .custom-select:focus::-ms-value {
      color: #1d612a;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 4px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 4px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 2rem + 4px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 2rem + 4px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #34a84a;
    box-shadow: 0 0 0 0.2rem rgba(22, 71, 31, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 2rem + 4px);
  padding: 1rem 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #1d612a;
  background-color: #fff;
  border: 2px solid #1d612a; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 2rem);
    padding: 1rem 1rem;
    line-height: 1.5;
    color: #1d612a;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(22, 71, 31, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(22, 71, 31, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(22, 71, 31, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #16471f;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #49c760; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #16471f;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #49c760; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #16471f;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #49c760; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #16471f; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f3f7f4;
  background-clip: border-box;
  border: 0 solid #1d612a; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem;
  color: #808080; }

.card-title {
  margin-bottom: 1rem; }

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 1rem 1rem;
  margin-bottom: 0;
  background-color: #f3f7f4;
  border-bottom: 0 solid #1d612a; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 1rem 1rem;
  background-color: #f3f7f4;
  border-top: 0 solid #1d612a; }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -1rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%; }

.card-img-top {
  width: 100%; }

.card-img-bottom {
  width: 100%; }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; } }

.card-columns .card {
  margin-bottom: 1rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0; }
  .accordion > .card .card-header {
    margin-bottom: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #0f3216;
  background-color: #d2dfd4;
  border-color: #c0d3c3; }
  .alert-primary hr {
    border-top-color: #b1c9b5; }
  .alert-primary .alert-link {
    color: #030b05; }

.alert-secondary {
  color: #434343;
  background-color: #e6e6e6;
  border-color: #dbdbdb; }
  .alert-secondary hr {
    border-top-color: #cecece; }
  .alert-secondary .alert-link {
    color: #2a2a2a; }

.alert-success, .alert--success {
  color: #0f3216;
  background-color: #d2dfd4;
  border-color: #c0d3c3; }
  .alert-success hr, .alert--success hr {
    border-top-color: #b1c9b5; }
  .alert-success .alert-link, .alert--success .alert-link {
    color: #030b05; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger, .alert--danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr, .alert--danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link, .alert--danger .alert-link {
    color: #491217; }

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-light hr {
    border-top-color: #f2f2f2; }
  .alert-light .alert-link {
    color: #6c6c6c; }

.alert-dark {
  color: #201e1d;
  background-color: #d8d7d7;
  border-color: #c9c8c7; }
  .alert-dark hr {
    border-top-color: #bcbbba; }
  .alert-dark .alert-link {
    color: #050505; }

.alert-ternary {
  color: #517353;
  background-color: #ebf8ec;
  border-color: #e3f6e4; }
  .alert-ternary hr {
    border-top-color: #d0f0d1; }
  .alert-ternary .alert-link {
    color: #3c553d; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #808080;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:last-child {
    margin-bottom: 0; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #16471f;
    border-color: #16471f; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 1900px) {
  .list-group-horizontal-fhd {
    flex-direction: row; }
    .list-group-horizontal-fhd .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-fhd .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 2540px) {
  .list-group-horizontal-qhd {
    flex-direction: row; }
    .list-group-horizontal-qhd .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-qhd .list-group-item:last-child {
        margin-right: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #0f3216;
  background-color: #c0d3c3; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #0f3216;
    background-color: #b1c9b5; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #0f3216;
    border-color: #0f3216; }

.list-group-item-secondary {
  color: #434343;
  background-color: #dbdbdb; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #434343;
    background-color: #cecece; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #434343;
    border-color: #434343; }

.list-group-item-success {
  color: #0f3216;
  background-color: #c0d3c3; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0f3216;
    background-color: #b1c9b5; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f3216;
    border-color: #0f3216; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #858585;
  background-color: white; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-dark {
  color: #201e1d;
  background-color: #c9c8c7; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #201e1d;
    background-color: #bcbbba; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #201e1d;
    border-color: #201e1d; }

.list-group-item-ternary {
  color: #517353;
  background-color: #e3f6e4; }
  .list-group-item-ternary.list-group-item-action:hover, .list-group-item-ternary.list-group-item-action:focus {
    color: #517353;
    background-color: #d0f0d1; }
  .list-group-item-ternary.list-group-item-action.active {
    color: #fff;
    background-color: #517353;
    border-color: #517353; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #1d612a !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #113a19 !important; }

.bg-secondary {
  background-color: #808080 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #676767 !important; }

.bg-success {
  background-color: #1d612a !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #113a19 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #fff !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important; }

.bg-dark {
  background-color: #3e3938 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #232020 !important; }

.bg-ternary {
  background-color: #9CDE9F !important; }

a.bg-ternary:hover, a.bg-ternary:focus,
button.bg-ternary:hover,
button.bg-ternary:focus {
  background-color: #76d17a !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid rgba(255, 255, 255, 0.15) !important; }

.border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.15) !important; }

.border-right {
  border-right: 1px solid rgba(255, 255, 255, 0.15) !important; }

.border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important; }

.border-left {
  border-left: 1px solid rgba(255, 255, 255, 0.15) !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #1d612a !important; }

.border-secondary {
  border-color: #808080 !important; }

.border-success {
  border-color: #1d612a !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #fff !important; }

.border-dark {
  border-color: #3e3938 !important; }

.border-ternary {
  border-color: #9CDE9F !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1900px) {
  .d-fhd-none {
    display: none !important; }
  .d-fhd-inline {
    display: inline !important; }
  .d-fhd-inline-block {
    display: inline-block !important; }
  .d-fhd-block {
    display: block !important; }
  .d-fhd-table {
    display: table !important; }
  .d-fhd-table-row {
    display: table-row !important; }
  .d-fhd-table-cell {
    display: table-cell !important; }
  .d-fhd-flex {
    display: flex !important; }
  .d-fhd-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 2540px) {
  .d-qhd-none {
    display: none !important; }
  .d-qhd-inline {
    display: inline !important; }
  .d-qhd-inline-block {
    display: inline-block !important; }
  .d-qhd-block {
    display: block !important; }
  .d-qhd-table {
    display: table !important; }
  .d-qhd-table-row {
    display: table-row !important; }
  .d-qhd-table-cell {
    display: table-cell !important; }
  .d-qhd-flex {
    display: flex !important; }
  .d-qhd-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1900px) {
  .flex-fhd-row {
    flex-direction: row !important; }
  .flex-fhd-column {
    flex-direction: column !important; }
  .flex-fhd-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-fhd-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-fhd-wrap {
    flex-wrap: wrap !important; }
  .flex-fhd-nowrap {
    flex-wrap: nowrap !important; }
  .flex-fhd-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-fhd-fill {
    flex: 1 1 auto !important; }
  .flex-fhd-grow-0 {
    flex-grow: 0 !important; }
  .flex-fhd-grow-1 {
    flex-grow: 1 !important; }
  .flex-fhd-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-fhd-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-fhd-start {
    justify-content: flex-start !important; }
  .justify-content-fhd-end {
    justify-content: flex-end !important; }
  .justify-content-fhd-center {
    justify-content: center !important; }
  .justify-content-fhd-between {
    justify-content: space-between !important; }
  .justify-content-fhd-around {
    justify-content: space-around !important; }
  .align-items-fhd-start {
    align-items: flex-start !important; }
  .align-items-fhd-end {
    align-items: flex-end !important; }
  .align-items-fhd-center {
    align-items: center !important; }
  .align-items-fhd-baseline {
    align-items: baseline !important; }
  .align-items-fhd-stretch {
    align-items: stretch !important; }
  .align-content-fhd-start {
    align-content: flex-start !important; }
  .align-content-fhd-end {
    align-content: flex-end !important; }
  .align-content-fhd-center {
    align-content: center !important; }
  .align-content-fhd-between {
    align-content: space-between !important; }
  .align-content-fhd-around {
    align-content: space-around !important; }
  .align-content-fhd-stretch {
    align-content: stretch !important; }
  .align-self-fhd-auto {
    align-self: auto !important; }
  .align-self-fhd-start {
    align-self: flex-start !important; }
  .align-self-fhd-end {
    align-self: flex-end !important; }
  .align-self-fhd-center {
    align-self: center !important; }
  .align-self-fhd-baseline {
    align-self: baseline !important; }
  .align-self-fhd-stretch {
    align-self: stretch !important; } }

@media (min-width: 2540px) {
  .flex-qhd-row {
    flex-direction: row !important; }
  .flex-qhd-column {
    flex-direction: column !important; }
  .flex-qhd-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-qhd-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-qhd-wrap {
    flex-wrap: wrap !important; }
  .flex-qhd-nowrap {
    flex-wrap: nowrap !important; }
  .flex-qhd-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-qhd-fill {
    flex: 1 1 auto !important; }
  .flex-qhd-grow-0 {
    flex-grow: 0 !important; }
  .flex-qhd-grow-1 {
    flex-grow: 1 !important; }
  .flex-qhd-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-qhd-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-qhd-start {
    justify-content: flex-start !important; }
  .justify-content-qhd-end {
    justify-content: flex-end !important; }
  .justify-content-qhd-center {
    justify-content: center !important; }
  .justify-content-qhd-between {
    justify-content: space-between !important; }
  .justify-content-qhd-around {
    justify-content: space-around !important; }
  .align-items-qhd-start {
    align-items: flex-start !important; }
  .align-items-qhd-end {
    align-items: flex-end !important; }
  .align-items-qhd-center {
    align-items: center !important; }
  .align-items-qhd-baseline {
    align-items: baseline !important; }
  .align-items-qhd-stretch {
    align-items: stretch !important; }
  .align-content-qhd-start {
    align-content: flex-start !important; }
  .align-content-qhd-end {
    align-content: flex-end !important; }
  .align-content-qhd-center {
    align-content: center !important; }
  .align-content-qhd-between {
    align-content: space-between !important; }
  .align-content-qhd-around {
    align-content: space-around !important; }
  .align-content-qhd-stretch {
    align-content: stretch !important; }
  .align-self-qhd-auto {
    align-self: auto !important; }
  .align-self-qhd-start {
    align-self: flex-start !important; }
  .align-self-qhd-end {
    align-self: flex-end !important; }
  .align-self-qhd-center {
    align-self: center !important; }
  .align-self-qhd-baseline {
    align-self: baseline !important; }
  .align-self-qhd-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1900px) {
  .float-fhd-left {
    float: left !important; }
  .float-fhd-right {
    float: right !important; }
  .float-fhd-none {
    float: none !important; } }

@media (min-width: 2540px) {
  .float-qhd-left {
    float: left !important; }
  .float-qhd-right {
    float: right !important; }
  .float-qhd-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 2rem !important; }

.mt-5,
.my-5 {
  margin-top: 2rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 2rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2rem !important; }

.m-6 {
  margin: 3rem !important; }

.mt-6,
.my-6 {
  margin-top: 3rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3rem !important; }

.m-7 {
  margin: 4rem !important; }

.mt-7,
.my-7 {
  margin-top: 4rem !important; }

.mr-7,
.mx-7 {
  margin-right: 4rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 4rem !important; }

.ml-7,
.mx-7 {
  margin-left: 4rem !important; }

.m-8 {
  margin: 5rem !important; }

.mt-8,
.my-8 {
  margin-top: 5rem !important; }

.mr-8,
.mx-8 {
  margin-right: 5rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 5rem !important; }

.ml-8,
.mx-8 {
  margin-left: 5rem !important; }

.m-9 {
  margin: 6rem !important; }

.mt-9,
.my-9 {
  margin-top: 6rem !important; }

.mr-9,
.mx-9 {
  margin-right: 6rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 6rem !important; }

.ml-9,
.mx-9 {
  margin-left: 6rem !important; }

.m-10 {
  margin: 7rem !important; }

.mt-10,
.my-10 {
  margin-top: 7rem !important; }

.mr-10,
.mx-10 {
  margin-right: 7rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 7rem !important; }

.ml-10,
.mx-10 {
  margin-left: 7rem !important; }

.m-11 {
  margin: 8rem !important; }

.mt-11,
.my-11 {
  margin-top: 8rem !important; }

.mr-11,
.mx-11 {
  margin-right: 8rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 8rem !important; }

.ml-11,
.mx-11 {
  margin-left: 8rem !important; }

.m-12 {
  margin: 9rem !important; }

.mt-12,
.my-12 {
  margin-top: 9rem !important; }

.mr-12,
.mx-12 {
  margin-right: 9rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 9rem !important; }

.ml-12,
.mx-12 {
  margin-left: 9rem !important; }

.m-13 {
  margin: 10rem !important; }

.mt-13,
.my-13 {
  margin-top: 10rem !important; }

.mr-13,
.mx-13 {
  margin-right: 10rem !important; }

.mb-13,
.my-13 {
  margin-bottom: 10rem !important; }

.ml-13,
.mx-13 {
  margin-left: 10rem !important; }

.m-14 {
  margin: 11rem !important; }

.mt-14,
.my-14 {
  margin-top: 11rem !important; }

.mr-14,
.mx-14 {
  margin-right: 11rem !important; }

.mb-14,
.my-14 {
  margin-bottom: 11rem !important; }

.ml-14,
.mx-14 {
  margin-left: 11rem !important; }

.m-15 {
  margin: 12rem !important; }

.mt-15,
.my-15 {
  margin-top: 12rem !important; }

.mr-15,
.mx-15 {
  margin-right: 12rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 12rem !important; }

.ml-15,
.mx-15 {
  margin-left: 12rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 2rem !important; }

.pt-5,
.py-5 {
  padding-top: 2rem !important; }

.pr-5,
.px-5 {
  padding-right: 2rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 2rem !important; }

.pl-5,
.px-5 {
  padding-left: 2rem !important; }

.p-6 {
  padding: 3rem !important; }

.pt-6,
.py-6 {
  padding-top: 3rem !important; }

.pr-6,
.px-6 {
  padding-right: 3rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3rem !important; }

.pl-6,
.px-6 {
  padding-left: 3rem !important; }

.p-7 {
  padding: 4rem !important; }

.pt-7,
.py-7 {
  padding-top: 4rem !important; }

.pr-7,
.px-7 {
  padding-right: 4rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 4rem !important; }

.pl-7,
.px-7 {
  padding-left: 4rem !important; }

.p-8 {
  padding: 5rem !important; }

.pt-8,
.py-8 {
  padding-top: 5rem !important; }

.pr-8,
.px-8 {
  padding-right: 5rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 5rem !important; }

.pl-8,
.px-8 {
  padding-left: 5rem !important; }

.p-9 {
  padding: 6rem !important; }

.pt-9,
.py-9 {
  padding-top: 6rem !important; }

.pr-9,
.px-9 {
  padding-right: 6rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 6rem !important; }

.pl-9,
.px-9 {
  padding-left: 6rem !important; }

.p-10 {
  padding: 7rem !important; }

.pt-10,
.py-10 {
  padding-top: 7rem !important; }

.pr-10,
.px-10 {
  padding-right: 7rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 7rem !important; }

.pl-10,
.px-10 {
  padding-left: 7rem !important; }

.p-11 {
  padding: 8rem !important; }

.pt-11,
.py-11 {
  padding-top: 8rem !important; }

.pr-11,
.px-11 {
  padding-right: 8rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 8rem !important; }

.pl-11,
.px-11 {
  padding-left: 8rem !important; }

.p-12 {
  padding: 9rem !important; }

.pt-12,
.py-12 {
  padding-top: 9rem !important; }

.pr-12,
.px-12 {
  padding-right: 9rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 9rem !important; }

.pl-12,
.px-12 {
  padding-left: 9rem !important; }

.p-13 {
  padding: 10rem !important; }

.pt-13,
.py-13 {
  padding-top: 10rem !important; }

.pr-13,
.px-13 {
  padding-right: 10rem !important; }

.pb-13,
.py-13 {
  padding-bottom: 10rem !important; }

.pl-13,
.px-13 {
  padding-left: 10rem !important; }

.p-14 {
  padding: 11rem !important; }

.pt-14,
.py-14 {
  padding-top: 11rem !important; }

.pr-14,
.px-14 {
  padding-right: 11rem !important; }

.pb-14,
.py-14 {
  padding-bottom: 11rem !important; }

.pl-14,
.px-14 {
  padding-left: 11rem !important; }

.p-15 {
  padding: 12rem !important; }

.pt-15,
.py-15 {
  padding-top: 12rem !important; }

.pr-15,
.px-15 {
  padding-right: 12rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 12rem !important; }

.pl-15,
.px-15 {
  padding-left: 12rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -2rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -2rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important; }

.m-n6 {
  margin: -3rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -3rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -3rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -3rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -3rem !important; }

.m-n7 {
  margin: -4rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -4rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -4rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -4rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -4rem !important; }

.m-n8 {
  margin: -5rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -5rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -5rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -5rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -5rem !important; }

.m-n9 {
  margin: -6rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -6rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -6rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -6rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -6rem !important; }

.m-n10 {
  margin: -7rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -7rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -7rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -7rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -7rem !important; }

.m-n11 {
  margin: -8rem !important; }

.mt-n11,
.my-n11 {
  margin-top: -8rem !important; }

.mr-n11,
.mx-n11 {
  margin-right: -8rem !important; }

.mb-n11,
.my-n11 {
  margin-bottom: -8rem !important; }

.ml-n11,
.mx-n11 {
  margin-left: -8rem !important; }

.m-n12 {
  margin: -9rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -9rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -9rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -9rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -9rem !important; }

.m-n13 {
  margin: -10rem !important; }

.mt-n13,
.my-n13 {
  margin-top: -10rem !important; }

.mr-n13,
.mx-n13 {
  margin-right: -10rem !important; }

.mb-n13,
.my-n13 {
  margin-bottom: -10rem !important; }

.ml-n13,
.mx-n13 {
  margin-left: -10rem !important; }

.m-n14 {
  margin: -11rem !important; }

.mt-n14,
.my-n14 {
  margin-top: -11rem !important; }

.mr-n14,
.mx-n14 {
  margin-right: -11rem !important; }

.mb-n14,
.my-n14 {
  margin-bottom: -11rem !important; }

.ml-n14,
.mx-n14 {
  margin-left: -11rem !important; }

.m-n15 {
  margin: -12rem !important; }

.mt-n15,
.my-n15 {
  margin-top: -12rem !important; }

.mr-n15,
.mx-n15 {
  margin-right: -12rem !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -12rem !important; }

.ml-n15,
.mx-n15 {
  margin-left: -12rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 2rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important; }
  .m-sm-6 {
    margin: 3rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3rem !important; }
  .m-sm-7 {
    margin: 4rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 4rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 4rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 4rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 4rem !important; }
  .m-sm-8 {
    margin: 5rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 5rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 5rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 5rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 5rem !important; }
  .m-sm-9 {
    margin: 6rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 6rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 6rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 6rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 6rem !important; }
  .m-sm-10 {
    margin: 7rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 7rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 7rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 7rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 7rem !important; }
  .m-sm-11 {
    margin: 8rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 8rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 8rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 8rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 8rem !important; }
  .m-sm-12 {
    margin: 9rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 9rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 9rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 9rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 9rem !important; }
  .m-sm-13 {
    margin: 10rem !important; }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 10rem !important; }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 10rem !important; }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 10rem !important; }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 10rem !important; }
  .m-sm-14 {
    margin: 11rem !important; }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 11rem !important; }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 11rem !important; }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 11rem !important; }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 11rem !important; }
  .m-sm-15 {
    margin: 12rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 12rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 12rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 12rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 12rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 2rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important; }
  .p-sm-6 {
    padding: 3rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3rem !important; }
  .p-sm-7 {
    padding: 4rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 4rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 4rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 4rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 4rem !important; }
  .p-sm-8 {
    padding: 5rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 5rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 5rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 5rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 5rem !important; }
  .p-sm-9 {
    padding: 6rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 6rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 6rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 6rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 6rem !important; }
  .p-sm-10 {
    padding: 7rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 7rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 7rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 7rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 7rem !important; }
  .p-sm-11 {
    padding: 8rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 8rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 8rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 8rem !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 8rem !important; }
  .p-sm-12 {
    padding: 9rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 9rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 9rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 9rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 9rem !important; }
  .p-sm-13 {
    padding: 10rem !important; }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 10rem !important; }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 10rem !important; }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 10rem !important; }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 10rem !important; }
  .p-sm-14 {
    padding: 11rem !important; }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 11rem !important; }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 11rem !important; }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 11rem !important; }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 11rem !important; }
  .p-sm-15 {
    padding: 12rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 12rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 12rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 12rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 12rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -2rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2rem !important; }
  .m-sm-n6 {
    margin: -3rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -3rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -3rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -3rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -3rem !important; }
  .m-sm-n7 {
    margin: -4rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -4rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -4rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -4rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -4rem !important; }
  .m-sm-n8 {
    margin: -5rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -5rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -5rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -5rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -5rem !important; }
  .m-sm-n9 {
    margin: -6rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -6rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -6rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -6rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -6rem !important; }
  .m-sm-n10 {
    margin: -7rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -7rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -7rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -7rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -7rem !important; }
  .m-sm-n11 {
    margin: -8rem !important; }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -8rem !important; }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -8rem !important; }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -8rem !important; }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -8rem !important; }
  .m-sm-n12 {
    margin: -9rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -9rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -9rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -9rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -9rem !important; }
  .m-sm-n13 {
    margin: -10rem !important; }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -10rem !important; }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -10rem !important; }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -10rem !important; }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -10rem !important; }
  .m-sm-n14 {
    margin: -11rem !important; }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -11rem !important; }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -11rem !important; }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -11rem !important; }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -11rem !important; }
  .m-sm-n15 {
    margin: -12rem !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -12rem !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -12rem !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -12rem !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -12rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 2rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important; }
  .m-md-6 {
    margin: 3rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3rem !important; }
  .m-md-7 {
    margin: 4rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 4rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 4rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 4rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 4rem !important; }
  .m-md-8 {
    margin: 5rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 5rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 5rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 5rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 5rem !important; }
  .m-md-9 {
    margin: 6rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 6rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 6rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 6rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 6rem !important; }
  .m-md-10 {
    margin: 7rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 7rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 7rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 7rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 7rem !important; }
  .m-md-11 {
    margin: 8rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 8rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 8rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 8rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 8rem !important; }
  .m-md-12 {
    margin: 9rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 9rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 9rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 9rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 9rem !important; }
  .m-md-13 {
    margin: 10rem !important; }
  .mt-md-13,
  .my-md-13 {
    margin-top: 10rem !important; }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 10rem !important; }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 10rem !important; }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 10rem !important; }
  .m-md-14 {
    margin: 11rem !important; }
  .mt-md-14,
  .my-md-14 {
    margin-top: 11rem !important; }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 11rem !important; }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 11rem !important; }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 11rem !important; }
  .m-md-15 {
    margin: 12rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 12rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 12rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 12rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 12rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 2rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important; }
  .p-md-6 {
    padding: 3rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3rem !important; }
  .p-md-7 {
    padding: 4rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 4rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 4rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 4rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 4rem !important; }
  .p-md-8 {
    padding: 5rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 5rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 5rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 5rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 5rem !important; }
  .p-md-9 {
    padding: 6rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 6rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 6rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 6rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 6rem !important; }
  .p-md-10 {
    padding: 7rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 7rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 7rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 7rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 7rem !important; }
  .p-md-11 {
    padding: 8rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 8rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 8rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 8rem !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 8rem !important; }
  .p-md-12 {
    padding: 9rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 9rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 9rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 9rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 9rem !important; }
  .p-md-13 {
    padding: 10rem !important; }
  .pt-md-13,
  .py-md-13 {
    padding-top: 10rem !important; }
  .pr-md-13,
  .px-md-13 {
    padding-right: 10rem !important; }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 10rem !important; }
  .pl-md-13,
  .px-md-13 {
    padding-left: 10rem !important; }
  .p-md-14 {
    padding: 11rem !important; }
  .pt-md-14,
  .py-md-14 {
    padding-top: 11rem !important; }
  .pr-md-14,
  .px-md-14 {
    padding-right: 11rem !important; }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 11rem !important; }
  .pl-md-14,
  .px-md-14 {
    padding-left: 11rem !important; }
  .p-md-15 {
    padding: 12rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 12rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 12rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 12rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 12rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -2rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2rem !important; }
  .m-md-n6 {
    margin: -3rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -3rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -3rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -3rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -3rem !important; }
  .m-md-n7 {
    margin: -4rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -4rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -4rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -4rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -4rem !important; }
  .m-md-n8 {
    margin: -5rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -5rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -5rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -5rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -5rem !important; }
  .m-md-n9 {
    margin: -6rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -6rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -6rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -6rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -6rem !important; }
  .m-md-n10 {
    margin: -7rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -7rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -7rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -7rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -7rem !important; }
  .m-md-n11 {
    margin: -8rem !important; }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -8rem !important; }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -8rem !important; }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -8rem !important; }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -8rem !important; }
  .m-md-n12 {
    margin: -9rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -9rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -9rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -9rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -9rem !important; }
  .m-md-n13 {
    margin: -10rem !important; }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -10rem !important; }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -10rem !important; }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -10rem !important; }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -10rem !important; }
  .m-md-n14 {
    margin: -11rem !important; }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -11rem !important; }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -11rem !important; }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -11rem !important; }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -11rem !important; }
  .m-md-n15 {
    margin: -12rem !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -12rem !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -12rem !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -12rem !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -12rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 2rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important; }
  .m-lg-6 {
    margin: 3rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3rem !important; }
  .m-lg-7 {
    margin: 4rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 4rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 4rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 4rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 4rem !important; }
  .m-lg-8 {
    margin: 5rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 5rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 5rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 5rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 5rem !important; }
  .m-lg-9 {
    margin: 6rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 6rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 6rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 6rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 6rem !important; }
  .m-lg-10 {
    margin: 7rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 7rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 7rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 7rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 7rem !important; }
  .m-lg-11 {
    margin: 8rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 8rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 8rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 8rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 8rem !important; }
  .m-lg-12 {
    margin: 9rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 9rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 9rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 9rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 9rem !important; }
  .m-lg-13 {
    margin: 10rem !important; }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 10rem !important; }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 10rem !important; }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 10rem !important; }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 10rem !important; }
  .m-lg-14 {
    margin: 11rem !important; }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 11rem !important; }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 11rem !important; }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 11rem !important; }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 11rem !important; }
  .m-lg-15 {
    margin: 12rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 12rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 12rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 12rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 12rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 2rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important; }
  .p-lg-6 {
    padding: 3rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3rem !important; }
  .p-lg-7 {
    padding: 4rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 4rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 4rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 4rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 4rem !important; }
  .p-lg-8 {
    padding: 5rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 5rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 5rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 5rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 5rem !important; }
  .p-lg-9 {
    padding: 6rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 6rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 6rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 6rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 6rem !important; }
  .p-lg-10 {
    padding: 7rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 7rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 7rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 7rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 7rem !important; }
  .p-lg-11 {
    padding: 8rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 8rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 8rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 8rem !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 8rem !important; }
  .p-lg-12 {
    padding: 9rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 9rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 9rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 9rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 9rem !important; }
  .p-lg-13 {
    padding: 10rem !important; }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 10rem !important; }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 10rem !important; }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 10rem !important; }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 10rem !important; }
  .p-lg-14 {
    padding: 11rem !important; }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 11rem !important; }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 11rem !important; }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 11rem !important; }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 11rem !important; }
  .p-lg-15 {
    padding: 12rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 12rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 12rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 12rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 12rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -2rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2rem !important; }
  .m-lg-n6 {
    margin: -3rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -3rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -3rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -3rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -3rem !important; }
  .m-lg-n7 {
    margin: -4rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -4rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -4rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -4rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -4rem !important; }
  .m-lg-n8 {
    margin: -5rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -5rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -5rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -5rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -5rem !important; }
  .m-lg-n9 {
    margin: -6rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -6rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -6rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -6rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -6rem !important; }
  .m-lg-n10 {
    margin: -7rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -7rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -7rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -7rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -7rem !important; }
  .m-lg-n11 {
    margin: -8rem !important; }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -8rem !important; }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -8rem !important; }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -8rem !important; }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -8rem !important; }
  .m-lg-n12 {
    margin: -9rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -9rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -9rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -9rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -9rem !important; }
  .m-lg-n13 {
    margin: -10rem !important; }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -10rem !important; }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -10rem !important; }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -10rem !important; }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -10rem !important; }
  .m-lg-n14 {
    margin: -11rem !important; }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -11rem !important; }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -11rem !important; }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -11rem !important; }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -11rem !important; }
  .m-lg-n15 {
    margin: -12rem !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -12rem !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -12rem !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -12rem !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -12rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 2rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important; }
  .m-xl-6 {
    margin: 3rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3rem !important; }
  .m-xl-7 {
    margin: 4rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 4rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 4rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 4rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 4rem !important; }
  .m-xl-8 {
    margin: 5rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 5rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 5rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 5rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 5rem !important; }
  .m-xl-9 {
    margin: 6rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 6rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 6rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 6rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 6rem !important; }
  .m-xl-10 {
    margin: 7rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 7rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 7rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 7rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 7rem !important; }
  .m-xl-11 {
    margin: 8rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 8rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 8rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 8rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 8rem !important; }
  .m-xl-12 {
    margin: 9rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 9rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 9rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 9rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 9rem !important; }
  .m-xl-13 {
    margin: 10rem !important; }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 10rem !important; }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 10rem !important; }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 10rem !important; }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 10rem !important; }
  .m-xl-14 {
    margin: 11rem !important; }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 11rem !important; }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 11rem !important; }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 11rem !important; }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 11rem !important; }
  .m-xl-15 {
    margin: 12rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 12rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 12rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 12rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 12rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 2rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important; }
  .p-xl-6 {
    padding: 3rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3rem !important; }
  .p-xl-7 {
    padding: 4rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 4rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 4rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 4rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 4rem !important; }
  .p-xl-8 {
    padding: 5rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 5rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 5rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 5rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 5rem !important; }
  .p-xl-9 {
    padding: 6rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 6rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 6rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 6rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 6rem !important; }
  .p-xl-10 {
    padding: 7rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 7rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 7rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 7rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 7rem !important; }
  .p-xl-11 {
    padding: 8rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 8rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 8rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 8rem !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 8rem !important; }
  .p-xl-12 {
    padding: 9rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 9rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 9rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 9rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 9rem !important; }
  .p-xl-13 {
    padding: 10rem !important; }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 10rem !important; }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 10rem !important; }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 10rem !important; }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 10rem !important; }
  .p-xl-14 {
    padding: 11rem !important; }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 11rem !important; }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 11rem !important; }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 11rem !important; }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 11rem !important; }
  .p-xl-15 {
    padding: 12rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 12rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 12rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 12rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 12rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -2rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2rem !important; }
  .m-xl-n6 {
    margin: -3rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -3rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -3rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -3rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -3rem !important; }
  .m-xl-n7 {
    margin: -4rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -4rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -4rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -4rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -4rem !important; }
  .m-xl-n8 {
    margin: -5rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -5rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -5rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -5rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -5rem !important; }
  .m-xl-n9 {
    margin: -6rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -6rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -6rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -6rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -6rem !important; }
  .m-xl-n10 {
    margin: -7rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -7rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -7rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -7rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -7rem !important; }
  .m-xl-n11 {
    margin: -8rem !important; }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -8rem !important; }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -8rem !important; }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -8rem !important; }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -8rem !important; }
  .m-xl-n12 {
    margin: -9rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -9rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -9rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -9rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -9rem !important; }
  .m-xl-n13 {
    margin: -10rem !important; }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -10rem !important; }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -10rem !important; }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -10rem !important; }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -10rem !important; }
  .m-xl-n14 {
    margin: -11rem !important; }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -11rem !important; }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -11rem !important; }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -11rem !important; }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -11rem !important; }
  .m-xl-n15 {
    margin: -12rem !important; }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -12rem !important; }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -12rem !important; }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -12rem !important; }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -12rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1900px) {
  .m-fhd-0 {
    margin: 0 !important; }
  .mt-fhd-0,
  .my-fhd-0 {
    margin-top: 0 !important; }
  .mr-fhd-0,
  .mx-fhd-0 {
    margin-right: 0 !important; }
  .mb-fhd-0,
  .my-fhd-0 {
    margin-bottom: 0 !important; }
  .ml-fhd-0,
  .mx-fhd-0 {
    margin-left: 0 !important; }
  .m-fhd-1 {
    margin: 0.25rem !important; }
  .mt-fhd-1,
  .my-fhd-1 {
    margin-top: 0.25rem !important; }
  .mr-fhd-1,
  .mx-fhd-1 {
    margin-right: 0.25rem !important; }
  .mb-fhd-1,
  .my-fhd-1 {
    margin-bottom: 0.25rem !important; }
  .ml-fhd-1,
  .mx-fhd-1 {
    margin-left: 0.25rem !important; }
  .m-fhd-2 {
    margin: 0.5rem !important; }
  .mt-fhd-2,
  .my-fhd-2 {
    margin-top: 0.5rem !important; }
  .mr-fhd-2,
  .mx-fhd-2 {
    margin-right: 0.5rem !important; }
  .mb-fhd-2,
  .my-fhd-2 {
    margin-bottom: 0.5rem !important; }
  .ml-fhd-2,
  .mx-fhd-2 {
    margin-left: 0.5rem !important; }
  .m-fhd-3 {
    margin: 1rem !important; }
  .mt-fhd-3,
  .my-fhd-3 {
    margin-top: 1rem !important; }
  .mr-fhd-3,
  .mx-fhd-3 {
    margin-right: 1rem !important; }
  .mb-fhd-3,
  .my-fhd-3 {
    margin-bottom: 1rem !important; }
  .ml-fhd-3,
  .mx-fhd-3 {
    margin-left: 1rem !important; }
  .m-fhd-4 {
    margin: 1.5rem !important; }
  .mt-fhd-4,
  .my-fhd-4 {
    margin-top: 1.5rem !important; }
  .mr-fhd-4,
  .mx-fhd-4 {
    margin-right: 1.5rem !important; }
  .mb-fhd-4,
  .my-fhd-4 {
    margin-bottom: 1.5rem !important; }
  .ml-fhd-4,
  .mx-fhd-4 {
    margin-left: 1.5rem !important; }
  .m-fhd-5 {
    margin: 2rem !important; }
  .mt-fhd-5,
  .my-fhd-5 {
    margin-top: 2rem !important; }
  .mr-fhd-5,
  .mx-fhd-5 {
    margin-right: 2rem !important; }
  .mb-fhd-5,
  .my-fhd-5 {
    margin-bottom: 2rem !important; }
  .ml-fhd-5,
  .mx-fhd-5 {
    margin-left: 2rem !important; }
  .m-fhd-6 {
    margin: 3rem !important; }
  .mt-fhd-6,
  .my-fhd-6 {
    margin-top: 3rem !important; }
  .mr-fhd-6,
  .mx-fhd-6 {
    margin-right: 3rem !important; }
  .mb-fhd-6,
  .my-fhd-6 {
    margin-bottom: 3rem !important; }
  .ml-fhd-6,
  .mx-fhd-6 {
    margin-left: 3rem !important; }
  .m-fhd-7 {
    margin: 4rem !important; }
  .mt-fhd-7,
  .my-fhd-7 {
    margin-top: 4rem !important; }
  .mr-fhd-7,
  .mx-fhd-7 {
    margin-right: 4rem !important; }
  .mb-fhd-7,
  .my-fhd-7 {
    margin-bottom: 4rem !important; }
  .ml-fhd-7,
  .mx-fhd-7 {
    margin-left: 4rem !important; }
  .m-fhd-8 {
    margin: 5rem !important; }
  .mt-fhd-8,
  .my-fhd-8 {
    margin-top: 5rem !important; }
  .mr-fhd-8,
  .mx-fhd-8 {
    margin-right: 5rem !important; }
  .mb-fhd-8,
  .my-fhd-8 {
    margin-bottom: 5rem !important; }
  .ml-fhd-8,
  .mx-fhd-8 {
    margin-left: 5rem !important; }
  .m-fhd-9 {
    margin: 6rem !important; }
  .mt-fhd-9,
  .my-fhd-9 {
    margin-top: 6rem !important; }
  .mr-fhd-9,
  .mx-fhd-9 {
    margin-right: 6rem !important; }
  .mb-fhd-9,
  .my-fhd-9 {
    margin-bottom: 6rem !important; }
  .ml-fhd-9,
  .mx-fhd-9 {
    margin-left: 6rem !important; }
  .m-fhd-10 {
    margin: 7rem !important; }
  .mt-fhd-10,
  .my-fhd-10 {
    margin-top: 7rem !important; }
  .mr-fhd-10,
  .mx-fhd-10 {
    margin-right: 7rem !important; }
  .mb-fhd-10,
  .my-fhd-10 {
    margin-bottom: 7rem !important; }
  .ml-fhd-10,
  .mx-fhd-10 {
    margin-left: 7rem !important; }
  .m-fhd-11 {
    margin: 8rem !important; }
  .mt-fhd-11,
  .my-fhd-11 {
    margin-top: 8rem !important; }
  .mr-fhd-11,
  .mx-fhd-11 {
    margin-right: 8rem !important; }
  .mb-fhd-11,
  .my-fhd-11 {
    margin-bottom: 8rem !important; }
  .ml-fhd-11,
  .mx-fhd-11 {
    margin-left: 8rem !important; }
  .m-fhd-12 {
    margin: 9rem !important; }
  .mt-fhd-12,
  .my-fhd-12 {
    margin-top: 9rem !important; }
  .mr-fhd-12,
  .mx-fhd-12 {
    margin-right: 9rem !important; }
  .mb-fhd-12,
  .my-fhd-12 {
    margin-bottom: 9rem !important; }
  .ml-fhd-12,
  .mx-fhd-12 {
    margin-left: 9rem !important; }
  .m-fhd-13 {
    margin: 10rem !important; }
  .mt-fhd-13,
  .my-fhd-13 {
    margin-top: 10rem !important; }
  .mr-fhd-13,
  .mx-fhd-13 {
    margin-right: 10rem !important; }
  .mb-fhd-13,
  .my-fhd-13 {
    margin-bottom: 10rem !important; }
  .ml-fhd-13,
  .mx-fhd-13 {
    margin-left: 10rem !important; }
  .m-fhd-14 {
    margin: 11rem !important; }
  .mt-fhd-14,
  .my-fhd-14 {
    margin-top: 11rem !important; }
  .mr-fhd-14,
  .mx-fhd-14 {
    margin-right: 11rem !important; }
  .mb-fhd-14,
  .my-fhd-14 {
    margin-bottom: 11rem !important; }
  .ml-fhd-14,
  .mx-fhd-14 {
    margin-left: 11rem !important; }
  .m-fhd-15 {
    margin: 12rem !important; }
  .mt-fhd-15,
  .my-fhd-15 {
    margin-top: 12rem !important; }
  .mr-fhd-15,
  .mx-fhd-15 {
    margin-right: 12rem !important; }
  .mb-fhd-15,
  .my-fhd-15 {
    margin-bottom: 12rem !important; }
  .ml-fhd-15,
  .mx-fhd-15 {
    margin-left: 12rem !important; }
  .p-fhd-0 {
    padding: 0 !important; }
  .pt-fhd-0,
  .py-fhd-0 {
    padding-top: 0 !important; }
  .pr-fhd-0,
  .px-fhd-0 {
    padding-right: 0 !important; }
  .pb-fhd-0,
  .py-fhd-0 {
    padding-bottom: 0 !important; }
  .pl-fhd-0,
  .px-fhd-0 {
    padding-left: 0 !important; }
  .p-fhd-1 {
    padding: 0.25rem !important; }
  .pt-fhd-1,
  .py-fhd-1 {
    padding-top: 0.25rem !important; }
  .pr-fhd-1,
  .px-fhd-1 {
    padding-right: 0.25rem !important; }
  .pb-fhd-1,
  .py-fhd-1 {
    padding-bottom: 0.25rem !important; }
  .pl-fhd-1,
  .px-fhd-1 {
    padding-left: 0.25rem !important; }
  .p-fhd-2 {
    padding: 0.5rem !important; }
  .pt-fhd-2,
  .py-fhd-2 {
    padding-top: 0.5rem !important; }
  .pr-fhd-2,
  .px-fhd-2 {
    padding-right: 0.5rem !important; }
  .pb-fhd-2,
  .py-fhd-2 {
    padding-bottom: 0.5rem !important; }
  .pl-fhd-2,
  .px-fhd-2 {
    padding-left: 0.5rem !important; }
  .p-fhd-3 {
    padding: 1rem !important; }
  .pt-fhd-3,
  .py-fhd-3 {
    padding-top: 1rem !important; }
  .pr-fhd-3,
  .px-fhd-3 {
    padding-right: 1rem !important; }
  .pb-fhd-3,
  .py-fhd-3 {
    padding-bottom: 1rem !important; }
  .pl-fhd-3,
  .px-fhd-3 {
    padding-left: 1rem !important; }
  .p-fhd-4 {
    padding: 1.5rem !important; }
  .pt-fhd-4,
  .py-fhd-4 {
    padding-top: 1.5rem !important; }
  .pr-fhd-4,
  .px-fhd-4 {
    padding-right: 1.5rem !important; }
  .pb-fhd-4,
  .py-fhd-4 {
    padding-bottom: 1.5rem !important; }
  .pl-fhd-4,
  .px-fhd-4 {
    padding-left: 1.5rem !important; }
  .p-fhd-5 {
    padding: 2rem !important; }
  .pt-fhd-5,
  .py-fhd-5 {
    padding-top: 2rem !important; }
  .pr-fhd-5,
  .px-fhd-5 {
    padding-right: 2rem !important; }
  .pb-fhd-5,
  .py-fhd-5 {
    padding-bottom: 2rem !important; }
  .pl-fhd-5,
  .px-fhd-5 {
    padding-left: 2rem !important; }
  .p-fhd-6 {
    padding: 3rem !important; }
  .pt-fhd-6,
  .py-fhd-6 {
    padding-top: 3rem !important; }
  .pr-fhd-6,
  .px-fhd-6 {
    padding-right: 3rem !important; }
  .pb-fhd-6,
  .py-fhd-6 {
    padding-bottom: 3rem !important; }
  .pl-fhd-6,
  .px-fhd-6 {
    padding-left: 3rem !important; }
  .p-fhd-7 {
    padding: 4rem !important; }
  .pt-fhd-7,
  .py-fhd-7 {
    padding-top: 4rem !important; }
  .pr-fhd-7,
  .px-fhd-7 {
    padding-right: 4rem !important; }
  .pb-fhd-7,
  .py-fhd-7 {
    padding-bottom: 4rem !important; }
  .pl-fhd-7,
  .px-fhd-7 {
    padding-left: 4rem !important; }
  .p-fhd-8 {
    padding: 5rem !important; }
  .pt-fhd-8,
  .py-fhd-8 {
    padding-top: 5rem !important; }
  .pr-fhd-8,
  .px-fhd-8 {
    padding-right: 5rem !important; }
  .pb-fhd-8,
  .py-fhd-8 {
    padding-bottom: 5rem !important; }
  .pl-fhd-8,
  .px-fhd-8 {
    padding-left: 5rem !important; }
  .p-fhd-9 {
    padding: 6rem !important; }
  .pt-fhd-9,
  .py-fhd-9 {
    padding-top: 6rem !important; }
  .pr-fhd-9,
  .px-fhd-9 {
    padding-right: 6rem !important; }
  .pb-fhd-9,
  .py-fhd-9 {
    padding-bottom: 6rem !important; }
  .pl-fhd-9,
  .px-fhd-9 {
    padding-left: 6rem !important; }
  .p-fhd-10 {
    padding: 7rem !important; }
  .pt-fhd-10,
  .py-fhd-10 {
    padding-top: 7rem !important; }
  .pr-fhd-10,
  .px-fhd-10 {
    padding-right: 7rem !important; }
  .pb-fhd-10,
  .py-fhd-10 {
    padding-bottom: 7rem !important; }
  .pl-fhd-10,
  .px-fhd-10 {
    padding-left: 7rem !important; }
  .p-fhd-11 {
    padding: 8rem !important; }
  .pt-fhd-11,
  .py-fhd-11 {
    padding-top: 8rem !important; }
  .pr-fhd-11,
  .px-fhd-11 {
    padding-right: 8rem !important; }
  .pb-fhd-11,
  .py-fhd-11 {
    padding-bottom: 8rem !important; }
  .pl-fhd-11,
  .px-fhd-11 {
    padding-left: 8rem !important; }
  .p-fhd-12 {
    padding: 9rem !important; }
  .pt-fhd-12,
  .py-fhd-12 {
    padding-top: 9rem !important; }
  .pr-fhd-12,
  .px-fhd-12 {
    padding-right: 9rem !important; }
  .pb-fhd-12,
  .py-fhd-12 {
    padding-bottom: 9rem !important; }
  .pl-fhd-12,
  .px-fhd-12 {
    padding-left: 9rem !important; }
  .p-fhd-13 {
    padding: 10rem !important; }
  .pt-fhd-13,
  .py-fhd-13 {
    padding-top: 10rem !important; }
  .pr-fhd-13,
  .px-fhd-13 {
    padding-right: 10rem !important; }
  .pb-fhd-13,
  .py-fhd-13 {
    padding-bottom: 10rem !important; }
  .pl-fhd-13,
  .px-fhd-13 {
    padding-left: 10rem !important; }
  .p-fhd-14 {
    padding: 11rem !important; }
  .pt-fhd-14,
  .py-fhd-14 {
    padding-top: 11rem !important; }
  .pr-fhd-14,
  .px-fhd-14 {
    padding-right: 11rem !important; }
  .pb-fhd-14,
  .py-fhd-14 {
    padding-bottom: 11rem !important; }
  .pl-fhd-14,
  .px-fhd-14 {
    padding-left: 11rem !important; }
  .p-fhd-15 {
    padding: 12rem !important; }
  .pt-fhd-15,
  .py-fhd-15 {
    padding-top: 12rem !important; }
  .pr-fhd-15,
  .px-fhd-15 {
    padding-right: 12rem !important; }
  .pb-fhd-15,
  .py-fhd-15 {
    padding-bottom: 12rem !important; }
  .pl-fhd-15,
  .px-fhd-15 {
    padding-left: 12rem !important; }
  .m-fhd-n1 {
    margin: -0.25rem !important; }
  .mt-fhd-n1,
  .my-fhd-n1 {
    margin-top: -0.25rem !important; }
  .mr-fhd-n1,
  .mx-fhd-n1 {
    margin-right: -0.25rem !important; }
  .mb-fhd-n1,
  .my-fhd-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-fhd-n1,
  .mx-fhd-n1 {
    margin-left: -0.25rem !important; }
  .m-fhd-n2 {
    margin: -0.5rem !important; }
  .mt-fhd-n2,
  .my-fhd-n2 {
    margin-top: -0.5rem !important; }
  .mr-fhd-n2,
  .mx-fhd-n2 {
    margin-right: -0.5rem !important; }
  .mb-fhd-n2,
  .my-fhd-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-fhd-n2,
  .mx-fhd-n2 {
    margin-left: -0.5rem !important; }
  .m-fhd-n3 {
    margin: -1rem !important; }
  .mt-fhd-n3,
  .my-fhd-n3 {
    margin-top: -1rem !important; }
  .mr-fhd-n3,
  .mx-fhd-n3 {
    margin-right: -1rem !important; }
  .mb-fhd-n3,
  .my-fhd-n3 {
    margin-bottom: -1rem !important; }
  .ml-fhd-n3,
  .mx-fhd-n3 {
    margin-left: -1rem !important; }
  .m-fhd-n4 {
    margin: -1.5rem !important; }
  .mt-fhd-n4,
  .my-fhd-n4 {
    margin-top: -1.5rem !important; }
  .mr-fhd-n4,
  .mx-fhd-n4 {
    margin-right: -1.5rem !important; }
  .mb-fhd-n4,
  .my-fhd-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-fhd-n4,
  .mx-fhd-n4 {
    margin-left: -1.5rem !important; }
  .m-fhd-n5 {
    margin: -2rem !important; }
  .mt-fhd-n5,
  .my-fhd-n5 {
    margin-top: -2rem !important; }
  .mr-fhd-n5,
  .mx-fhd-n5 {
    margin-right: -2rem !important; }
  .mb-fhd-n5,
  .my-fhd-n5 {
    margin-bottom: -2rem !important; }
  .ml-fhd-n5,
  .mx-fhd-n5 {
    margin-left: -2rem !important; }
  .m-fhd-n6 {
    margin: -3rem !important; }
  .mt-fhd-n6,
  .my-fhd-n6 {
    margin-top: -3rem !important; }
  .mr-fhd-n6,
  .mx-fhd-n6 {
    margin-right: -3rem !important; }
  .mb-fhd-n6,
  .my-fhd-n6 {
    margin-bottom: -3rem !important; }
  .ml-fhd-n6,
  .mx-fhd-n6 {
    margin-left: -3rem !important; }
  .m-fhd-n7 {
    margin: -4rem !important; }
  .mt-fhd-n7,
  .my-fhd-n7 {
    margin-top: -4rem !important; }
  .mr-fhd-n7,
  .mx-fhd-n7 {
    margin-right: -4rem !important; }
  .mb-fhd-n7,
  .my-fhd-n7 {
    margin-bottom: -4rem !important; }
  .ml-fhd-n7,
  .mx-fhd-n7 {
    margin-left: -4rem !important; }
  .m-fhd-n8 {
    margin: -5rem !important; }
  .mt-fhd-n8,
  .my-fhd-n8 {
    margin-top: -5rem !important; }
  .mr-fhd-n8,
  .mx-fhd-n8 {
    margin-right: -5rem !important; }
  .mb-fhd-n8,
  .my-fhd-n8 {
    margin-bottom: -5rem !important; }
  .ml-fhd-n8,
  .mx-fhd-n8 {
    margin-left: -5rem !important; }
  .m-fhd-n9 {
    margin: -6rem !important; }
  .mt-fhd-n9,
  .my-fhd-n9 {
    margin-top: -6rem !important; }
  .mr-fhd-n9,
  .mx-fhd-n9 {
    margin-right: -6rem !important; }
  .mb-fhd-n9,
  .my-fhd-n9 {
    margin-bottom: -6rem !important; }
  .ml-fhd-n9,
  .mx-fhd-n9 {
    margin-left: -6rem !important; }
  .m-fhd-n10 {
    margin: -7rem !important; }
  .mt-fhd-n10,
  .my-fhd-n10 {
    margin-top: -7rem !important; }
  .mr-fhd-n10,
  .mx-fhd-n10 {
    margin-right: -7rem !important; }
  .mb-fhd-n10,
  .my-fhd-n10 {
    margin-bottom: -7rem !important; }
  .ml-fhd-n10,
  .mx-fhd-n10 {
    margin-left: -7rem !important; }
  .m-fhd-n11 {
    margin: -8rem !important; }
  .mt-fhd-n11,
  .my-fhd-n11 {
    margin-top: -8rem !important; }
  .mr-fhd-n11,
  .mx-fhd-n11 {
    margin-right: -8rem !important; }
  .mb-fhd-n11,
  .my-fhd-n11 {
    margin-bottom: -8rem !important; }
  .ml-fhd-n11,
  .mx-fhd-n11 {
    margin-left: -8rem !important; }
  .m-fhd-n12 {
    margin: -9rem !important; }
  .mt-fhd-n12,
  .my-fhd-n12 {
    margin-top: -9rem !important; }
  .mr-fhd-n12,
  .mx-fhd-n12 {
    margin-right: -9rem !important; }
  .mb-fhd-n12,
  .my-fhd-n12 {
    margin-bottom: -9rem !important; }
  .ml-fhd-n12,
  .mx-fhd-n12 {
    margin-left: -9rem !important; }
  .m-fhd-n13 {
    margin: -10rem !important; }
  .mt-fhd-n13,
  .my-fhd-n13 {
    margin-top: -10rem !important; }
  .mr-fhd-n13,
  .mx-fhd-n13 {
    margin-right: -10rem !important; }
  .mb-fhd-n13,
  .my-fhd-n13 {
    margin-bottom: -10rem !important; }
  .ml-fhd-n13,
  .mx-fhd-n13 {
    margin-left: -10rem !important; }
  .m-fhd-n14 {
    margin: -11rem !important; }
  .mt-fhd-n14,
  .my-fhd-n14 {
    margin-top: -11rem !important; }
  .mr-fhd-n14,
  .mx-fhd-n14 {
    margin-right: -11rem !important; }
  .mb-fhd-n14,
  .my-fhd-n14 {
    margin-bottom: -11rem !important; }
  .ml-fhd-n14,
  .mx-fhd-n14 {
    margin-left: -11rem !important; }
  .m-fhd-n15 {
    margin: -12rem !important; }
  .mt-fhd-n15,
  .my-fhd-n15 {
    margin-top: -12rem !important; }
  .mr-fhd-n15,
  .mx-fhd-n15 {
    margin-right: -12rem !important; }
  .mb-fhd-n15,
  .my-fhd-n15 {
    margin-bottom: -12rem !important; }
  .ml-fhd-n15,
  .mx-fhd-n15 {
    margin-left: -12rem !important; }
  .m-fhd-auto {
    margin: auto !important; }
  .mt-fhd-auto,
  .my-fhd-auto {
    margin-top: auto !important; }
  .mr-fhd-auto,
  .mx-fhd-auto {
    margin-right: auto !important; }
  .mb-fhd-auto,
  .my-fhd-auto {
    margin-bottom: auto !important; }
  .ml-fhd-auto,
  .mx-fhd-auto {
    margin-left: auto !important; } }

@media (min-width: 2540px) {
  .m-qhd-0 {
    margin: 0 !important; }
  .mt-qhd-0,
  .my-qhd-0 {
    margin-top: 0 !important; }
  .mr-qhd-0,
  .mx-qhd-0 {
    margin-right: 0 !important; }
  .mb-qhd-0,
  .my-qhd-0 {
    margin-bottom: 0 !important; }
  .ml-qhd-0,
  .mx-qhd-0 {
    margin-left: 0 !important; }
  .m-qhd-1 {
    margin: 0.25rem !important; }
  .mt-qhd-1,
  .my-qhd-1 {
    margin-top: 0.25rem !important; }
  .mr-qhd-1,
  .mx-qhd-1 {
    margin-right: 0.25rem !important; }
  .mb-qhd-1,
  .my-qhd-1 {
    margin-bottom: 0.25rem !important; }
  .ml-qhd-1,
  .mx-qhd-1 {
    margin-left: 0.25rem !important; }
  .m-qhd-2 {
    margin: 0.5rem !important; }
  .mt-qhd-2,
  .my-qhd-2 {
    margin-top: 0.5rem !important; }
  .mr-qhd-2,
  .mx-qhd-2 {
    margin-right: 0.5rem !important; }
  .mb-qhd-2,
  .my-qhd-2 {
    margin-bottom: 0.5rem !important; }
  .ml-qhd-2,
  .mx-qhd-2 {
    margin-left: 0.5rem !important; }
  .m-qhd-3 {
    margin: 1rem !important; }
  .mt-qhd-3,
  .my-qhd-3 {
    margin-top: 1rem !important; }
  .mr-qhd-3,
  .mx-qhd-3 {
    margin-right: 1rem !important; }
  .mb-qhd-3,
  .my-qhd-3 {
    margin-bottom: 1rem !important; }
  .ml-qhd-3,
  .mx-qhd-3 {
    margin-left: 1rem !important; }
  .m-qhd-4 {
    margin: 1.5rem !important; }
  .mt-qhd-4,
  .my-qhd-4 {
    margin-top: 1.5rem !important; }
  .mr-qhd-4,
  .mx-qhd-4 {
    margin-right: 1.5rem !important; }
  .mb-qhd-4,
  .my-qhd-4 {
    margin-bottom: 1.5rem !important; }
  .ml-qhd-4,
  .mx-qhd-4 {
    margin-left: 1.5rem !important; }
  .m-qhd-5 {
    margin: 2rem !important; }
  .mt-qhd-5,
  .my-qhd-5 {
    margin-top: 2rem !important; }
  .mr-qhd-5,
  .mx-qhd-5 {
    margin-right: 2rem !important; }
  .mb-qhd-5,
  .my-qhd-5 {
    margin-bottom: 2rem !important; }
  .ml-qhd-5,
  .mx-qhd-5 {
    margin-left: 2rem !important; }
  .m-qhd-6 {
    margin: 3rem !important; }
  .mt-qhd-6,
  .my-qhd-6 {
    margin-top: 3rem !important; }
  .mr-qhd-6,
  .mx-qhd-6 {
    margin-right: 3rem !important; }
  .mb-qhd-6,
  .my-qhd-6 {
    margin-bottom: 3rem !important; }
  .ml-qhd-6,
  .mx-qhd-6 {
    margin-left: 3rem !important; }
  .m-qhd-7 {
    margin: 4rem !important; }
  .mt-qhd-7,
  .my-qhd-7 {
    margin-top: 4rem !important; }
  .mr-qhd-7,
  .mx-qhd-7 {
    margin-right: 4rem !important; }
  .mb-qhd-7,
  .my-qhd-7 {
    margin-bottom: 4rem !important; }
  .ml-qhd-7,
  .mx-qhd-7 {
    margin-left: 4rem !important; }
  .m-qhd-8 {
    margin: 5rem !important; }
  .mt-qhd-8,
  .my-qhd-8 {
    margin-top: 5rem !important; }
  .mr-qhd-8,
  .mx-qhd-8 {
    margin-right: 5rem !important; }
  .mb-qhd-8,
  .my-qhd-8 {
    margin-bottom: 5rem !important; }
  .ml-qhd-8,
  .mx-qhd-8 {
    margin-left: 5rem !important; }
  .m-qhd-9 {
    margin: 6rem !important; }
  .mt-qhd-9,
  .my-qhd-9 {
    margin-top: 6rem !important; }
  .mr-qhd-9,
  .mx-qhd-9 {
    margin-right: 6rem !important; }
  .mb-qhd-9,
  .my-qhd-9 {
    margin-bottom: 6rem !important; }
  .ml-qhd-9,
  .mx-qhd-9 {
    margin-left: 6rem !important; }
  .m-qhd-10 {
    margin: 7rem !important; }
  .mt-qhd-10,
  .my-qhd-10 {
    margin-top: 7rem !important; }
  .mr-qhd-10,
  .mx-qhd-10 {
    margin-right: 7rem !important; }
  .mb-qhd-10,
  .my-qhd-10 {
    margin-bottom: 7rem !important; }
  .ml-qhd-10,
  .mx-qhd-10 {
    margin-left: 7rem !important; }
  .m-qhd-11 {
    margin: 8rem !important; }
  .mt-qhd-11,
  .my-qhd-11 {
    margin-top: 8rem !important; }
  .mr-qhd-11,
  .mx-qhd-11 {
    margin-right: 8rem !important; }
  .mb-qhd-11,
  .my-qhd-11 {
    margin-bottom: 8rem !important; }
  .ml-qhd-11,
  .mx-qhd-11 {
    margin-left: 8rem !important; }
  .m-qhd-12 {
    margin: 9rem !important; }
  .mt-qhd-12,
  .my-qhd-12 {
    margin-top: 9rem !important; }
  .mr-qhd-12,
  .mx-qhd-12 {
    margin-right: 9rem !important; }
  .mb-qhd-12,
  .my-qhd-12 {
    margin-bottom: 9rem !important; }
  .ml-qhd-12,
  .mx-qhd-12 {
    margin-left: 9rem !important; }
  .m-qhd-13 {
    margin: 10rem !important; }
  .mt-qhd-13,
  .my-qhd-13 {
    margin-top: 10rem !important; }
  .mr-qhd-13,
  .mx-qhd-13 {
    margin-right: 10rem !important; }
  .mb-qhd-13,
  .my-qhd-13 {
    margin-bottom: 10rem !important; }
  .ml-qhd-13,
  .mx-qhd-13 {
    margin-left: 10rem !important; }
  .m-qhd-14 {
    margin: 11rem !important; }
  .mt-qhd-14,
  .my-qhd-14 {
    margin-top: 11rem !important; }
  .mr-qhd-14,
  .mx-qhd-14 {
    margin-right: 11rem !important; }
  .mb-qhd-14,
  .my-qhd-14 {
    margin-bottom: 11rem !important; }
  .ml-qhd-14,
  .mx-qhd-14 {
    margin-left: 11rem !important; }
  .m-qhd-15 {
    margin: 12rem !important; }
  .mt-qhd-15,
  .my-qhd-15 {
    margin-top: 12rem !important; }
  .mr-qhd-15,
  .mx-qhd-15 {
    margin-right: 12rem !important; }
  .mb-qhd-15,
  .my-qhd-15 {
    margin-bottom: 12rem !important; }
  .ml-qhd-15,
  .mx-qhd-15 {
    margin-left: 12rem !important; }
  .p-qhd-0 {
    padding: 0 !important; }
  .pt-qhd-0,
  .py-qhd-0 {
    padding-top: 0 !important; }
  .pr-qhd-0,
  .px-qhd-0 {
    padding-right: 0 !important; }
  .pb-qhd-0,
  .py-qhd-0 {
    padding-bottom: 0 !important; }
  .pl-qhd-0,
  .px-qhd-0 {
    padding-left: 0 !important; }
  .p-qhd-1 {
    padding: 0.25rem !important; }
  .pt-qhd-1,
  .py-qhd-1 {
    padding-top: 0.25rem !important; }
  .pr-qhd-1,
  .px-qhd-1 {
    padding-right: 0.25rem !important; }
  .pb-qhd-1,
  .py-qhd-1 {
    padding-bottom: 0.25rem !important; }
  .pl-qhd-1,
  .px-qhd-1 {
    padding-left: 0.25rem !important; }
  .p-qhd-2 {
    padding: 0.5rem !important; }
  .pt-qhd-2,
  .py-qhd-2 {
    padding-top: 0.5rem !important; }
  .pr-qhd-2,
  .px-qhd-2 {
    padding-right: 0.5rem !important; }
  .pb-qhd-2,
  .py-qhd-2 {
    padding-bottom: 0.5rem !important; }
  .pl-qhd-2,
  .px-qhd-2 {
    padding-left: 0.5rem !important; }
  .p-qhd-3 {
    padding: 1rem !important; }
  .pt-qhd-3,
  .py-qhd-3 {
    padding-top: 1rem !important; }
  .pr-qhd-3,
  .px-qhd-3 {
    padding-right: 1rem !important; }
  .pb-qhd-3,
  .py-qhd-3 {
    padding-bottom: 1rem !important; }
  .pl-qhd-3,
  .px-qhd-3 {
    padding-left: 1rem !important; }
  .p-qhd-4 {
    padding: 1.5rem !important; }
  .pt-qhd-4,
  .py-qhd-4 {
    padding-top: 1.5rem !important; }
  .pr-qhd-4,
  .px-qhd-4 {
    padding-right: 1.5rem !important; }
  .pb-qhd-4,
  .py-qhd-4 {
    padding-bottom: 1.5rem !important; }
  .pl-qhd-4,
  .px-qhd-4 {
    padding-left: 1.5rem !important; }
  .p-qhd-5 {
    padding: 2rem !important; }
  .pt-qhd-5,
  .py-qhd-5 {
    padding-top: 2rem !important; }
  .pr-qhd-5,
  .px-qhd-5 {
    padding-right: 2rem !important; }
  .pb-qhd-5,
  .py-qhd-5 {
    padding-bottom: 2rem !important; }
  .pl-qhd-5,
  .px-qhd-5 {
    padding-left: 2rem !important; }
  .p-qhd-6 {
    padding: 3rem !important; }
  .pt-qhd-6,
  .py-qhd-6 {
    padding-top: 3rem !important; }
  .pr-qhd-6,
  .px-qhd-6 {
    padding-right: 3rem !important; }
  .pb-qhd-6,
  .py-qhd-6 {
    padding-bottom: 3rem !important; }
  .pl-qhd-6,
  .px-qhd-6 {
    padding-left: 3rem !important; }
  .p-qhd-7 {
    padding: 4rem !important; }
  .pt-qhd-7,
  .py-qhd-7 {
    padding-top: 4rem !important; }
  .pr-qhd-7,
  .px-qhd-7 {
    padding-right: 4rem !important; }
  .pb-qhd-7,
  .py-qhd-7 {
    padding-bottom: 4rem !important; }
  .pl-qhd-7,
  .px-qhd-7 {
    padding-left: 4rem !important; }
  .p-qhd-8 {
    padding: 5rem !important; }
  .pt-qhd-8,
  .py-qhd-8 {
    padding-top: 5rem !important; }
  .pr-qhd-8,
  .px-qhd-8 {
    padding-right: 5rem !important; }
  .pb-qhd-8,
  .py-qhd-8 {
    padding-bottom: 5rem !important; }
  .pl-qhd-8,
  .px-qhd-8 {
    padding-left: 5rem !important; }
  .p-qhd-9 {
    padding: 6rem !important; }
  .pt-qhd-9,
  .py-qhd-9 {
    padding-top: 6rem !important; }
  .pr-qhd-9,
  .px-qhd-9 {
    padding-right: 6rem !important; }
  .pb-qhd-9,
  .py-qhd-9 {
    padding-bottom: 6rem !important; }
  .pl-qhd-9,
  .px-qhd-9 {
    padding-left: 6rem !important; }
  .p-qhd-10 {
    padding: 7rem !important; }
  .pt-qhd-10,
  .py-qhd-10 {
    padding-top: 7rem !important; }
  .pr-qhd-10,
  .px-qhd-10 {
    padding-right: 7rem !important; }
  .pb-qhd-10,
  .py-qhd-10 {
    padding-bottom: 7rem !important; }
  .pl-qhd-10,
  .px-qhd-10 {
    padding-left: 7rem !important; }
  .p-qhd-11 {
    padding: 8rem !important; }
  .pt-qhd-11,
  .py-qhd-11 {
    padding-top: 8rem !important; }
  .pr-qhd-11,
  .px-qhd-11 {
    padding-right: 8rem !important; }
  .pb-qhd-11,
  .py-qhd-11 {
    padding-bottom: 8rem !important; }
  .pl-qhd-11,
  .px-qhd-11 {
    padding-left: 8rem !important; }
  .p-qhd-12 {
    padding: 9rem !important; }
  .pt-qhd-12,
  .py-qhd-12 {
    padding-top: 9rem !important; }
  .pr-qhd-12,
  .px-qhd-12 {
    padding-right: 9rem !important; }
  .pb-qhd-12,
  .py-qhd-12 {
    padding-bottom: 9rem !important; }
  .pl-qhd-12,
  .px-qhd-12 {
    padding-left: 9rem !important; }
  .p-qhd-13 {
    padding: 10rem !important; }
  .pt-qhd-13,
  .py-qhd-13 {
    padding-top: 10rem !important; }
  .pr-qhd-13,
  .px-qhd-13 {
    padding-right: 10rem !important; }
  .pb-qhd-13,
  .py-qhd-13 {
    padding-bottom: 10rem !important; }
  .pl-qhd-13,
  .px-qhd-13 {
    padding-left: 10rem !important; }
  .p-qhd-14 {
    padding: 11rem !important; }
  .pt-qhd-14,
  .py-qhd-14 {
    padding-top: 11rem !important; }
  .pr-qhd-14,
  .px-qhd-14 {
    padding-right: 11rem !important; }
  .pb-qhd-14,
  .py-qhd-14 {
    padding-bottom: 11rem !important; }
  .pl-qhd-14,
  .px-qhd-14 {
    padding-left: 11rem !important; }
  .p-qhd-15 {
    padding: 12rem !important; }
  .pt-qhd-15,
  .py-qhd-15 {
    padding-top: 12rem !important; }
  .pr-qhd-15,
  .px-qhd-15 {
    padding-right: 12rem !important; }
  .pb-qhd-15,
  .py-qhd-15 {
    padding-bottom: 12rem !important; }
  .pl-qhd-15,
  .px-qhd-15 {
    padding-left: 12rem !important; }
  .m-qhd-n1 {
    margin: -0.25rem !important; }
  .mt-qhd-n1,
  .my-qhd-n1 {
    margin-top: -0.25rem !important; }
  .mr-qhd-n1,
  .mx-qhd-n1 {
    margin-right: -0.25rem !important; }
  .mb-qhd-n1,
  .my-qhd-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-qhd-n1,
  .mx-qhd-n1 {
    margin-left: -0.25rem !important; }
  .m-qhd-n2 {
    margin: -0.5rem !important; }
  .mt-qhd-n2,
  .my-qhd-n2 {
    margin-top: -0.5rem !important; }
  .mr-qhd-n2,
  .mx-qhd-n2 {
    margin-right: -0.5rem !important; }
  .mb-qhd-n2,
  .my-qhd-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-qhd-n2,
  .mx-qhd-n2 {
    margin-left: -0.5rem !important; }
  .m-qhd-n3 {
    margin: -1rem !important; }
  .mt-qhd-n3,
  .my-qhd-n3 {
    margin-top: -1rem !important; }
  .mr-qhd-n3,
  .mx-qhd-n3 {
    margin-right: -1rem !important; }
  .mb-qhd-n3,
  .my-qhd-n3 {
    margin-bottom: -1rem !important; }
  .ml-qhd-n3,
  .mx-qhd-n3 {
    margin-left: -1rem !important; }
  .m-qhd-n4 {
    margin: -1.5rem !important; }
  .mt-qhd-n4,
  .my-qhd-n4 {
    margin-top: -1.5rem !important; }
  .mr-qhd-n4,
  .mx-qhd-n4 {
    margin-right: -1.5rem !important; }
  .mb-qhd-n4,
  .my-qhd-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-qhd-n4,
  .mx-qhd-n4 {
    margin-left: -1.5rem !important; }
  .m-qhd-n5 {
    margin: -2rem !important; }
  .mt-qhd-n5,
  .my-qhd-n5 {
    margin-top: -2rem !important; }
  .mr-qhd-n5,
  .mx-qhd-n5 {
    margin-right: -2rem !important; }
  .mb-qhd-n5,
  .my-qhd-n5 {
    margin-bottom: -2rem !important; }
  .ml-qhd-n5,
  .mx-qhd-n5 {
    margin-left: -2rem !important; }
  .m-qhd-n6 {
    margin: -3rem !important; }
  .mt-qhd-n6,
  .my-qhd-n6 {
    margin-top: -3rem !important; }
  .mr-qhd-n6,
  .mx-qhd-n6 {
    margin-right: -3rem !important; }
  .mb-qhd-n6,
  .my-qhd-n6 {
    margin-bottom: -3rem !important; }
  .ml-qhd-n6,
  .mx-qhd-n6 {
    margin-left: -3rem !important; }
  .m-qhd-n7 {
    margin: -4rem !important; }
  .mt-qhd-n7,
  .my-qhd-n7 {
    margin-top: -4rem !important; }
  .mr-qhd-n7,
  .mx-qhd-n7 {
    margin-right: -4rem !important; }
  .mb-qhd-n7,
  .my-qhd-n7 {
    margin-bottom: -4rem !important; }
  .ml-qhd-n7,
  .mx-qhd-n7 {
    margin-left: -4rem !important; }
  .m-qhd-n8 {
    margin: -5rem !important; }
  .mt-qhd-n8,
  .my-qhd-n8 {
    margin-top: -5rem !important; }
  .mr-qhd-n8,
  .mx-qhd-n8 {
    margin-right: -5rem !important; }
  .mb-qhd-n8,
  .my-qhd-n8 {
    margin-bottom: -5rem !important; }
  .ml-qhd-n8,
  .mx-qhd-n8 {
    margin-left: -5rem !important; }
  .m-qhd-n9 {
    margin: -6rem !important; }
  .mt-qhd-n9,
  .my-qhd-n9 {
    margin-top: -6rem !important; }
  .mr-qhd-n9,
  .mx-qhd-n9 {
    margin-right: -6rem !important; }
  .mb-qhd-n9,
  .my-qhd-n9 {
    margin-bottom: -6rem !important; }
  .ml-qhd-n9,
  .mx-qhd-n9 {
    margin-left: -6rem !important; }
  .m-qhd-n10 {
    margin: -7rem !important; }
  .mt-qhd-n10,
  .my-qhd-n10 {
    margin-top: -7rem !important; }
  .mr-qhd-n10,
  .mx-qhd-n10 {
    margin-right: -7rem !important; }
  .mb-qhd-n10,
  .my-qhd-n10 {
    margin-bottom: -7rem !important; }
  .ml-qhd-n10,
  .mx-qhd-n10 {
    margin-left: -7rem !important; }
  .m-qhd-n11 {
    margin: -8rem !important; }
  .mt-qhd-n11,
  .my-qhd-n11 {
    margin-top: -8rem !important; }
  .mr-qhd-n11,
  .mx-qhd-n11 {
    margin-right: -8rem !important; }
  .mb-qhd-n11,
  .my-qhd-n11 {
    margin-bottom: -8rem !important; }
  .ml-qhd-n11,
  .mx-qhd-n11 {
    margin-left: -8rem !important; }
  .m-qhd-n12 {
    margin: -9rem !important; }
  .mt-qhd-n12,
  .my-qhd-n12 {
    margin-top: -9rem !important; }
  .mr-qhd-n12,
  .mx-qhd-n12 {
    margin-right: -9rem !important; }
  .mb-qhd-n12,
  .my-qhd-n12 {
    margin-bottom: -9rem !important; }
  .ml-qhd-n12,
  .mx-qhd-n12 {
    margin-left: -9rem !important; }
  .m-qhd-n13 {
    margin: -10rem !important; }
  .mt-qhd-n13,
  .my-qhd-n13 {
    margin-top: -10rem !important; }
  .mr-qhd-n13,
  .mx-qhd-n13 {
    margin-right: -10rem !important; }
  .mb-qhd-n13,
  .my-qhd-n13 {
    margin-bottom: -10rem !important; }
  .ml-qhd-n13,
  .mx-qhd-n13 {
    margin-left: -10rem !important; }
  .m-qhd-n14 {
    margin: -11rem !important; }
  .mt-qhd-n14,
  .my-qhd-n14 {
    margin-top: -11rem !important; }
  .mr-qhd-n14,
  .mx-qhd-n14 {
    margin-right: -11rem !important; }
  .mb-qhd-n14,
  .my-qhd-n14 {
    margin-bottom: -11rem !important; }
  .ml-qhd-n14,
  .mx-qhd-n14 {
    margin-left: -11rem !important; }
  .m-qhd-n15 {
    margin: -12rem !important; }
  .mt-qhd-n15,
  .my-qhd-n15 {
    margin-top: -12rem !important; }
  .mr-qhd-n15,
  .mx-qhd-n15 {
    margin-right: -12rem !important; }
  .mb-qhd-n15,
  .my-qhd-n15 {
    margin-bottom: -12rem !important; }
  .ml-qhd-n15,
  .mx-qhd-n15 {
    margin-left: -12rem !important; }
  .m-qhd-auto {
    margin: auto !important; }
  .mt-qhd-auto,
  .my-qhd-auto {
    margin-top: auto !important; }
  .mr-qhd-auto,
  .mx-qhd-auto {
    margin-right: auto !important; }
  .mb-qhd-auto,
  .my-qhd-auto {
    margin-bottom: auto !important; }
  .ml-qhd-auto,
  .mx-qhd-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1900px) {
  .text-fhd-left {
    text-align: left !important; }
  .text-fhd-right {
    text-align: right !important; }
  .text-fhd-center {
    text-align: center !important; } }

@media (min-width: 2540px) {
  .text-qhd-left {
    text-align: left !important; }
  .text-qhd-right {
    text-align: right !important; }
  .text-qhd-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #1d612a !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0b2611 !important; }

.text-secondary {
  color: #808080 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #5a5a5a !important; }

.text-success {
  color: #1d612a !important; }

a.text-success:hover, a.text-success:focus {
  color: #0b2611 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #fff !important; }

a.text-light:hover, a.text-light:focus {
  color: #d9d9d9 !important; }

.text-dark {
  color: #3e3938 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #161414 !important; }

.text-ternary {
  color: #9CDE9F !important; }

a.text-ternary:hover, a.text-ternary:focus {
  color: #63cb67 !important; }

.text-body {
  color: #808080 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: rgba(255, 255, 255, 0.15); }
  .table .thead-dark th {
    color: inherit;
    border-color: rgba(255, 255, 255, 0.15); } }

/*! Flickity v2.2.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.banner-inner, .hero-block .hero-block-inner, .flex {
  display: flex !important; }

.banner-inner, .hero-block .hero-block-inner {
  justify-content: center;
  align-items: center; }

.flex-justify-start {
  justify-content: flex-start; }

.flex-justify-center {
  justify-content: center; }

.flex-justify-end {
  justify-content: flex-end; }

.banner-inner, .hero-block .hero-block-inner {
  flex-direction: column; }

.flex--grow {
  flex: 1; }

.flex--shrink {
  flex: 0 1; }

@media screen {
  .js {
    /* So they look staggered */ } }
  @media screen and (min-width: 992px) {
    .js .fromLeft {
      opacity: 0.0;
      transform: translate3d(-128px, 0, 0); }
    .js .fromRight {
      opacity: 0.0;
      transform: translate3d(128px, 0, 0); }
    .js .slideUp {
      opacity: 0.0;
      transform: translate3d(0, 128px, 0); } }

@media screen {
    .js .slide {
      animation: slide;
      animation-delay: 0;
      animation-duration: 500ms;
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-play-state: running; }
  @keyframes slide {
    to {
      opacity: 1.0;
      transform: translate3d(0, 0, 0); } }
    .js .fade-in {
      opacity: 0.0; } }

a {
  transition: all 100ms cubic-bezier(0, 0, 0.2, 1); }

.email-icon-link {
  font-size: 18px;
  color: #1d612a;
  display: inline-block;
  font-weight: 600;
  line-height: 28px;
  margin-left: 0;
  margin-right: auto;
  padding-left: 2rem;
  position: relative;
  width: auto; }
  .email-icon-link:before {
    display: inline-block;
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: inherit;
    content: "\f0e0";
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    position: absolute;
    left: 0;
    top: 0;
    vertical-align: middle; }
  .email-icon-link:hover {
    color: #9CDE9F; }
    .email-icon-link:hover:before {
      content: "\f2b6";
      top: -2px; }

.back-link {
  font-size: 1.25rem;
  letter-spacing: 0.02em;
  font-family: "neusa-next-std-compact", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  line-height: 29px;
  text-decoration: none !important;
  text-transform: uppercase; }
  .back-link::before {
    display: inline-block;
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: inherit;
    content: "\f060";
    display: inline-block;
    font-weight: 900;
    margin-right: 0.5rem; }
  .back-link:hover::before {
    animation: left-bounce 750ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
    animation-play-state: running; }

@keyframes left-bounce {
  0%,
  100% {
    transform: translate3d(0, 0, 0); }
  50% {
    transform: translate3d(-70%, 0, 0); } }

.forward-link {
  font-size: 1.25rem;
  letter-spacing: 0.02em;
  color: inherit;
  font-family: "neusa-next-std-compact", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  line-height: 29px;
  text-decoration: none !important;
  text-transform: uppercase; }
  .forward-link:after {
    display: inline-block;
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: inherit;
    content: "\f061";
    display: inline-block;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    margin-left: 0.5rem; }
  .forward-link:hover::after {
    animation: right-bounce 750ms cubic-bezier(0.4, 0, 0.2, 1) infinite;
    animation-play-state: running; }

@keyframes right-bounce {
  0%,
  100% {
    transform: translate3d(0, 0, 0); }
  50% {
    transform: translate3d(70%, 0, 0); } }

h1, h2, h3, h4, h5, h6, blockquote, button, li, p, a, .btn,
input[type='submit'],
input[type='text'],
input[type='email'],
input[type='tel'],
label, select, option, textarea,
.font-smoothing,
.content-block p,
.feature-block-content p,
.green-caption-left-block p,
.left-image-right-text-block p,
.left-text-right-image-block p {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.caption {
  font-size: 4.625rem; }
  @media (max-width: 1200px) {
    .caption {
      font-size: calc(1.5875rem + 4.05vw) ; } }

h1, .h1 {
  font-size: 3.375rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.4625rem + 2.55vw) ; } }

h2, .h2 {
  font-size: 2.875rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.4125rem + 1.95vw) ; } }

small {
  font-size: 0.875rem;
  text-transform: none; }
  small a {
    text-decoration: none; }

p {
  font-weight: 300; }

.title,
.title-accent-left,
.title-accent-center {
  padding-top: 1.5rem;
  position: relative; }
  @media (min-width: 768px) {
    .title,
    .title-accent-left,
    .title-accent-center {
      margin-bottom: 2rem; } }
  @media (min-width: 992px) {
    .title,
    .title-accent-left,
    .title-accent-center {
      margin-bottom: 2.5rem; } }

.title-accent-left:before,
.title-accent-center:before {
  background-color: #1d612a;
  content: "";
  display: block;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 4.25rem; }

.title-accent-center {
  text-align: center; }
  .title-accent-center:before {
    left: 50%;
    transform: translateX(-50%); }

.page-title {
  font-size: 3.75rem;
  color: #1d612a; }
  @media (max-width: 1200px) {
    .page-title {
      font-size: calc(1.5rem + 3vw) ; } }


.font-weight-semi-bold {
  font-weight: 600; }

.list-unstyled > li {
  white-space: nowrap; }

.banner {
  height: 82vh;
  margin-top: 60px;
  position: relative;
  opacity: 1;
  overflow: hidden;
  width: 100%;
  z-index: 1; }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .banner {
      height: 50vh; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .banner {
      height: 58vh; } }
  @media (min-width: 1200px) {
    .banner {
      padding-bottom: 36.5625%;
      height: 0;
      margin-top: 138px; } }
  .banner .banner__item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 100%;
    overflow: hidden;
    transition: opacity 100ms cubic-bezier(0, 0, 0.2, 1);
    width: 100%; }
    @media (min-width: 1200px) {
      .banner .banner__item {
        padding-bottom: 36.5625%;
        height: 0; } }
    .banner .banner__item.dark-overlay:before {
      background-color: rgba(23, 23, 23, 0.45);
      bottom: 0;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 1; }

.banner-inner {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 150ms cubic-bezier(0, 0, 0.2, 1);
  width: 100%;
  z-index: 2; }

.home .banner_title {
  font-size: 3.375rem; }
  @media (max-width: 1200px) {
    .home .banner_title {
      font-size: calc(1.4625rem + 2.55vw) ; } }

.home .banner_caption {
  font-size: 1.25rem; }

.banner_title {
  font-size: 4.625rem;
  color: #fff;
  margin-top: auto;
  margin-bottom: 2rem; }
  @media (max-width: 1200px) {
    .banner_title {
      font-size: calc(1.5875rem + 4.05vw) ; } }

.banner_caption {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 400;
  line-height: 34px;
  margin-bottom: 3rem; }
  @media (max-width: 1200px) {
    .banner_caption {
      font-size: calc(1.275rem + 0.3vw) ; } }

.light-green .banner_title,
.light-green .banner_caption,
.light-grey .banner_title,
.light-grey .banner_caption {
  color: #1d612a; }

.banner-nav-container {
  position: relative;
  top: -3rem;
  z-index: 2; }
  @media (min-width: 1200px) {
    .banner-nav-container {
      top: -4rem; } }
  .banner-nav-container .banner-nav {
    align-items: center;
    display: flex;
    position: relative;
    z-index: 3; }
    .banner-nav-container .banner-nav ol {
      margin: 0; }
      .banner-nav-container .banner-nav ol li {
        background: #fff;
        border-radius: 0;
        cursor: pointer;
        display: inline-block;
        opacity: 0.25;
        position: relative;
        margin: 0 8px;
        height: 3px;
        width: 48px;
        z-index: 4; }
        .banner-nav-container .banner-nav ol li.is-selected, .banner-nav-container .banner-nav ol li:hover {
          opacity: 1; }
        .banner-nav-container .banner-nav ol li:after {
          content: "";
          display: block;
          height: 15px;
          position: absolute;
          top: -6px;
          width: 100%;
          z-index: 4; }

.flickity-prev-next-buttons {
  display: none !important; }
  @media (min-width: 768px) {
    .flickity-prev-next-buttons {
      display: inline-block !important;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: 0;
      margin-top: auto; }
      .flickity-prev-next-buttons .bannerPrev, .flickity-prev-next-buttons .bannerNext {
        font-size: 25px;
        color: #fff;
        display: inline-block;
        opacity: 0.25; }
        .flickity-prev-next-buttons .bannerPrev:hover, .flickity-prev-next-buttons .bannerNext:hover {
          opacity: 1; }
      .flickity-prev-next-buttons .bannerNext {
        margin-left: 3rem; }
      .flickity-prev-next-buttons i {
        vertical-align: middle; } }

.banner__item {
  opacity: 0;
  visibility: hidden; }

.banner__item:first-child {
  opacity: 1;
  visibility: visible; }

.flickity-enabled {
  opacity: 1; }
  .flickity-enabled .banner__item {
    opacity: 1;
    visibility: visible; }

.btn {
  font-size: 18px;
  letter-spacing: 0.025em;
  border-color: transparent;
  max-width: 100%;
  text-transform: uppercase;
  white-space: nowrap; }
  .btn i {
    margin-left: 1rem; }

.btn-light {
  color: #1d612a; }

.btn-wide {
  padding-left: 4rem;
  padding-right: 4rem; }

.btn-transparent {
  background-color: transparent;
  border-color: transparent;
  color: #1d612a;
  font-weight: 600; }

.btn-primary:hover {
  background: #fff;
  border-color: #1d612a;
  color: #1d612a; }

.btn-primary:active {
  background: #16471f;
  border-color: #16471f;
  color: #16471f; }

.btn-link {
  font-size: 24px;
  font-weight: 600;
  text-transform: none; }

a.dropdown-toggle {
  -webkit-appearance: unset !important; }

.contact-content h1, .contact-content h2 {
  font-weight: 700; }

@media (min-width: 768px) and (max-width: 991.98px) {
  .contact-content {
    width: 50%; }
  .contact-ctas-wrapper {
    width: 50%; } }

.contact-enquiries-cta {
  background: #f2f2f2;
  color: #808080; }
  .contact-enquiries-cta p {
    font-size: 1.125rem;
    font-weight: 400; }
  .contact-enquiries-cta h4 {
    color: #808080;
    margin-bottom: 0.5rem !important; }
  .contact-enquiries-cta a {
    font-size: 1.5rem;
    color: #1d612a;
    font-weight: 600; }
    @media (max-width: 1200px) {
      .contact-enquiries-cta a {
        font-size: calc(1.275rem + 0.3vw) ; } }
    .contact-enquiries-cta a:hover {
      color: #9CDE9F; }

.contact-emergency-cta {
  background: #1d612a;
  color: #fff;
  position: relative;
  z-index: 1; }
  .contact-emergency-cta p {
    font-size: 18px;
    font-weight: 400; }
  .contact-emergency-cta h4 {
    color: #fff;
    margin-bottom: 0.5rem !important; }
  .contact-emergency-cta a {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600; }
    @media (max-width: 1200px) {
      .contact-emergency-cta a {
        font-size: calc(1.275rem + 0.3vw) ; } }
    .contact-emergency-cta a:hover {
      color: #9CDE9F; }
  .contact-emergency-cta:before {
    background-image: url("../img/emergency-callout-icon-lime.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    display: block;
    height: 8.25rem;
    opacity: 0.05;
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    width: 8.25rem;
    z-index: 2; }

.content a:not(.btn) {
  color: #1d612a;
  text-decoration: none; }
  .content a:not(.btn):hover {
    color: #808080; }

.content h1 {
  font-size: 4.625rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
  position: relative; }
  @media (max-width: 1200px) {
    .content h1 {
      font-size: calc(1.5875rem + 4.05vw) ; } }
  .content h1:before {
    background-color: #1d612a;
    content: "";
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 4.25rem; }

.content h2 {
  font-size: 3.375rem;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
  position: relative; }
  @media (max-width: 1200px) {
    .content h2 {
      font-size: calc(1.4625rem + 2.55vw) ; } }
  .content h2:before {
    background-color: #1d612a;
    content: "";
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 4.25rem; }

.content li, .content p {
  font-size: 18px;
  font-weight: 300; }

.content p {
  margin-bottom: 1.5rem; }

.content blockquote {
  display: block;
  padding-bottom: 4rem;
  padding-left: 64px !important;
  padding-right: 0 !important;
  padding-top: 4rem;
  position: relative;
  z-index: 1; }
  .content blockquote:before {
    background: #f3f7f4;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    bottom: 0;
    height: 100%;
    width: 150vw;
    z-index: 0; }
  .content blockquote > p:first-child {
    font-size: 2.875rem;
    color: #1d612a;
    font-family: fieldwork, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 3rem;
    position: relative;
    text-align: left;
    z-index: 1; }
    @media (max-width: 1200px) {
      .content blockquote > p:first-child {
        font-size: calc(1.4125rem + 1.95vw) ; } }
    .content blockquote > p:first-child:before {
      color: #1d612a;
      content: "\201C";
      display: block;
      font-size: 150px;
      font-weight: 300;
      left: -4.5rem;
      opacity: 0.5;
      position: absolute;
      top: 2.5rem;
      z-index: 1; }
    .content blockquote > p:first-child:after {
      bottom: 1.75rem;
      color: #1d612a;
      content: "\201D";
      display: inline-block;
      font-size: 150px;
      font-weight: 300;
      left: 1rem;
      line-height: 0;
      opacity: 0.5;
      position: relative;
      transform: rotate(180deg);
      z-index: 1; }
  .content blockquote > p:last-child {
    font-size: 18px;
    color: #1d612a;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 2rem;
    position: relative;
    z-index: 1; }
  .content blockquote strong, .content blockquote b {
    font-weight: 700; }
  .content blockquote ul {
    font-weight: 400;
    margin-bottom: 0; }
  @media (min-width: 1900px) {
    .content blockquote {
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.content .two-cols {
  column-count: 2;
  column-gap: 4em;
  column-width: 360px;
  margin-bottom: 2rem; }
  .content .two-cols > p:last-child {
    margin-bottom: 0; }

.content ul:not(.list-unstyled) {
  list-style: none;
  margin-bottom: 1.75rem;
  padding-left: 1em; }
  .content ul:not(.list-unstyled) li::before {
    content: "•";
    color: #1d612a;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    transform: scale(1.25); }

.content ol {
  counter-reset: li;
  /* Initiate a counter */
  margin-bottom: 1.75rem;
  margin-left: 0;
  padding-left: 1.25em; }

.content ol > li {
  display: block;
  float: none;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  text-indent: none; }

.content ol > li:before {
  box-sizing: border-box;
  color: #1d612a;
  content: counter(li, decimal) ". ";
  /* Use the counter as content */
  counter-increment: li;
  /* Increment the counter by 1 */
  /* Position and style the number */
  position: absolute;
  top: 0;
  left: -1.25em;
  /* Some space between the number and the content in browsers that support
            generated content but not positioning it (Camino 2 is one example) */
  line-height: 1.5;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 1em; }

.content ol > li > p {
  margin: 0; }

@media (max-width: 767.98px) {
  .content a {
    word-break: break-word; } }

.content img {
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 100%;
  height: auto; }

.content .alignright,
.content .alignleft,
.content .aligncenter,
.content .alignnone {
  margin: 2rem auto;
  max-width: 100%; }
  @media (max-width: 767.98px) {
    .content .alignright,
    .content .alignleft,
    .content .aligncenter,
    .content .alignnone {
      display: block;
      text-align: center; } }

@media (max-width: 991.98px) {
  .content .alignleft {
    clear: both; } }

@media (min-width: 768px) {
  .content .alignleft {
    float: left;
    margin: 1rem;
    margin-left: 0; } }

@media (max-width: 991.98px) {
  .content .alignright {
    clear: both; } }

@media (min-width: 768px) {
  .content .alignright {
    float: right;
    margin: 1rem;
    margin-right: 0; } }

@media (min-width: 768px) {
  .content .aligncenter {
    margin: 3rem auto;
    display: block; } }

@media (min-width: 768px) {
  .content .alignnone + img {
    margin-left: 1rem; } }

.content figure .wp-caption-text {
  font-size: 0.85rem;
  color: #1d612a; }

.content-block blockquote {
  margin-bottom: 1.5rem; }
  @media (min-width: 1900px) {
    .content-block blockquote {
      margin-bottom: 2rem; } }

.content-block blockquote > p {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
  padding-right: 0; }
  @media (min-width: 768px) {
    .content-block blockquote > p {
      font-size: 1.375rem; } }
  @media (min-width: 768px) and (max-width: 1200px) {
    .content-block blockquote > p {
      font-size: calc(1.2625rem + 0.15vw) ; } }
  @media (min-width: 1900px) {
    .content-block blockquote > p {
      font-size: 1.75rem; } }
  @media (min-width: 1900px) and (max-width: 1200px) {
    .content-block blockquote > p {
      font-size: calc(1.3rem + 0.6vw) ; } }

.content-block p {
  font-size: 1rem;
  letter-spacing: 0.01em;
  font-weight: 300; }

.feature-block-content p,
.green-caption-left-block p,
.left-image-right-text-block p,
.left-text-right-image-block p {
  font-size: 1.125rem;
  font-weight: 300; }

.two-col-img-right {
  height: auto;
  margin-bottom: 1rem;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 768px) {
    .two-col-img-right {
      margin-bottom: -30%;
      position: relative;
      z-index: 5; } }

.two-col-img-left {
  height: auto;
  max-width: 100%;
  width: 100%; }

.img-caption {
  font-size: 0.75rem; }

.accreditation-slides {
  position: relative;
  text-align: center; }
  .accreditation-slides .accreditation-slide {
    display: flex;
    margin: auto; }
  .accreditation-slides .accreditationsPrev,
  .accreditation-slides .accreditationsNext {
    font-size: 1.5625rem;
    color: #1d612a;
    font-weight: 400;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 1200px) {
      .accreditation-slides .accreditationsPrev,
      .accreditation-slides .accreditationsNext {
        font-size: calc(1.28125rem + 0.375vw) ; } }
  .accreditation-slides .accreditationsPrev {
    left: 0; }
  .accreditation-slides .accreditationsNext {
    right: 0; }
  .accreditation-slides .flickity-page-dots {
    left: 0;
    right: 0; }
    .accreditation-slides .flickity-page-dots .dot {
      background-color: #1d612a; }
  .accreditation-slides .flickity-slider {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .accreditation-slides .stretched-link {
    display: block;
    filter: grayscale(100%);
    transition: all 300ms;
    opacity: 0.5; }
    .accreditation-slides .stretched-link:hover {
      filter: grayscale(0%);
      opacity: 1; }

.core-value i.fas,
.core-value i.fal,
.core-value i.far {
  font-size: 100px;
  color: #1d612a;
  margin: 0 auto auto auto;
  opacity: 0.15; }

@media (min-width: 1200px) {
  .core-value h3 {
    margin-left: auto;
    margin-right: auto;
    width: 80%; } }

.core-value p {
  font-size: 18px;
  font-weight: 400; }

@media (min-width: 992px) {
  .page-template-about .core-values-block .row > div:nth-child(2) > .core-value {
    transform: translateY(80px); } }

.feature-block {
  color: #1d612a; }
  .feature-block .feature-block-image {
    position: relative;
    z-index: 2; }
  .feature-block .feature-block-content h2 {
    position: relative;
    z-index: 2; }
  .feature-block .feature-block-content p {
    font-size: 1rem;
    position: relative;
    z-index: 2; }
    @media (min-width: 992px) {
      .feature-block .feature-block-content p {
        font-size: 1.125rem; } }
  .feature-block .feature-block-content:before {
    background: #f3f7f4;
    bottom: 0;
    content: "";
    display: block;
    height: 105%;
    left: -20vw;
    position: absolute;
    top: 0;
    width: 150vw;
    z-index: 1; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .feature-block .feature-block-content:before {
        left: auto;
        right: 0;
        width: 120%; } }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .feature-block .feature-block-content:before {
        right: auto;
        left: -25%;
        width: 58vw; } }
    @media (min-width: 1200px) and (max-width: 1899.98px) {
      .feature-block .feature-block-content:before {
        left: -30%;
        width: 57vw; } }
    @media (min-width: 1900px) {
      .feature-block .feature-block-content:before {
        left: -33%;
        width: 55vw; } }

.feature-row {
  color: #1d612a;
  position: relative; }
  .feature-row:before {
    background: #f3f7f4;
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 0; }
  .feature-row .feature-row-image {
    position: relative;
    z-index: 5; }
    @media (min-width: 768px) {
      .feature-row .feature-row-image {
        position: absolute; } }
  .feature-row .feature-row-content h2 {
    font-size: 2.25rem; }
    @media (max-width: 1200px) {
      .feature-row .feature-row-content h2 {
        font-size: calc(1.35rem + 1.2vw) ; } }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .feature-row .feature-row-content h2 {
        font-size: 2.625rem; } }
  @media (min-width: 992px) and (max-width: 1199.98px) and (max-width: 1200px) {
    .feature-row .feature-row-content h2 {
      font-size: calc(1.3875rem + 1.65vw) ; } }
    @media (min-width: 1200px) and (max-width: 1899.98px) {
      .feature-row .feature-row-content h2 {
        font-size: 3rem; } }
  @media (min-width: 1200px) and (max-width: 1899.98px) and (max-width: 1200px) {
    .feature-row .feature-row-content h2 {
      font-size: calc(1.425rem + 2.1vw) ; } }
  .feature-row .feature-row-content p {
    font-size: 1rem;
    font-weight: 300; }
    @media (min-width: 992px) {
      .feature-row .feature-row-content p {
        font-size: 1.125rem; } }
  .feature-row .feature-row-content .btn {
    font-weight: 700;
    margin-left: 0;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    text-align: left; }

.caption-right-block {
  margin-top: 200px; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .caption-right-block {
      margin-top: 150px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .caption-right-block .drop-shadow-bottom-left {
      max-width: 100%; }
    .caption-right-block .drop-shadow {
      display: none !important; } }
  .caption-right-block .caption-right-row {
    position: relative; }
  .caption-right-block .caption-right-col {
    position: static; }
  .caption-right-block .caption-right-picture {
    display: block;
    position: relative; }
    @media (min-width: 768px) {
      .caption-right-block .caption-right-picture:before {
        background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0.6));
        bottom: 0;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.75;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 3; } }
    @media (min-width: 992px) {
      .caption-right-block .caption-right-picture:before {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white); } }
  .caption-right-block .caption-right-content {
    position: relative;
    z-index: 4; }
    @media (min-width: 768px) {
      .caption-right-block .caption-right-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 2.5rem;
        position: absolute;
        right: 0;
        top: -1rem;
        width: 100%; }
        .caption-right-block .caption-right-content a {
          align-self: start;
          flex-shrink: 1; }
        .caption-right-block .caption-right-content.justify-content-start {
          padding-top: 2rem; }
        .caption-right-block .caption-right-content.justify-content-end {
          padding-bottom: 2rem; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .caption-right-block .caption-right-content {
        padding: 0 5rem; }
        .caption-right-block .caption-right-content .title-accent-left {
          text-align: center !important; }
          .caption-right-block .caption-right-content .title-accent-left::before {
            right: 0;
            margin: 0 auto; }
        .caption-right-block .caption-right-content .btn {
          margin: 0 auto; } }
    @media (min-width: 992px) {
      .caption-right-block .caption-right-content {
        bottom: 0;
        right: 5%;
        top: 0;
        width: 36.6%; }
        .caption-right-block .caption-right-content.justify-content-start {
          padding-top: 5rem; }
        .caption-right-block .caption-right-content.justify-content-end {
          padding-bottom: 5rem; }
        .caption-right-block .caption-right-content .h1 {
          font-size: 2.6rem; } }
    @media (min-width: 1200px) {
      .caption-right-block .caption-right-content {
        width: 36.7%; }
        .caption-right-block .caption-right-content .h1 {
          font-size: 2.8rem; } }

.hero-block {
  height: 560px;
  overflow: hidden;
  position: relative; }
  @media (min-width: 992px) {
    .hero-block {
      padding-bottom: 49.375%; } }
  .hero-block .hero-block-content {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 0; }
    @media (min-width: 992px) {
      .hero-block .hero-block-content {
        padding-bottom: 49.375%;
        height: 0; } }
    .hero-block .hero-block-content:before {
      background: #f3f7f4;
      bottom: 0;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      opacity: 0.8;
      position: absolute;
      right: 0;
      top: 0;
      width: 100vw;
      z-index: 1; }
  .hero-block .hero-block-inner {
    font-size: 18px;
    bottom: 0;
    color: #1d612a;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2; }
    .hero-block .hero-block-inner p {
      font-size: inherit; }

.left-text-right-image-block img {
  display: block;
  position: relative;
  z-index: 2; }
  @media (min-width: 1900px) {
    .left-text-right-image-block img {
      transform: translateX(20%); } }

.left-image-right-text-block img {
  display: block; }
  @media (min-width: 768px) {
    .left-image-right-text-block img {
      transform: translateX(-5%); } }
  @media (min-width: 992px) {
    .left-image-right-text-block img {
      transform: translateX(-5%); } }
  @media (min-width: 1200px) {
    .left-image-right-text-block img {
      transform: translateX(-10%); } }

.map-block {
  color: #1d612a;
  position: relative;
  overflow: hidden; }
  .map-block:before {
    background: #f3f7f4;
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 0; }
  .map-block .map-block-content p {
    font-size: 1rem;
    font-weight: 300; }
    @media (min-width: 992px) {
      .map-block .map-block-content p {
        font-size: 1.125rem; } }
  .map-block .map-block-content .btn {
    font-weight: 700;
    margin-left: 0;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    text-align: left; }

#location-map {
  margin-top: 0;
  margin-bottom: 80px;
  width: 100%;
  height: auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }
  @media (min-width: 768px) {
    #location-map {
      margin-top: -130px; } }

@media (min-width: 1200px) {
  .home .services-blocks .col-md-4:nth-child(2) {
    transform: translateY(84px); } }

.services-blocks .service-block {
  opacity: 0;
  transform: translateY(250px);
  animation-duration: 600ms; }

.services-blocks .service-block-content {
  padding-bottom: 104.34783%;
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: background 300ms; }
  .services-blocks .service-block-content:after {
    background-color: rgba(29, 97, 42, 0.75);
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 300ms cubic-bezier(0, 0, 0.2, 1);
    width: 100%;
    z-index: 2; }
  .services-blocks .service-block-content:hover {
    background: #1d612a; }
    .services-blocks .service-block-content:hover:after {
      opacity: 0; }
    .services-blocks .service-block-content:hover > h3 {
      transform: translate(-50%, 0%);
      top: 5%;
      opacity: 0; }
      @media (min-width: 1200px) {
        .services-blocks .service-block-content:hover > h3 {
          top: 20%; } }
    .services-blocks .service-block-content:hover .service-block-inner {
      opacity: 1; }
  .services-blocks .service-block-content > h3 {
    color: #fff;
    display: block;
    font-weight: 700;
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 400ms cubic-bezier(0, 0, 0.2, 1);
    z-index: 3; }
  .services-blocks .service-block-content img {
    display: block;
    filter: grayscale(100%);
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    margin: 0;
    transition: opacity 300ms cubic-bezier(0, 0, 0.2, 1);
    opacity: 1;
    width: 100%;
    z-index: -1; }
  .services-blocks .service-block-content .service-block-inner {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    position: relative;
    transition: opacity 800ms cubic-bezier(0, 0, 0.2, 1);
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    margin: 0;
    bottom: 0;
    right: 0;
    width: 100%; }
    .services-blocks .service-block-content .service-block-inner p {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.5;
      margin-top: auto;
      margin-bottom: auto;
      padding-top: 20%; }
      @media (min-width: 576px) and (max-width: 767.98px) {
        .services-blocks .service-block-content .service-block-inner p {
          font-size: 1.5rem; } }
  @media (min-width: 576px) and (max-width: 767.98px) and (max-width: 1200px) {
    .services-blocks .service-block-content .service-block-inner p {
      font-size: calc(1.275rem + 0.3vw) ; } }
      @media (min-width: 768px) and (max-width: 991.98px) {
        .services-blocks .service-block-content .service-block-inner p {
          font-size: 1rem;
          line-height: 1.4;
          padding-top: 5%; } }
      @media (min-width: 1200px) {
        .services-blocks .service-block-content .service-block-inner p {
          font-size: 1.5rem; } }
  @media (min-width: 1200px) and (max-width: 1200px) {
    .services-blocks .service-block-content .service-block-inner p {
      font-size: calc(1.275rem + 0.3vw) ; } }
    .services-blocks .service-block-content .service-block-inner a {
      border: 0; }
    .services-blocks .service-block-content .service-block-inner .btn.btn-light:hover {
      color: #FFFFFF;
      background-color: transparent;
      box-shadow: inset 0 0 0 5px #fff; }

.testimonials-block {
  position: relative; }
  @media (min-width: 992px) {
    .testimonials-block > .container {
      position: relative; } }
  .testimonials-block > .container:after {
    background: #1d612a;
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    transform: translate3d(100%, 0, 0);
    transition: transform 500ms cubic-bezier(0, 0, 0.2, 1);
    width: 100vw;
    will-change: transform;
    z-index: 0; }
    @media (min-width: 992px) {
      .testimonials-block > .container:after {
        height: 90%; } }
    @media (min-width: 1200px) {
      .testimonials-block > .container:after {
        height: 80%; } }
  .testimonials-block .col-lg-6.d-lg-inline-block {
    align-self: flex-end; }
  .testimonials-block.is-visible > .container:after,
  .testimonials-block.is-visible .testimonial-image {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    will-change: unset; }

.testimonial-image-wrapper {
  display: block; }
  .testimonial-image-wrapper .testimonial-image {
    display: none; }
    @media (min-width: 992px) {
      .testimonial-image-wrapper .testimonial-image {
        display: block;
        height: auto;
        object-fit: cover;
        opacity: 1;
        position: relative;
        margin-left: -4rem;
        margin-top: 6rem;
        max-width: 100%;
        transform: translate3d(-100%, 0, 0);
        transition: transform 500ms cubic-bezier(0, 0, 0.2, 1);
        width: 100%;
        z-index: 1; } }
    @media (min-width: 1200px) {
      .testimonial-image-wrapper .testimonial-image {
        margin-left: -12rem; } }

.testimonial-block-content {
  color: #fff; }
  .testimonial-block-content h2 {
    font-size: 2.875rem;
    color: #fff;
    display: block;
    position: relative;
    z-index: 1; }
    @media (max-width: 1200px) {
      .testimonial-block-content h2 {
        font-size: calc(1.4125rem + 1.95vw) ; } }
    .testimonial-block-content h2 .quote-left,
    .testimonial-block-content h2 .quote-right {
      background-image: url("../img/speechmark.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      display: block;
      height: 32px;
      position: absolute;
      width: 32px;
      z-index: 1; }
      @media (min-width: 1200px) {
        .testimonial-block-content h2 .quote-left,
        .testimonial-block-content h2 .quote-right {
          height: 48px;
          width: 48px; } }
    .testimonial-block-content h2 .quote-left {
      left: -2.25rem;
      top: 0; }
      @media (min-width: 1200px) {
        .testimonial-block-content h2 .quote-left {
          left: -4rem;
          top: -0.25rem; } }
    .testimonial-block-content h2 .quote-right {
      display: inline-block;
      position: absolute;
      left: none;
      margin-left: 0.5rem;
      margin-top: 0.5rem;
      top: none; }
      @media (min-width: 1200px) {
        .testimonial-block-content h2 .quote-right {
          margin-left: 1rem; } }
  .testimonial-block-content p {
    font-size: 1.125rem;
    font-weight: 600;
    position: relative;
    z-index: 1; }
    @media (min-width: 768px) {
      .testimonial-block-content p {
        padding-bottom: 90px; } }

.contact-cta {
  background: transparent;
  border: 4px solid #808080;
  position: relative; }
  .contact-cta h3 {
    position: relative;
    z-index: 5; }
  .contact-cta p {
    font-size: 1.5rem;
    font-weight: 600;
    position: relative;
    z-index: 5; }
    @media (max-width: 1200px) {
      .contact-cta p {
        font-size: calc(1.275rem + 0.3vw) ; } }

@media (min-width: 992px) {
  .page-template-about .contact-cta {
    position: relative;
    top: -3rem;
    z-index: 5; } }

@media (min-width: 1200px) {
  .page-template-about .contact-cta {
    position: relative;
    top: -6rem;
    z-index: 5; } }

.drop-shadow-bottom-left,
.drop-shadow-bottom-right,
.drop-shadow-top-left,
.drop-shadow-top-right {
  display: block;
  flex: 0;
  margin: auto;
  position: relative;
  max-width: 80%;
  text-align: center; }
  @media (min-width: 768px) {
    .drop-shadow-bottom-left,
    .drop-shadow-bottom-right,
    .drop-shadow-top-left,
    .drop-shadow-top-right {
      max-width: 80%; } }
  .drop-shadow-bottom-left .drop-shadow,
  .drop-shadow-bottom-right .drop-shadow,
  .drop-shadow-top-left .drop-shadow,
  .drop-shadow-top-right .drop-shadow {
    background-size: 100%;
    content: "";
    display: block;
    flex: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1; }
    .drop-shadow-bottom-left .drop-shadow.primary,
    .drop-shadow-bottom-right .drop-shadow.primary,
    .drop-shadow-top-left .drop-shadow.primary,
    .drop-shadow-top-right .drop-shadow.primary {
      background-color: #1d612a; }
    .drop-shadow-bottom-left .drop-shadow.secondary,
    .drop-shadow-bottom-right .drop-shadow.secondary,
    .drop-shadow-top-left .drop-shadow.secondary,
    .drop-shadow-top-right .drop-shadow.secondary {
      background-color: #808080; }
  .drop-shadow-bottom-left img,
  .drop-shadow-bottom-right img,
  .drop-shadow-top-left img,
  .drop-shadow-top-right img {
    display: inline-block;
    flex: 0;
    height: auto;
    margin: auto;
    max-width: 100%;
    position: relative;
    width: 100%;
    z-index: 2; }

.drop-shadow-bottom-left .drop-shadow {
  transform: translate(-10%, 10%); }
  @media (min-width: 768px) {
    .drop-shadow-bottom-left .drop-shadow {
      transform: translate(-12%, 12%); } }
  @media (min-width: 1200px) {
    .drop-shadow-bottom-left .drop-shadow {
      transform: translate(-15%, 15%); } }

.drop-shadow-bottom-right .drop-shadow {
  bottom: 0;
  right: 0;
  transform: translate(10%, 10%); }
  @media (min-width: 768px) {
    .drop-shadow-bottom-right .drop-shadow {
      transform: translate(12%, 12%); } }
  @media (min-width: 1200px) {
    .drop-shadow-bottom-right .drop-shadow {
      transform: translate(15%, 15%); } }

.drop-shadow-top-left .drop-shadow {
  top: 0;
  left: 0;
  transform: translate(-10%, -10%); }
  @media (min-width: 768px) {
    .drop-shadow-top-left .drop-shadow {
      transform: translate(-12%, -12%); } }
  @media (min-width: 1200px) {
    .drop-shadow-top-left .drop-shadow {
      transform: translate(-15%, -15%); } }

.drop-shadow-top-right .drop-shadow {
  top: 0;
  right: 0;
  transform: translate(10%, -10%); }
  @media (min-width: 768px) {
    .drop-shadow-top-right .drop-shadow {
      transform: translate(12%, -12%); } }
  @media (min-width: 1200px) {
    .drop-shadow-top-right .drop-shadow {
      transform: translate(15%, -15%); } }

.emergency-callout {
  color: #fff;
  margin-bottom: 0.5rem;
  margin-top: 1rem; }
  .emergency-callout img {
    height: 48px;
    margin-right: 10px;
    width: 48px; }
  .emergency-callout span {
    letter-spacing: 0.2px; }
  .emergency-callout a {
    font-size: 26px;
    color: #fff;
    letter-spacing: 2.1px;
    line-height: normal;
    transform: translateY(2px); }

.footer {
  background: rgba(128, 128, 128, 0.75);
  color: #fff; }
  @media (max-width: 767.98px) {
    .footer .logo {
      max-width: 85%; } }
  .footer .logo {
    width: 260px; }
  .footer .footer-email {
    color: #fff;
    font-weight: 600; }
    .footer .footer-email:hover {
      color: #1d612a; }
  .footer .emergency-callout {
    color: #fff; }
    .footer .emergency-callout a {
      color: #fff !important; }
  .footer .nav-item {
    color: #fff;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem; }
    .footer .nav-item .nav-link {
      font-size: 1rem;
      color: inherit;
      display: block;
      font-family: fieldwork, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 300;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      text-decoration: none; }
      .footer .nav-item .nav-link:hover {
        color: #1d612a; }

.footer-social-link {
  color: #585858;
  margin-bottom: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: auto; }
  .footer-social-link:hover .fa-lg {
    color: #fff; }

.form-col-bg {
  position: relative;
  z-index: 0; }
  .form-col-bg:before {
    background-color: #f3f7f4;
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    left: -30px;
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 0; }
    @media (min-width: 992px) {
      .form-col-bg:before {
        left: 0;
        right: unset;
        width: 66.66667vw; } }

.custom-control-label {
  color: #808080;
  padding-left: 0.5rem; }

.custom-select {
  cursor: pointer; }

.is-error .custom-control-label {
  background: #fff;
  padding: 0.5rem; }

.is-error input, .is-error textarea, .is-error select {
  border: 2px solid #ff424e !important;
  color: #ff424e !important;
  outline: 1px solid #ff424e !important; }

.is-error input[type="checkbox"] + label:before, .is-error input[type="radio"] + label:before {
  border-color: #ff424e; }

.is-error .input-message {
  font-size: 0.875rem;
  color: #ff424e;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  position: relative;
  visibility: visible;
  width: 100%;
  z-index: 1; }

.alert--danger {
  background: #fff;
  border: 3px solid #ff424e;
  color: #808080; }

.alert--success {
  background: #fff;
  border: 3px solid #9cde9f;
  color: #808080; }

.submit-btn.waiting {
  display: none; }

.gp_form.waiting .submit-btn {
  display: none; }

.gp_form.waiting .spinner,
.gp_form.waiting .spinner-border {
  display: inline-block; }

label small {
  display: inline-block;
  margin-top: 0.5rem; }

.city-town-row {
  display: none; }

/*
https://tympanus.net/codrops/2013/07/30/google-nexus-website-menu/
*/
.gn-menu-main {
  display: block;
  flex-basis: 0%;
  flex-grow: 0;
  flex-shrink: 0;
  height: 60px;
  margin-bottom: auto;
  margin-top: 0;
  position: fixed;
  width: 100%;
  z-index: 1030; }
  @media (min-width: 1200px) {
    .gn-menu-main {
      display: none !important; } }

.gn-menu-main .logo {
  height: 50px;
  margin-bottom: auto;
  margin-top: auto;
  width: auto; }
  @media (min-width: 576px) {
    .gn-menu-main .logo {
      height: 60px; } }

.gn-menu-main,
.gn-menu-main ul {
  margin: 0;
  padding: 0;
  background: #1d612a;
  list-style: none;
  text-transform: none;
  line-height: 60px; }

.gn-menu-main ul {
  background: #1d612a; }

.gn-menu-main a {
  cursor: pointer;
  display: flex;
  height: 100%;
  text-decoration: none;
  user-select: none;
  width: 100%;
  z-index: 1030; }

/*
.gn-menu-main a:hover,
.gn-menu li.gn-search-item:hover,
.gn-menu li.gn-search-item:hover a {
    background: #5f6f81;
    color: white;
}
*/
.gn-menu-main > li:first-child {
  margin-left: 0;
  margin-right: auto;
  text-align: left; }

.gn-menu-main > li {
  border-right: none;
  height: 100%;
  text-align: right; }

.gn-menu-main li.gn-search {
  font-weight: 400;
  margin-left: auto; }

.gn-menu-main li.gn-search,
.gn-menu-main li.gn-trigger {
  overflow: hidden;
  position: relative;
  width: 60px;
  user-select: none; }

/*
.gn-menu-main > li > a {
    padding: 0 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}
*/
.gn-menu-main:after {
  display: table;
  clear: both;
  content: ''; }

.gn-menu-wrapper {
  background: #1d612a;
  bottom: 0;
  height: 100vh;
  left: 0;
  overflow: hidden;
  padding-top: 60px;
  position: fixed;
  top: 0;
  transform: translateX(-60px);
  /* will be transitioned to 0px */
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1), width 250ms cubic-bezier(0, 0, 0.2, 1);
  width: 50px;
  /* will be transitioned to 340px */
  z-index: 1020; }
  @media (min-width: 576px) {
    .gn-menu-wrapper {
      padding-top: 70px; } }
  .gn-menu-wrapper hr {
    background-color: rgba(255, 255, 255, 0.3); }
  .gn-menu-wrapper div.d-flex {
    position: relative; }
  .gn-menu-wrapper .gmenu-cta {
    font-weight: 400;
    line-height: 1.4; }
    .gn-menu-wrapper .gmenu-cta img {
      width: 48px; }
    .gn-menu-wrapper .gmenu-cta a {
      font-size: 1.5rem;
      height: auto; }
      @media (max-width: 1200px) {
        .gn-menu-wrapper .gmenu-cta a {
          font-size: calc(1.275rem + 0.3vw) ; } }

.gn-menu-wrapper.gn-open-all,
.gn-menu-wrapper.gn-open-part {
  transform: translateX(0px); }

.gn-menu-wrapper.gn-open-all {
  width: 370px; }

@media (max-width: 767.98px) {
  /*@media screen and (max-width: 422px) { */
  .gn-menu-wrapper.gn-open-all {
    transform: translateX(0px); } }

.gn-scroller {
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 370px;
  height: 100vh;
  max-width: 100vw; }

.gn-menu {
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%; }
  .gn-menu .submenu-btn {
    position: absolute;
    right: 0;
    background: none;
    border: 0;
    color: #FFFFFF;
    height: 100%;
    margin: 0;
    text-align: center;
    width: 48px;
    line-height: normal;
    z-index: 1031; }
    .gn-menu .submenu-btn[aria-expanded=true] {
      color: #1d612a; }
    .gn-menu .submenu-btn i {
      user-select: none;
      pointer-events: none; }
  .gn-menu .submenu-list {
    background-color: #FFFFFF; }
  .gn-menu .submenu-link {
    font-size: 1.25rem;
    display: block;
    line-height: 40px;
    padding: 0.4rem 2rem 0.4rem 2rem;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    @media (min-width: 768px) {
      .gn-menu .submenu-link {
        text-align: left; } }
    .gn-menu .submenu-link:hover {
      background: #e6e6e6;
      color: #1d612a; }

.gn-menu-main .gn-menu .gn-menu-link {
  font-size: 1.5rem;
  color: #fff;
  display: block;
  font-family: fieldwork, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  line-height: 48px;
  padding: 0 2rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media (max-width: 1200px) {
    .gn-menu-main .gn-menu .gn-menu-link {
      font-size: calc(1.275rem + 0.3vw) ; } }
  @media (min-width: 768px) {
    .gn-menu-main .gn-menu .gn-menu-link {
      text-align: left; } }
  .gn-menu-main .gn-menu .gn-menu-link:hover, .gn-menu-main .gn-menu .gn-menu-link.active {
    background: #fff;
    color: #1d612a; }

.gn-icon {
  display: block;
  height: 100%;
  line-height: normal;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%; }
  .gn-icon i {
    line-height: inherit;
    margin: auto; }

.burger {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 1030; }
  .burger span {
    background: #fff;
    display: block;
    height: 4px;
    left: 15px;
    margin-bottom: 5px;
    margin-top: 5px;
    position: relative;
    top: 15px;
    transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
    width: 30px;
    z-index: 1030; }
    .burger span:nth-child(1) {
      transition-delay: 350ms; }
    .burger span:nth-child(2) {
      transition-delay: 400ms; }
    .burger span:nth-child(3) {
      transition-delay: 450ms; }

.cross {
  height: 48px;
  right: 6px;
  position: absolute;
  top: 6px;
  width: 48px;
  transform: rotate(45deg);
  z-index: 1030; }
  .cross:before, .cross:after {
    background-color: #fff;
    content: '';
    position: absolute;
    transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
    z-index: 1030; }
  .cross:before {
    height: 0;
    left: 22px;
    top: 8px;
    transition-delay: 0;
    width: 4px; }
  .cross:after {
    height: 4px;
    left: 8px;
    top: 22px;
    transition-delay: 150ms;
    width: 0; }

.gn-selected .burger span {
  width: 0; }
  .gn-selected .burger span:nth-child(1) {
    transition-delay: 0; }
  .gn-selected .burger span:nth-child(2) {
    transition-delay: 100ms; }
  .gn-selected .burger span:nth-child(3) {
    transition-delay: 300ms; }

.gn-selected .cross:before {
  height: 32px;
  transition-delay: 600ms; }

.gn-selected .cross:after {
  width: 32px;
  transition-delay: 750ms; }

.header {
  background: #1d612a;
  position: relative;
  transform: translate3d(0, -100%, 0);
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
  width: 100%; }
  .header.float {
    position: fixed;
    z-index: 1030; }
  .header.show {
    transform: translate3d(0, 0, 0); }
  .header .logo {
    width: 260px; }
  .header .nav-item {
    color: #fff;
    position: relative;
    transition: background-color 300ms cubic-bezier(0, 0, 0.2, 1), color 30ms cubic-bezier(0, 0, 0.2, 1); }
    .header .nav-item:hover {
      background: #fff;
      color: #1d612a; }
      .header .nav-item:hover .dropdown-menu {
        display: block; }
    .header .nav-item .nav-link {
      font-size: 16px;
      color: inherit;
      display: inline-block;
      font-family: fieldwork, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 600;
      line-height: 48px;
      text-decoration: none; }
    .header .nav-item .dropdown-menu {
      left: -1px !important;
      margin-top: -1px;
      top: unset !important;
      transform: unset !important; }
    .header .nav-item .dropdown > .nav-link {
      padding-right: 0; }
    .header .nav-item .dropdown-toggle:after {
      display: inline-block;
      font-family: 'Font Awesome 5 Pro';
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      border: 0;
      content: "\f107";
      font-size: 20px;
      font-weight: 400;
      vertical-align: middle; }

img.object-fit-cover {
  object-fit: cover;
  font-family: 'object-fit: cover;'; }

@media (min-width: 1200px) {
  .page-template-services .contact-cta,
  .page-template-services .services-drop-shadow-image {
    transform: translateX(5%); } }

@media (min-width: 1900px) {
  .page-template-services .contact-cta,
  .page-template-services .services-drop-shadow-image {
    transform: translateX(25%); } }

@media (max-width: 991.98px) {
  .page-template-services .services-drop-shadow-image.drop-shadow-top-right,
  .page-template-services .services-drop-shadow-image.drop-shadow-top-left {
    max-width: 50%; } }

.page-template-services .card {
  padding: 2rem; }

.page-template-services .card-header {
  font-weight: 600;
  line-height: 22px;
  padding: 0; }
  .page-template-services .card-header i {
    font-size: 20px;
    transform: rotate(180deg); }
  .page-template-services .card-header .collapsed > i {
    transform: rotate(0deg); }

.page-template-services .card-body {
  font-size: 1.125rem;
  color: #808080;
  font-weight: 400;
  line-height: 22px;
  padding: 0; }
  .page-template-services .card-body li {
    padding-bottom: 0.75rem;
    padding-top: 0.75rem; }
    .page-template-services .card-body li:first-child {
      padding-top: 0; }
    .page-template-services .card-body li:last-child {
      padding-bottom: 0; }

.spinner-border {
  display: none;
  height: 4rem;
  margin: auto 0;
  width: 4rem; }

table {
  margin-top: 1rem;
  margin-bottom: 2rem;
  max-width: 100%;
  overflow: auto; }
  table a {
    word-break: break-word; }
  @media (max-width: 767.98px) {
    table {
      display: block; }
      table td, table th {
        padding: 0.25rem; } }

th {
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f7f4;
  color: #1d612a;
  font-family: fieldwork, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1;
  padding: 0.5rem; }

td {
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #808080;
  font-weight: 300;
  padding: 0.5rem; }

tr:nth-child(even) td {
  background-color: #f3f7f4; }

html {
  height: 100%;
  overflow-x: hidden;
  max-width: 100vw; }

body {
  background: #fff;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow-x: hidden;
  top: 0 !important; }

.head-space {
  padding-top: 76px; }
  @media (min-width: 992px) {
    .head-space {
      padding-top: 108px; } }
  @media (min-width: 1200px) {
    .head-space {
      padding-top: 138px; } }

.content-wrapper {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  max-width: 100vw; }

.no-scroll {
  overflow: hidden !important;
  overflow-x: none !important; }

.no-focus-outline a:focus,
.no-focus-outline button:focus {
  outline: none; }
