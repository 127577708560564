// Material Design
// https://material.io/design/motion/speed.html#easing
$material-standard:   cubic-bezier(0.4, 0.0, 0.2, 1);
$material-decelerate: cubic-bezier(0.0, 0.0, 0.2, 1);
$material-accelerate: cubic-bezier(0.4, 0.0,   1, 1);

// Robert Penner
// https://matthewlein.com/tools/ceaser
$penner-ease-out-expo:  cubic-bezier(0.190, 1.000, 0.220, 1.000);
$penner-ease-out-quad:  cubic-bezier(0.250, 0.460, 0.450, 0.940);
$penner-ease-out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$penner-ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
