$banner-aspect-ratio-x: 320;
$banner-aspect-ratio-y: 117;

.banner {
    height: 82vh;
    margin-top: $mobile-menu-height;
    position: relative;
    opacity: 1;
    overflow: hidden;
    //transition: opacity 300ms $material-decelerate;
    width: 100%;
    z-index: 1;

    @include media-breakpoint-only(sm) {
      height: 50vh;
    }

    @include media-breakpoint-only(md) {
      height: 58vh;
    }

    @include media-breakpoint-up(xl) {
        @include responsive-ratio($banner-aspect-ratio-x, $banner-aspect-ratio-y);
        height: 0;
        margin-top: $header-height;
        // margin-top: 0; // if no fixed sticky nav
        // margin-top: $main-nav-height; // if there is fixed sticky nav
    }

    .banner__item {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        height: 100%;
        overflow: hidden;
        transition: opacity 100ms $material-decelerate;
        width: 100%;

        @include media-breakpoint-up(xl) {
            @include responsive-ratio($banner-aspect-ratio-x, $banner-aspect-ratio-y);
            height: 0;
        }

        &.dark-overlay {
          &:before {
              background-color: rgba(#171717, 0.45);
              bottom: 0;
              content: "";
              display: block;
              height: 100%;
              left: 0;
              //opacity: 0.29;
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;
              z-index: 1;
          }
        }
    }
}


.banner-inner {
    @extend %flex;
    @extend %flex-center;
    @extend %flex-column;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 150ms $material-decelerate;
    width: 100%;
    z-index: 2;
}


.home {
    .banner_title {
        @include font-size(54px);
    }

    .banner_caption {
        @include font-size(20px);
    }
}

.banner_title {
    @include font-size(74px);
    color: $white;
    margin-top: auto;
    margin-bottom: 2rem;
    //font-family: "ITCAirstreamW01-Regular";
    //line-height: (50px / $font-size-base) * 1rem;
    //text-transform: uppercase;

    // @include media-breakpoint-up(md) {
    //     @include font-size(72px);
    // }

    // @include media-breakpoint-up(lg) {
    //     @include static-font-size(70px);
    // }

    // @include media-breakpoint-up(fhd) {
    //     //@include font-size(116px);
    //     margin-right: 6rem;
    //     margin-left: 6rem;
    // }
}

.banner_caption {
    // max-width: 750px;
    @include font-size(24px);
    color: $white;
    font-weight: 400;
    line-height: (34px / $font-size-base) * 1rem;
    margin-bottom: 3rem;
}

.light-green,
.light-grey {
  .banner_title,
  .banner_caption {
    color: $primary;
  }
}


.banner-nav-container {
    position: relative;
    top: -3rem;
    z-index: 2;

    @include media-breakpoint-up(xl) {
      top: -4rem;
    }

    .banner-nav {
        align-items: center;
        display: flex;
        position: relative;
        z-index: 3;

        ol {
            margin: 0;

            li {
                background: #fff;
                border-radius: 0;
                cursor: pointer;
                display: inline-block;
                opacity: 0.25;
                position: relative;
                margin: 0 8px;
                height: 3px;
                width: 48px;
                z-index: 4;

                &.is-selected, &:hover {
                    opacity: 1;
                }

                &:after {
                    content: "";
                    display: block;
                    height: 15px;
                    //outline: 1px solid #f00;
                    position: absolute;
                    top: -6px;
                    width: 100%;
                    z-index: 4;
                }
            }
        }
    }
}


.flickity-prev-next-buttons {
  display: none !important;

  @include media-breakpoint-up(md) {
    display: inline-block !important;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 0;
    margin-top: auto;

    .bannerPrev, .bannerNext {
        @include static-font-size(25px);
        color: #fff;
        display: inline-block;
        opacity: 0.25;

        &:hover {
            opacity: 1;
        }
    }

    .bannerNext {
        margin-left: 3rem;
    }

    i {
        vertical-align: middle;
    }
  }
}


.banner__item {
    opacity: 0;
    visibility: hidden;
}

.banner__item:first-child {
    opacity: 1;
    visibility: visible;
}

.flickity-enabled {
  opacity: 1;

  .banner__item {
        opacity: 1;
        visibility: visible;
    }
}
