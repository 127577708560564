/* custom bootstrap variables */

$body-bg: #fff;
$body-color: $grey;

// native fonts
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: fieldwork, $font-family-sans-serif;

$enable-responsive-font-sizes: true;
$enable-rounded: false;
$enable-gradients: false;
$enable-shadows: false;
$enable-transitions: true;
$enable-grid-classes: true;
$enable-print-styles: true;
$enable-validation-icons: true;

$theme-colors: (
  "primary":   $primary,
  "secondary": $secondary,
  "ternary":   $ternary
  // "quaternary": $quaternary,
  // "quintile": $quintile,
  //"danger": $primary
);

//$link-color:            #fff;
$link-hover-color:      theme-color("primary");
$link-hover-decoration: none;

$line-height-base: 1.5; // 24px

$font-size-base: 1rem;

$h1-font-size:                $font-size-base * 3.375; // 54px
$h2-font-size:                $font-size-base * 2.875; // 46px
$h3-font-size:                $font-size-base * 2.375; // 38px
$h4-font-size:                $font-size-base * 1.5;   // 24px
$h5-font-size:                $font-size-base * 1.125; // 18px
$h6-font-size:                $font-size-base * 1;     // 16px

//$headings-margin-bottom:      2rem;
$headings-font-family:        fieldwork, $font-family-sans-serif;
$headings-font-weight:        600;
$headings-line-height:        1.2;
$headings-color:              $primary;

$border-color: rgba(#fff, 0.15);

$component-active-color:      #fff;
$component-active-bg:         #16471f;

// Cards

$card-spacer-y:                     1rem;
$card-spacer-x:                     1rem;
$card-border-width:                 0;
$card-border-radius:                0;
$card-border-color:                 $primary;
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg:                       $off-white;
$card-cap-color:                    null;
$card-color:                        $grey;
$card-bg:                           $off-white;

$card-img-overlay-padding:          1.25rem;

$card-group-margin:                 30px / 2;
$card-deck-margin:                  $card-group-margin;

$card-columns-count:                3;
$card-columns-gap:                  1.25rem;
$card-columns-margin:               $card-spacer-y;


$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
  7: ($spacer * 4),
  8: ($spacer * 5),
  9: ($spacer * 6),
 10: ($spacer * 7),
 11: ($spacer * 8),
 12: ($spacer * 9),
 13: ($spacer * 10),
 14: ($spacer * 11),
 15: ($spacer * 12),
), $spacers);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  fhd: 1900px,
  qhd: 2540px
  //uhd: 3840px
);

$container-max-widths: (
  sm: 540px,
  md: 726px, // 720
  lg: 960px,
  xl: 1140px,
  fhd: 1330px,
  qhd: 1600px // 2000px
  //uhd: 3000px
);

$grid-columns: 12;
$grid-gutter-width: 30px;

// Buttons
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

$btn-border-width: 4px;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 2rem;
$btn-font-size-lg: 18px;
$btn-font-weight:   700;

// $btn-line-height-lg:          $input-btn-line-height-lg;


// Navs

$nav-link-padding-y: .5rem;
$nav-link-padding-x: 1rem;

// Forms
$input-padding-y:     1rem;
$input-padding-x:     1rem;
$input-font-size:     18px;
$input-font-weight:   600;

$input-color:         $primary;
$input-border-color:  $primary;
$input-border-width:  2px;

$input-placeholder-color: rgba($primary, 0.75);

$custom-control-indicator-border-color:  $primary;
$custom-select-indicator-color: $primary;

// Dropdowns
$dropdown-padding-y: 0;
$dropdown-item-padding-y: 0.5rem;
$dropdown-link-color: $primary;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: #e6e6e6;
