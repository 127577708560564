// https://github.com/bfred-it/object-fit-images

img.object-fit-cover {
  object-fit: cover;
  font-family: 'object-fit: cover;'; // hack for IE
}

// .object-fit-contain {
//   object-fit: contain;
//   font-family: 'object-fit: contain;'; // hack for IE
// }

// Or if you also need object-position
// .your-favorite-image {
// 	  object-fit: cover;
// 	  object-position: bottom;
// 	  font-family: 'object-fit: cover; object-position: bottom;';
// }
