.page-template-services {

  // .services-drop-shadow-image {
  //   position: relative;
  // }

    .contact-cta,
    .services-drop-shadow-image {
      @include media-breakpoint-up(xl) {
        transform: translateX(5%);
      }

      @include media-breakpoint-up(fhd) {
        transform: translateX(25%);
      }
    }

    // @include media-breakpoint-down(lg) {
    //   .services-drop-shadow-image > img {
    //     max-width: 80%;
    //   }
    // }

    .services-drop-shadow-image.drop-shadow-top-right,
    .services-drop-shadow-image.drop-shadow-top-left {
          @include media-breakpoint-down(md) {
            max-width: 50%;
        }
    }

    .card {
        padding: 2rem;
    }

    .card-header {
        font-weight: 600;
        line-height: (22px / $font-size-base) * 1rem;
        padding: 0;

        i {
            font-size: 20px;
            transform: rotate(180deg);
        }

        .collapsed > i {
            transform: rotate(0deg);
        }
    }

    .card-body {
        @include font-size(18px);
        color: $grey;
        font-weight: 400;
        line-height: (22px / $font-size-base) * 1rem;
        padding: 0;

        li {
            padding-bottom: 0.75rem;
            padding-top: 0.75rem;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
