.content-block {
  // h2 {
  //   @include font-size(64px);
  //   letter-spacing: 0;
  //   // margin-bottom: 2rem;

  //   @include media-breakpoint-up(md) {
  //     @include font-size(48px);
  //     margin-bottom: 1.5rem;
  //   }

  //   @include media-breakpoint-up(lg) {
  //     @include font-size(64px);
  //     margin-bottom: 2rem;
  //   }

  //   @include media-breakpoint-up(fhd) {
  //     @include font-size(90px);
  //     margin-bottom: 3rem;
  //   }
  // }

  blockquote {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(fhd) {
      margin-bottom: 2rem;
    }
  }

  blockquote > p {
    @include font-size(20px);
    font-weight: 400;
    //line-height: (42px / $font-size-base) * 1rem;
    margin: 0;
    padding-right: 0;
    //text-align: center;

    @include media-breakpoint-up(md) {
      @include font-size(22px);
      //text-align: left;
    }

    @include media-breakpoint-up(fhd) {
      @include font-size(28px);
    }
  }

  p {
    @extend .font-smoothing;
    @include font-size(16px);
    @include xd-letter-spacing(10);
    font-weight: 300;
    //line-height: (28px / $font-size-base) * 1rem;
    // padding-right: 8rem;
  }
}

.feature-block-content p,
.green-caption-left-block p,
.left-image-right-text-block p,
.left-text-right-image-block p {
  @extend .font-smoothing;
  @include font-size(18px);
  font-weight: 300;
}

// .drop-shadow-left,
// .drop-shadow-right {
//   display: block;
//   flex: 0;
//   position: relative;

//   .drop-shadow {
//     background-color: $primary;
//     background-size: 100%;
//     content: "";
//     display: block;
//     flex: 0;
//     height: 95%;
//     max-width: 100%;
//     position: absolute;
//     // bottom: 4rem;
//     bottom: 0;
//     width: 100%;
//     z-index: 1;
//   }

//   img {
//     display: block;
//     flex: 0;
//     height: auto;
//     max-width: 100%;
//     position: relative;
//     width: 100%;
//     z-index: 2;
//   }
// }

.two-col-img-right {
  height: auto;
  margin-bottom: 1rem;
  max-width: 100%;
  width: 100%;

  @include media-breakpoint-up(md) {
    margin-bottom: -30%;
    position: relative;
    z-index: 5;
  }
}

.two-col-img-left {
  height: auto;
  max-width: 100%;
  width: 100%;
}

.img-caption {
  @include font-size(12px);
}
