.drop-shadow-bottom-left,
.drop-shadow-bottom-right,
.drop-shadow-top-left ,
.drop-shadow-top-right  {
  display: block;
  flex: 0;
  margin: auto;
  position: relative;
  max-width: 80%;
  text-align: center;


  @include media-breakpoint-up(md) {
    max-width: 80%;
  }

  .drop-shadow {
    background-size: 100%;
    content: "";
    display: block;
    flex: 0;
    height: 100%;
    position: absolute;
    // bottom: 4rem;
    // bottom: 0;
    width: 100%;
    z-index: 1;

    &.primary {
      background-color: $primary;
    }

    &.secondary {
      background-color: $secondary;
    }

    // @include media-breakpoint-up(md) {
    //   transform: translate(15%, -15%);
    // }
  }

  img {
    display: inline-block;
    flex: 0;
    height: auto;
    margin: auto;
    max-width: 100%;
    position: relative;
    width: 100%;
    z-index: 2;
  }
}

.drop-shadow-bottom-left .drop-shadow {
  // bottom: 0;
  // left: 0;
  // right: 0;
  // top: 0;
  transform: translate(-10%, 10%);

  @include media-breakpoint-up(md) {
    transform: translate(-12%, 12%);
  }

  @include media-breakpoint-up(xl) {
    transform: translate(-15%, 15%);
  }
}

.drop-shadow-bottom-right .drop-shadow {
  bottom: 0;
  right: 0;
  transform: translate(10%, 10%);

  @include media-breakpoint-up(md) {
    transform: translate(12%, 12%);
  }

  @include media-breakpoint-up(xl) {
    transform: translate(15%, 15%);
  }
}

.drop-shadow-top-left .drop-shadow {
  top: 0;
  left: 0;
  transform: translate(-10%, -10%);

  @include media-breakpoint-up(md) {
    transform: translate(-12%, -12%);
  }

  @include media-breakpoint-up(xl) {
    transform: translate(-15%, -15%);
  }
}

.drop-shadow-top-right .drop-shadow {
  top: 0;
  right: 0;
  transform: translate(10%, -10%);

  @include media-breakpoint-up(md) {
    transform: translate(12%, -12%);
  }

  @include media-breakpoint-up(xl) {
    transform: translate(15%, -15%);
  }
}



// // inherits element colour
// .drop-shadow-bottom-left {
//   box-shadow: -0.5rem 0.5rem;

//   @include media-breakpoint-only(sm) {
//     box-shadow: -1rem 1rem;
//   }

//   @include media-breakpoint-only(md) {
//     box-shadow: -2rem 2rem;
//   }

//   @include media-breakpoint-only(lg) {
//     box-shadow: -3rem 3rem;
//   }

//   @include media-breakpoint-only(xl) {
//     box-shadow: -4rem 4rem;
//   }

//   @include media-breakpoint-up(fhd) {
//     box-shadow: -5rem 5rem;
//   }
// }

// .drop-shadow-bottom-right {
//   box-shadow: 5rem 5rem;
// }

// .drop-shadow-top-left {
//   box-shadow: -5rem -5rem;
// }

// .drop-shadow-top-right {
//   box-shadow: 5rem -5rem;
// }
