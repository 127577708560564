.spinner-border {
  display: none;
  height: 4rem;
  margin: auto 0;
  width: 4rem;
}

// .spinner {
//     animation: rotation 2s infinite linear;
//     display: none; // block
//     height: 48px;
//     margin: 0;
//     max-width: 100%;
//     width: 48px;
// }

// @keyframes rotation {
//   from {
//       transform: rotate(0deg);
//   }
//   to {
//       transform: rotate(359deg);
//   }
// }
