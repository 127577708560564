.left-image-right-text-block {
  img {
    display: block;

    @include media-breakpoint-up(md) {
      transform: translateX(-5%);
    }

    @include media-breakpoint-up(lg) {
      transform: translateX(-5%);
    }

    @include media-breakpoint-up(xl) {
      transform: translateX(-10%);
    }
  }
}
