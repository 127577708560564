@include media-breakpoint-up(xl) {
  .home .services-blocks .col-md-4:nth-child(2) {
    transform: translateY(84px);
  }
}

.services-blocks {
    .service-block {
      opacity: 0;
      transform: translateY(250px);
      animation-duration: 600ms;
    }

    .service-block-content {
      @include responsive-ratio(23, 24);
      display: block;
      overflow: hidden;
      // padding-bottom: (500 / 380 * 100%);
      // padding-bottom: 145%;
      position: relative;
      text-align: center;
      transition: background 300ms;

      &:after {
        background-color: rgba($primary, 0.75);
        bottom: 0;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 300ms $material-decelerate;
        width: 100%;
        z-index: 2;
      }

      &:hover {
        background: $primary;

        &:after {
          opacity: 0;
        }

        > h3 {
          transform: translate(-50%, 0%);
          top: 5%;
          opacity: 0;

          @include media-breakpoint-up(xl) {
            top: 20%;
          }

        }

        .service-block-inner {
          opacity: 1;
        }
      }

      > h3 {
        color: #fff;
        display: block;
        font-weight: 700;
        left: 50%;
        //line-height: (48px / $font-size-base) * 1rem;
        // margin: 0;
        opacity: 1;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 400ms $material-decelerate;
        z-index: 3;
      }

      img {
        display: block;
        filter: grayscale(100%);
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        //bottom: 0;
        height: auto;
        margin: 0;
        transition: opacity 300ms $material-decelerate;
        opacity: 1;
        width: 100%;
        z-index: -1;
        
        // display: block;
        // position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
        // height: 100%;
        // width: 100%;
        // z-index: 1;
      }

      .service-block-inner {
        align-items: center;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 0;
        position: relative;
        transition: opacity 800ms $material-decelerate;
        z-index: 5;

        //display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        margin: 0;
        bottom: 0;
        right: 0;
        width: 100%;

        // h3 {
        //   color: inherit;
        //   font-weight: 700;
        //   line-height: (42px / $font-size-base) * 1rem;
        //   margin-bottom: 2rem;
        // }

        p {
          @include font-size(18px);
          font-weight: 400;
          line-height: 1.5;
          //line-height: (38px / $font-size-base) * 1rem;
          margin-top: auto;
          margin-bottom: auto;
          padding-top: 20%;

          @include media-breakpoint-only(sm) {
            @include font-size(24px);
          }

          @include media-breakpoint-only(md) {
            @include font-size(16px);
            line-height: 1.4;
            padding-top: 5%;
          }

          @include media-breakpoint-up(xl) {
            @include font-size(24px);
          }
        }

        a {
          border: 0;
        }

        .btn.btn-light {
          &:hover {
            color: #FFFFFF;
            background-color: transparent;
            box-shadow: inset 0 0 0 5px $white;
          }
        }
      }
    }
  }

  // @include media-breakpoint-up(lg) {
  //   .home .services-blocks .row > div:nth-child(2) > .service-block {
  //       transform: translateY(80px);
  //   }
  // }
