table {
    margin-top: 1rem;
    margin-bottom: 2rem;
    max-width: 100%;
    overflow: auto;

    a {
        word-break: break-word;
    }

    @include media-breakpoint-down(sm) {
        display: block;
        td, th {
            padding: 0.25rem;
        }
    }
}

th {
  @include font-size(18px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $off-white;
  color: $primary;
  font-family: $headings-font-family;
  font-weight: 400;
  line-height: 1;
  padding: 0.5rem;
}

td {
  @include font-size(18px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $grey;
  font-weight: 300;
  padding: 0.5rem;
}

tr:nth-child(even) td {
  background-color: $off-white;
}
